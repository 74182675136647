import {Login_Admin,Logout_Admin,Change_Password, Get_Profile, Get_logoType, Reset_Password} from './actionType'
export const DataDashboard=(data: { username: string; password: string;  },navigate:any)=>{
  
    return {
        type: Login_Admin,
        data,
        navigate
    }
}
export const LogoutAdmin=(token: string,navigate:any)=>{
   
    return {
        type: Logout_Admin,
        token,
        navigate
    }
}
export const ChangePassword=(data: { oldPassword: string; newPassword: string;  },navigate:any)=>{
 
    return {
        type: Change_Password,
        data,
        navigate
    }
}
export const ProfileDisplay=()=>{
    return {
        type: Get_Profile,
    }
}
export const ResetPassword=(data: { new_password: string; confirm_password: string;  },reset_token:string,navigate:any)=>{
    return {
        type: Reset_Password,
        data,
        reset_token,
        navigate
    }
}
export const homeLogo=(logotype : boolean)=>{

    return{
        type:Get_logoType,
        logotype
    }
}