export const Add_Merchant = "Add_Merchant"
export const Delete_Merchant = "Delete_Merchant"
export const Edit_Merchant = "Edit_Merchant"
export const Display_Merchant = "Display_Merchant"
export const ResponseMerchant = "Response_Merchant"
export const Get_Edit_Response  = "Get_Edit_Response"
export const getResponseById  = "Edit_Response_By_ID"
export const Display_Category_List = "Display_Category_List"
export const Display_Package_List = "Display_Package_List"
export const Display_CategoryData = "Display_CategoryData"
export const Display_PackageData = "Display_PackageData"
export const Change_Status = "Change_Status"
export const getBranches = "Get_Branches"
export const BranchesList = "Branches_List"
export const Reset_Merchant_Password = "Reset_Merchant_Password"
export const Change_Merchant_Email = "Change_Merchant_Email"
export const GetMerchantDetailById = "Get_Merchant_Detail_By_Id"
export const GetMerchantDetailResponse = "Get_Merchant_Detail_Response"
