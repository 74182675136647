import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from  "../dictionary"
import axios from "axios";
const {load_money } = Dictionary
const Load_Money_Services ={
    add: async (data : {},headers:any )=>{

        return axios.post(BASE_URL + load_money.addLoad_Money(), data,{ headers: headers });
    },
    delete: async (id : string)=>{
        return axios.delete(BASE_URL + load_money.deleteLoad_Money(id) );
    },
    edit: async (id : string,data : {},headers:any )=>{
        return axios.put(BASE_URL + load_money.editLoad_Money(id),data,{ headers: headers });
    },
    view: async (paramsObj:any)=>{
        return axios.get(BASE_URL + load_money.viewLoad_Money(),{params : paramsObj} );
    }
}
export default Load_Money_Services;
