import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { getBranchList } from "../../store/action"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import { map } from "lodash";
const Branches = () => {
    const [branchData, setbranchData] = useState<any>([]);
    const [merchantData, setmerchantData] = useState<any>([])
    const merchantId: string | any = useParams()
    const checkData: any = useSelector((state: any) => state);
    const { branch } = useSelector((state: any) => ({
        branch: state?.Merchant?.BranchList?.data,
      }));
    const dispatch = useDispatch()

    useEffect(() => {
        setbranchData(branch?.branches)
        setmerchantData(branch)
    }, [branch])
    useEffect(() => {

        dispatch(getBranchList(merchantId.id))
    }, [])
    return (
        <div className="my-5 py-5">
            <div className="row justify-content-center">
                <div className="col-11 mb-3 ">
                    <div className="d-flex justify-content-between align-items-center">
                        <Breadcrumbs breadcrumbItem="Branches" isBack={true}/>

                    </div>
                </div>
                <div className="col-11">
                    <div>
                        <Card className="branches-card " >
                            <Row className="no-gutters align-items-center my-5">
                                <Col md={4}>
                                    <div className="text-center">
                                        <CardImg className="img-fluid" src={merchantData?.merchant?.brand_logo} alt="" style={{ width: '150px', height: '150px',objectFit:'cover' }} />
                                    </div>
                                </Col>
                                <Col md={8} className={"text-center"}>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-3"></div>
                                            <div className="col-3  ">
                                             <CardTitle> Name &nbsp;</CardTitle>  
                                            </div>
                                            <div className="col-6 text-start">
                                                <CardText>{merchantData?.merchant?.owner?.first_name} {merchantData?.merchant?.owner?.last_name}</CardText>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3"></div>
                                            <div className="col-3  text-end">
                                               <CardTitle>Brand Name &nbsp;</CardTitle>                                             </div>
                                            <div className="col-6 text-start">
                                                <CardText>{merchantData?.merchant?.brand_name}</CardText>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3"></div>
                                            <div className="col-3 ">
                                               <CardTitle>Mobile</CardTitle>                                             </div>
                                            <div className="col-6 text-start">
                                                <CardText>{merchantData?.merchant?.owner?.mobile}</CardText>
                                            </div>
                                        </div>
{/* 
                                        <CardText>
                                            {merchantData?.merchant?.merchant_code}
                                        </CardText> */}
                                        {/* <CardText>
                                            <small className="text-muted">
                                                {merchantData?.merchant?.merchant_code}
                                            </small>
                                        </CardText> */}
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                    <div className=" row ">
                        {map(branchData, (i: any, ind) => (
                            <div className="col-4">
                                <Card >
                                    {/* <Col md={4}>
                                            <CardImg className="img-fluid" src={"img2"} alt="Skote" />
                                        </Col> */}
                                    <CardBody className="branches-card">
                                        <CardTitle >Name: &nbsp;{i?.name}</CardTitle>
                                        <CardText>
                                            Address: &nbsp;{i?.address},{i.city},{i?.state}
                                        </CardText>

                                    </CardBody>

                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Branches