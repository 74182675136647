import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutAdmin } from "../../../store/auth/action";
import AdminLogo from "../../../assets/images/index"

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storageData: any = JSON.parse(localStorage.getItem("login_response") || '{}')
  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.displayName);
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //   ) {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.username);
    //   }
    // }
  }, [props.success]);

  const adminLogout = () => {
    let token: string = localStorage.getItem("access_token") || "";
    dispatch(LogoutAdmin(token, navigate))
    // localStorage.removeItem("access_token");
    // navigate("/login")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={AdminLogo.Adminavater}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{storageData.first_name} {storageData.last_name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            My Wallet
          </DropdownItem>
          // <DropdownItem tag="a" href="#">
          //   <span className="badge bg-success float-end">11</span>
          //   <i className="bx bx-wrench font-size-16 align-middle me-1" />
          //   Settings
          // </DropdownItem> */}
          <DropdownItem tag="a" onClick={() => adminLogout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span >Logout</span>
          </DropdownItem>
          {/* <div className="dropdown-divider" /> */}
          {/* <Link to="/login" className="dropdown-item"> */}
          <div >

          </div>

          {/* </Link> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

// ProfileMenu.propTypes = {
//   success: PropTypes.any,
//   t: PropTypes.any
// };

// const mapStatetoProps = state => {
//   const { error, success } = state.Profile;
//   return { error, success };
// };

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
// );
export default ProfileMenu;