import { put, takeLatest } from 'redux-saga/effects'
import { Login_Admin, Get_Login_Response, Get_Logout_Response, Logout_Admin, Change_Password, Get_Profile, Get_Profile_Response, Reset_Password } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* getLogin(action: any): Generator<any, AxiosResponse<any>, any> {
    let message
    let payload = {
        username: action.data.username,
        password: action.data.password,
        grant_type: "password",
        scope: '1'
    }
    const headers = {
        'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0',
        "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
        const response: any = yield service.auth.login(payload, headers);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: Get_Login_Response, data });
            action.navigate("/dashboard")
            localStorage.setItem("access_token", data.data.accessToken)
            localStorage.setItem("login_response", JSON.stringify(data.data))
        } else {
            toast.error(response?.message)
        }
        return response;
    } catch (error: any) {
        if (error?.response?.data?.status === 401) {
            toast.error("Email or password is incorrect")
        } else {
            toast.error(error?.response?.data?.data.message)
        }
        console.log("Error",)
        return action
    }

}

function* getLogout(action: any): Generator<any, AxiosResponse<any>, any> {
    const headers = {
        'Authorization': `Bearer ${action.token}`,
        "Content-Type": "application/x-www-form-urlencoded"
    };
    const response: any = yield service.auth.logout({}, headers);
    const data = yield response.data;
    action.navigate("/login")
    localStorage.clear();
    if (response.status === 200) {
        toast.success(response.data.message);
        yield put({ type: Get_Logout_Response, data });
    }
    else {
        toast.error(response.data.message)
    }
    localStorage.removeItem("access_token");
    return response; // add this line to return the response
}
function* changePassword(action: any): Generator<any, AxiosResponse<any>, any> {
    let payload = {
        old_password: action.data.oldPassword,
        new_password: action.data.newPassword,
    }
    try {
        const response: any = yield service.auth.changePassword(payload, {});

        if (response.status === 200) {
            toast.success(response.data.message);
            localStorage.removeItem("access_token");
            action.navigate("/login")
        } else {
            toast.error(response.data.message)
        }
        return response;
    } catch (error: any) {
        console.log(error);

        toast.error(error?.response?.data?.message)
        return action;
    }
    // add this line to return the response
}

function* displayProfile(): Generator<any, AxiosResponse<any>, any> {
    const response: any = yield service.auth.viewProfile();
    const data = yield response.data;

    if (response.status === 200) {
        yield put({ type: Get_Profile_Response, data })
        toast.success(response.message);

    } else {
        toast.error(response.message);

    }
    return response; // add this line to return the response
}
function* resetPassword(action: any): Generator<any, AxiosResponse<any>, any> {
    let payload = {
        new_password: action.data.password,
        confirm_password: action.data.confirmPassword,
        reset_token: action.reset_token
    }
    try {
        const response: any = yield service.auth.resetPassword(payload);

        if (response.status === 200) {
            toast.success(response.data.message);
            action.navigate("/login")
        } else {
            toast.error(response.data.message)
        }
        return response;
    } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message)
        return action;
    }
    // add this line to return the response
}
function* productSaga() {
    yield takeLatest(Login_Admin, getLogin)
    yield takeLatest(Logout_Admin, getLogout)
    yield takeLatest(Change_Password, changePassword)
    yield takeLatest(Get_Profile, displayProfile)
    yield takeLatest(Reset_Password, resetPassword)
}

export default productSaga;