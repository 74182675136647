import React, { useEffect, useMemo, useState } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
import Select from "react-select";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { countryCodeData } from "../../components/Common/carabianCountry"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { IAddData, IEditData } from "./interface";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AddCustomer } from "../../store/action";

const Add_Customer = () => {
    const [gender, setGender] = useState<string | any>(null);
    const [countryCode, setCountryCode] = useState<string | any>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [countryError, setCountryError] = useState<string>('');
    const [genderError, setGenderError] = useState<string>('');
    const [Error, setError] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const CustomerId: string | any = useParams()
    const blockInvalidChar = (e: any) => ['e', 'E', '+', '-', '*', '/'].includes(e.key) && e.preventDefault();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: firstName,
            lastname: lastName,
            email: email,
            date: date,
            mobile: mobile
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Please Enter Your First Name"),
            lastname: Yup.string().required("Please Enter Your Last Name"),
            email: Yup.string().required("Please Enter Your email"),
            date: Yup.string().required("Please Enter Your date"),
            // gender: Yup.string().required("Please Select Your gender"),
            // countrycode: Yup.string().required("Please Select Your countrycode"),
            mobile: Yup.string().required("Please Enter Your mobile"),
        }),

        onSubmit: (values) => {

            let temp: IAddData = {
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                date: values.date,
                gender: gender.value,
                countrycode: countryCode.value,
                mobile: values.mobile
            }
            if (Error === false) {
                dispatch(AddCustomer(temp, navigate))

            }
        }
    });
    const genderData = [
        {
            options: [
                { label: "Male", value: "MALE" },
                { label: "Female", value: "FEMALE" },
            ]
        },
    ];

    function handleGender(e: string) {
        setGender(e)
    }
    function handleCountryCode(e: string) {
        setCountryCode(e)
    }
    const handleError = () => {
        setCountryError("")
        setGenderError("")
        setError(false)
        if (countryCode === null) {
            setCountryError("Please select a country")
            setError(true)
        }
        if (gender === null) {
            setGenderError("Please select a gender")
            setError(true)
        }
    }
    return (
        <>
            <div className="row page-content justify-content-center mx-2 mt-2">
                <div className="col-lg-12 ">
                    <div className="mb-3">
                        <Breadcrumbs breadcrumbItem="Add Customer" isBack={true} />
                    </div>
                    <Card>
                        <CardBody>
                            <Form className="needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">First Name</Label>
                                            <Input
                                                name="firstname"
                                                placeholder="First name"
                                                type="text"
                                                className="form-control"
                                                id="validationCustom01"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.firstname || ""}
                                                invalid={
                                                    validation.touched.firstname && validation.errors.firstname ? true : false
                                                }
                                            />
                                            {validation.touched.firstname && validation.errors.firstname ? (
                                                <FormFeedback type="invalid">{validation.errors.firstname}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">Last name</Label>
                                            <Input
                                                name="lastname"
                                                placeholder="Last name"
                                                type="text"
                                                className="form-control"
                                                id="validationCustom02"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.lastname || ""}
                                                invalid={
                                                    validation.touched.lastname && validation.errors.lastname ? true : false
                                                }
                                            />
                                            {validation.touched.lastname && validation.errors.lastname ? (
                                                <FormFeedback type="invalid">{validation.errors.lastname}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom01">Email</Label>
                                            <Input
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                className="form-control"
                                                id="validationCustom01"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.email || ""}
                                                invalid={
                                                    validation.touched.email && validation.errors.email ? true : false
                                                }
                                            />
                                            {validation.touched.email && validation.errors.email ? (
                                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom02">DOB</Label>
                                            <Input
                                                name="date"
                                                placeholder="Date"
                                                type="date"
                                                className="form-control"
                                                id="validationCustom02"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.date || ""}
                                                invalid={
                                                    validation.touched.date && validation.errors.date ? true : false
                                                }
                                            />
                                            {validation.touched.date && validation.errors.date ? (
                                                <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom03">Gender</Label>
                                            <Select
                                                name="gender "
                                                value={gender}
                                                onChange={(e) => {
                                                    handleGender(e)
                                                }}
                                                options={genderData}
                                                className="select2-selection"
                                            />
                                            {gender?.value.length === undefined ? (
                                                <p className="errorColor">{genderError}</p>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom03">Country</Label>
                                            <Select
                                                name="countrycode"
                                                value={countryCode}
                                                onChange={(e) => {
                                                    handleCountryCode(e);
                                                }}
                                                options={countryCodeData}
                                                className="select2-selection"
                                            />
                                            {countryCode?.value.length === undefined ? (
                                                <p className="errorColor">{countryError}</p>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="validationCustom05">Mobile</Label>
                                            <Input
                                                name="mobile"
                                                placeholder="Mobile"
                                                type="text"
                                                className="form-control"
                                                id="validationCustom05"
                                                onKeyDown={blockInvalidChar}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.mobile || ""}
                                                maxLength={10}
                                            />
                                            {validation.touched.mobile && validation.errors.mobile?.length !== 0 ? (
                                                <p className="errorColor">{validation.errors.mobile}</p>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="text-center">
                                    <Button type="submit" className="color" onClick={() => { handleError() }}>
                                        {isEdit ? "Update" : "Add"}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Add_Customer;
