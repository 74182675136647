import {Add_Award,Display_Award,get_Points,customer_QrMobile,Search_Customer,Transactions_Data} from './actionType'
export const AddAward=(data: { country_code: string; mobile:string; amount:string;   })=>{
   
    return {
        type: Add_Award,
        data,
    }
}
export const getPoints=(data: { customerId: string;amount:string; country_code: string; mobile:string; })=>{
    return {
        type: get_Points,
        data
    }
}

export const DisplayAward=({search}:any)=>{
    return {
        type: Display_Award,
        search
    }
}
export const customerQrMobile = (QrID : any) => {
    
    return {
        type: customer_QrMobile,
        QrID
    }
}
// export const DisplayCustomer=(common_key:string)=>{
//     return {
//         type: Display_Award,
//         common_key,
//     }
// }
export const transactionHistory = (search?: any, page?: Number, limit?: Number) => {
    return {
        type: Transactions_Data,
        search,
        page,
        limit
    }
}