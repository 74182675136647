import { put, takeLatest, call } from 'redux-saga/effects'
import { Add_Award_Redeem, Display_Award_Redeem, Edit_Award_Redeem, Delete_Award_Redeem, Response_Award_Redeem, Edit_Award_Redeem_Response } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addAward_Redeem(action: any): Generator<any, AxiosResponse<any>, any> {
    try{
        
        const response: any = yield service.award_redeem.add(action.data,{});
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);  
            yield call(displayAward_Redeem);
        }else{
            toast.error(response.data.message);  
        }
        return response; // add this line to return the response
    }catch(error:any){
        const data = yield {
            data: error?.response?.data?.message,
            popup:true
         }
         yield put({ type: Response_Award_Redeem, data });
        // toast.error(error?.response?.data?.message);  
        // yield call(displayAward_Redeem);
        return error
    }
    
}
function* editAward_Redeem(action: any): Generator<any, AxiosResponse<any>, any> {
    
    try {
        const response: any = yield service.award_redeem.edit(action.id ,action.data, { });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);  
            yield put({ type: Edit_Award_Redeem_Response, data });
            yield call(displayAward_Redeem);
            
        }else{
            toast.error(response.data.message);  
        }
        return response; 
    } catch (error:any) {
        console.log(error);
        const data = yield {
            data: error?.response?.data?.message,
            popup:true
         }
         yield put({ type: Response_Award_Redeem, data }); 
        return action
    }
   // add this line to return the response
}
function* deleteAward_Redeem(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.award_redeem.delete(action.id);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);  
            yield call(displayAward_Redeem);
            // yield put({ type: EditResponse, data });
        }else{
            toast.error(response.data.message); 
        }
        return response;
    }  catch (error:any) {
        console.log(error);
        toast.error(error?.response?.data?.message);  
        yield call(displayAward_Redeem);

        return action
    }
    // add this line to return the response
}
function* displayAward_Redeem(action?:any): Generator<any, AxiosResponse<any>, any> {
   try {
    const response: any = yield service.award_redeem.view({search : action?.search,page:action?.page,limit:action?.limit});
    const data = yield response.data;
    if (response.status === 200) {
        toast.success(response.message); 
        yield put({ type: Response_Award_Redeem, data })
    }else{
        toast.error(response.message); 
    }
    return response; // add this line to return the response
   }  catch (error:any) {
    console.log(error);
    toast.error(error?.response?.data?.message);  
    return error
}
    
}
function* productSaga() {
    yield takeLatest(Add_Award_Redeem, addAward_Redeem)
    yield takeLatest(Edit_Award_Redeem, editAward_Redeem)
    yield takeLatest(Delete_Award_Redeem, deleteAward_Redeem)
    yield takeLatest(Display_Award_Redeem, displayAward_Redeem)
}

export default productSaga;