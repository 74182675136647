import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback,
    CardTitle, Button, Modal, FormGroup
} from "reactstrap";
import Table from '../../components/Common/dataTable';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddLoad_Money, DisplayLoad_Money, DeleteLoad_Money, EditLoad_Money } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { IAddData } from "./interface";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupModel from "../../components/Common/Popup"
import { countryCodeData } from "../../components/Common/carabianCurrency"
import Constants from "../../constants/Constants";

const Index = () => {
    const [showModal, setShowModal] = useState(false);
    const [award_Redeem_Amount, setLoad_Money_Amount] = useState('');
    const [countryCode, setCountryCode] = useState<string | any>(null);
    const [award_Redeem_Point, setLoad_Money_Point] = useState('');
    const [Load_Money_Id, setLoad_Money_Id] = useState('');
    const [Load_MoneyData, setLoad_MoneyData] = useState<any>([])
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [countryError, setCountryError] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(2)
    const [Error, setError] = useState<boolean>(false);
    const [search, setSearch] = useState("")
    const storageData: any = JSON.parse(localStorage.getItem("login_response") || '{}')
    const dispatch = useDispatch();
    const { Load_Money_Data, Load_Money_Count } = useSelector((state: any) => ({
        Load_Money_Data: state?.Load_Money.Load_MoneyData?.data,
        Load_Money_Count: state?.Load_Money.Load_MoneyData?.count
        ,
    }));
    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) =>
                index + 1,
            minWidth: "15%",
        },
        {
            name: "Currency",
            selector: (i: any) => handleCountry(i)?.label,
            minWidth: "25%",
            compact: true,
            sortable: true,
        },
        {
            name: "Amount",
            selector: (i: any) => i.amount || '-',
            minWidth: "25%",
        },
        {
            name: "Points",
            selector: (i: any) => i.points,
            minWidth: "25%",
            // compact: true,
            // sortable: true,
        },
        Constants.SCOPE.ADMIN === storageData.scope && (
            {
                name: "Action",
                selector: (i: any) =>
                    <span className="cursor">
                        <i className="mdi mdi-pencil fs-2 m-lg-1" onClick={() => {
                            _handleLoad_MoneyUpdate(i)
                            setLoad_Money_Id(i.id)
                        }
                        } ></i>
                        <i className="bx bxs-trash fs-2" onClick={() => {
                            setDeleteConfirmation(true)
                            setDeleteId(i.id)
                            handleReset()
                        }}></i>

                    </span>,
                minWidth: "10%",
            })
    ]
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: award_Redeem_Amount,
            points: award_Redeem_Point,
        },
        validationSchema: Yup.object({
            amount: Yup.string().required("Please Enter the Amount"),
            points: Yup.string().required("Please Enter the  Points"),
        }),
        onSubmit: (values, { resetForm }: any) => {
            let countryError = 'Please Select Country Type'
            let temp1 = false
            if (countryCode === null) {
                temp1 = true
                setCountryError(countryError)
            } else {
                setCountryError('')
            }
            let temp: IAddData = {
                currency: countryCode.value,
                amount: values.amount,
                points: values.points,
            }
            if (Error === false) {
                if (isEdit) {
                    dispatch(EditLoad_Money(Load_Money_Id, temp))
                    setShowModal(false)
                    setIsEdit(false)
                    resetForm({ values: '' })
                    handleReset()
                } else {
                    dispatch(AddLoad_Money(temp))
                    setShowModal(false)
                    resetForm({ values: '' })
                    handleReset()
                }
            }
        }
    });
    function handleCountryCode(e: string) {
        if (e) {
            setCountryError('')
        }
        setCountryCode(e)
    }
    useEffect(() => {
        setLoad_MoneyData(Load_Money_Data)
        setTotalCount(Load_Money_Count)
    }, [Load_Money_Data])

    useEffect(() => {
        dispatch(DisplayLoad_Money({ search: '', page: currentPage, limit: limitPerPage }))
    }, [currentPage, limitPerPage])

    const _handleLoad_MoneyUpdate = (i: IAddData) => {
        setIsEdit(true)
        let countryindex = countryCodeData[0].options.findIndex(x => x?.value === i?.currency);
        setLoad_Money_Amount(i.amount)
        setCountryCode(countryCodeData[0].options[countryindex])
        setLoad_Money_Point(i.points)
        setShowModal(true)
    }
    const handleDelete = () => {
        dispatch(DeleteLoad_Money(deleteId))
    }

    const handleReset = () => {
        setCountryCode('')
        setCountryError("")
        validation.resetForm({
            values: {
                amount: '',
                points: '',
            },
        })
    }

    //for change tooltip display propery
    const handleError = () => {
        setCountryError("")
        setError(false)
        if (countryCode === '') {
            setCountryError("Please select a country")
            setError(true)
        }
    }

    const handleSearch = (e: string) => {
        let searchValue: string = e
        setTimeout(() => {
            dispatch(DisplayLoad_Money({ search: searchValue, page: currentPage, limit: limitPerPage }))
        }, 500)
        setSearch(e)
    }

    const handleCountry = (i: any) => {
        let countryindex = countryCodeData[0].options.findIndex(x => x?.value === i?.currency);

        return countryCodeData[0].options[countryindex]
    }

    return (
        <React.Fragment>
            <div className="my-5 py-5">
                {/* <Container fluid={true}> */}
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Load Money" />
                            {Constants.SCOPE.ADMIN === storageData.scope ?
                                (<div className="text-end">
                                    <Button className="color"
                                        onClick={() => {
                                            handleReset()
                                            setIsEdit(false)
                                            setShowModal(true)
                                        }}>Add</Button>
                                </div>) : (
                                    <div className="text-end" style={{ height: "34.5px" }}>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="card col-11">
                        <div className="row">
                            <div className="col-6">
                                <form className="app-search d-none d-lg-block w-50">
                                    <div className="position-relative">
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" /> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <Table
                                columns={Load_MoneyData && columns}
                                data={Load_MoneyData ? Load_MoneyData : []}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setLimitPerPage={setLimitPerPage}
                                pagination={false}
                            />

                        </div>
                    </div>
                </div>

                <div>
                    <Modal
                        isOpen={showModal}
                        role="dialog"
                        autoFocus={true}
                        centered
                        data-toggle="modal"
                        toggle={() => {
                            setShowModal(!showModal);
                        }}
                    >

                        <div className="modal-body">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col >
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Load Money</CardTitle>
                                                <div className="p-2 ">


                                                    <Form className="needs-validation"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row className="mb-3">
                                                            <Label htmlFor="validationCustom01">
                                                                Currency
                                                            </Label>
                                                            <Col sm={12}>
                                                                <Select
                                                                    name="countrycode"
                                                                    value={countryCode}
                                                                    onChange={(e) => {
                                                                        handleCountryCode(e);
                                                                    }}
                                                                    options={countryCodeData}
                                                                    className="select2-selection"

                                                                />
                                                                {countryCode?.value?.length === undefined ? (
                                                                    <span className="errorColor">{countryError}</span>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="validationCustom01">Amount</Label>
                                                                    <Input
                                                                        name="amount"
                                                                        placeholder="Amount"
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="validationCustom01"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.amount || ""}
                                                                        invalid={
                                                                            validation.touched.amount && validation.errors.amount ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.amount && validation.errors.amount ? (
                                                                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="validationCustom02">Points</Label>
                                                                    <Input
                                                                        name="points"
                                                                        placeholder="Points"
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="validationCustom02"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.points || ""}
                                                                        invalid={
                                                                            validation.touched.points && validation.errors.points ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.points && validation.errors.points ? (
                                                                        <FormFeedback type="invalid">{validation.errors.points}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <div className="text-center">
                                                            <Button color="primary" type="submit" onClick={() => { handleError() }}>
                                                                {isEdit ? "Update" : 'Add'}
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>
                    <div>
                        {deleteConfirmation && (
                            <PopupModel
                                ApiName={handleDelete}
                                popUpFunction={setDeleteConfirmation}
                                title="Confirmation"
                                paragraph={`Are you sure you want to delete ?`}
                            />
                        )}
                    </div>
                </div>

                {/* </Container> */}
            </div>
        </React.Fragment>
    );
};

export default Index;
