import { getReponseById, ResponseCustomer } from './actionType'
const initialState = {
    responseCutomerData: [],
    responseCustomerById: [],
}
const ManageCustomer = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case ResponseCustomer:
            return {
                ...state,
                responseCutomerData: action.data
            }
        case getReponseById:
            return {
                ...state,
                responseCustomerById: action.data
            }
        default:
            return state;
    }
}
export default ManageCustomer