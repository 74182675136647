import {Add_Category,Display_Category,Edit_Category,Delete_Category} from './actionType'
export const AddCategory=(data: { name: string; image: string;  })=>{
    return {
        type: Add_Category,
        data,
    }
}
export const EditCategory=(id: string ,data: { name: string; image: string;  })=>{
    return {
        type: Edit_Category,
        id,
        data
    }
}
export const DeleteCategory=(id: string)=>{
    return {
        type: Delete_Category,
        id,
    }
}
export const DisplayCategory=({search,page,limit}:any)=>{
    return {
        type: Display_Category,
        search,
        page,
        limit
    }
}