import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from  "../dictionary"
import axios from "axios";
const {dashboard } = Dictionary
const DashboardServices ={
    view: async ()=>{
        return axios.get(BASE_URL + dashboard.viewDashboard() );
    }
}
export default DashboardServices;
