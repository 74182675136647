import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../../components/Common/Breadcrumb'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { AddImages, deleteImage, getImages } from '../../store/image/action';
import PopupModel from '../../components/Common/Popup';

const Index = () => {
  const inputFileRef = useRef<any>();
  const [imagePreview, setImagePreview] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageArr, setImageArr] = useState<any>();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedImageId, setSelectedImageId] = useState('')

  const dispatch = useDispatch();
  const { Images } = useSelector((state: any) => ({
    Images: state?.Images?.images,
  }));
  const _chooseFileToUpload = () => {
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
  };
  const _handleImageChange = (e: any) => {


    if (e?.target?.files.length > 0) {
      let image_Url = URL.createObjectURL(e?.target?.files[0])
      setImagePreview(image_Url)
      setImageUrl(e.target.files)
    }


  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      carousel: '',
    },
    validationSchema: Yup.object({
      carousel: Yup.string().required("Please select photo(s)"),
    }),

    onSubmit: () => {
      console.log('values', imageUrl)
    }
  });
  const _handleSubmit = () => {
    let payload = {
      carousel: imageUrl
    }
    dispatch(AddImages(payload))
    setImageUrl('')
  }
  useEffect(() => {
    dispatch(getImages())
  }, [])

  useEffect(() => {
    setImageArr(Images)
    setImagePreview('')
  }, [Images])
  const _handleDeleteClick = () => {
    dispatch(deleteImage(selectedImageId))

  }
  return (
    <>
      <div className="my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-11 p-0 mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <Breadcrumb breadcrumbItem="Upload Images" />
            </div>
          </div>
          <div className="card col-11">
            <div className="row">
              <div className="col-4">
                <div
                  style={{ marginTop: "24px" }}
                >
                  <div>
                    <div>
                      <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                    </div>
                    <div
                      className="avatarPreview cursor-pointer"
                      onClick={() => _chooseFileToUpload()}
                    >
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage: `url(${imagePreview})`,
                        }}
                        className="backgroundimage upload-image"
                      >
                        <p className={`text-center all-color text-center ${imagePreview && "addcustomimage"}`}>
                          Select Image
                        </p>
                      </div>
                    </div>
                  </div>
                  <input
                    name="carousel"
                    type="file"
                    ref={inputFileRef}
                    accept="image/*"
                    onChange={(e) => {
                      validation.handleChange(e)
                      _handleImageChange(e)
                    }}
                    multiple
                    style={{ display: "none" }}
                    onBlur={validation.handleBlur}
                  />
                </div>
                <div className="text-center mt-1">
                  <Button color="primary"
                    disabled={imageUrl ? false : true}
                    onClick={() => _handleSubmit()}>Upload
                  </Button>
                </div>
              </div>
              {imageArr && imageArr?.data?.map((item: any, index: number) =>
                <div key={item.id} className='col-4'>
                  <div className='imagePreview mt-4 mb-2'>
                    <img
                      className='img-fluid'
                      src={item?.image}
                    />
                    <div className='delete-icon' onClick={() => {
                      setSelectedImageId(item?.id)
                      setDeleteConfirmation(true)
                    }}>
                      <i className="dripicons-cross align-middle" />
                    </div>
                  </div>
                </div>
              )}

            </div>

          </div>
        </div>
      </div >
      {deleteConfirmation && <PopupModel
        ApiName={_handleDeleteClick}
        popUpFunction={setDeleteConfirmation}
        title="Confirmation"
        paragraph={`Are you sure you want to Delete this image?`}
      />
      }
    </>
  )
}

export default Index