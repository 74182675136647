import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from  "../dictionary"
import axios from "axios";
const {auth } = Dictionary
const authServices ={
    login: async (data : {} ,headers : any)=>{
        return axios.post(BASE_URL + auth.login(), data, { headers });
    },
    logout: async ({},headers : any)=>{
        return axios.post(BASE_URL + auth.logout(),{},{headers});
    },
    changePassword: async (data : {},headers : any)=>{
        return axios.put(BASE_URL + auth.changePassword(),data,{ headers: headers } );
    },
    viewProfile: async ()=>{
        return axios.get(BASE_URL + auth.getProfile() );
    },
    resetPassword: async (data : {})=>{
        return axios.put(BASE_URL + auth.resetPassword(),data);
    },
}
export default authServices;
