import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from  "../dictionary"
import axios from "axios";
const {userQuery } = Dictionary
const QueryServices ={
    view: async (paramsObj:any)=>{
        return axios.get(BASE_URL + userQuery.viewQuery(),{params : paramsObj} );
    }
}
export default QueryServices;
