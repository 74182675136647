import {Add_Award_Redeem,Response_Award_Redeem } from './actionType'

const initialState = {
    data:[]
}

 const Award_Redeem = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case Response_Award_Redeem:
            return {
                ...state,
                data:action.data}
        default:
            return state;
    }
}

export default Award_Redeem