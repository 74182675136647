import { put, takeLatest } from 'redux-saga/effects'
import { Display_Query, ResponseQuery } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* displayQuery(action?:any): Generator<any, AxiosResponse<any>, any> {

    try {
        const response: any = yield service.query.view({search : action?.search,page:action?.page,limit:action?.limit});
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: ResponseQuery, data })
        } else {
            toast.success(response.data.message);
        }
        return response;
    } catch (error:any) {
        console.log(error)
        toast.error(error?.response?.data?.message);
        return error
    }
    // add this line to return the response
}
function* productSaga() {
    yield takeLatest(Display_Query, displayQuery)
}

export default productSaga;