import { put, takeLatest, call } from 'redux-saga/effects'
import {
    BranchesList, Add_Merchant, Display_Merchant, getBranches,
    Edit_Merchant, Delete_Merchant, getResponseById, ResponseMerchant,
    Get_Edit_Response, Display_Category_List, Display_Package_List, Display_CategoryData,
    Display_PackageData, Change_Status, Reset_Merchant_Password, Change_Merchant_Email,GetMerchantDetailResponse,
    GetMerchantDetailById
} from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addMerchant(action: any): Generator<any, AxiosResponse<any>, any> {
    try {

        const response: any = yield service.merchant.add(action.data, { "Content-type": "application/json" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message)
            action.navigate("/merchant")
            yield call(displayMerchant);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }

}
function* ResetPassword(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let payLoad = {
            email: action.email
        }
        const response: any = yield service.merchant.resetPassword(payLoad, {});
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message)
            yield call(displayMerchant);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }

}
function* editMerchant(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.edit(action.id, action.data, { "Content-type": "application/json" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            action.navigate("/merchant")
            yield call(displayMerchant);

        } else {
            toast.error(response.data.message)
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }


}
function* deleteMerchant(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.delete(action.id);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(displayMerchant);
            // yield put({ type: EditResponse, data });
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }
}
function* displayMerchant(action?: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.view({ search: action?.search, created_from: action?.created_from, created_to: action?.created_to, page: action?.page, limit: action?.limit });
        const data = yield response.data;
        if (response.status === 200) {
            yield put({ type: ResponseMerchant, data })
            toast.success(response.message)
        } else {
            toast.error(response.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }


}
function* displayCategoryList(): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.listCategory();
        const data = yield response.data;
        if (response.status === 200) {
            yield put({ type: Display_CategoryData, data })
            // toast.success(response.data.message); 
        } else {
            // toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }

}
function* displayPackagesList(): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.listPackages();
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.message);
            yield put({ type: Display_PackageData, data })
        }
        else {
            toast.error(response.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }

}
function* displayMerchantById(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.getbyid(action.id);
        const data = yield response.data;

        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: Get_Edit_Response, data });
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }

}
function* changeStatusById(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.changeStatusbyid(action.id);
        const data = yield response.data;

        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(displayMerchant);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        yield call(displayMerchant);
        return error
    }

}
function* getBranchesList(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.merchant.getBranches(action.id);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: BranchesList, data });
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }
}
function* changeMerchantEmail(action: any): any {
    try {
        const response: any = yield service.merchant.changeMerchantEmail(action.payload);
        if (response.status === 200) {
            const response: any = yield service.merchant.getbyid(action.merchantId);
            const data = yield response.data;
            if (response.status === 200) {
                yield put({ type: Get_Edit_Response, data });
            }
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }
}
function* getMerchantDetail(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
          const response: any = yield service.merchant.getMerchantDetail(action.customer_id,{page:action?.page,limit:action?.limit,istransaction:action?.istransaction,transaction_type:action?.transaction_type});
          const data = yield response.data;
          if (response.status === 200) {
              toast.success(response.data.message)
              yield put({ type: GetMerchantDetailResponse, data });
          } else {
              toast.error(response.message)
          }
          return response; // add this line to return the response
      } catch (error: any) {
          console.log("error", error)
          toast.error(error?.response?.data?.message);
          return error
      }
  }
function* productSaga() {
    yield takeLatest(Add_Merchant, addMerchant)
    yield takeLatest(Edit_Merchant, editMerchant)
    yield takeLatest(Delete_Merchant, deleteMerchant)
    yield takeLatest(Display_Merchant, displayMerchant)
    yield takeLatest(getResponseById, displayMerchantById)
    yield takeLatest(Display_Category_List, displayCategoryList)
    yield takeLatest(Display_Package_List, displayPackagesList)
    yield takeLatest(Change_Status, changeStatusById)
    yield takeLatest(getBranches, getBranchesList)
    yield takeLatest(Reset_Merchant_Password, ResetPassword)
    yield takeLatest(Change_Merchant_Email, changeMerchantEmail)
    yield takeLatest(GetMerchantDetailById, getMerchantDetail)
}

export default productSaga;