import {Add_Load_Money,Display_Load_Money,Edit_Load_Money,Delete_Load_Money} from './actionType'
export const AddLoad_Money=(data: { currency: string; amount:string; points:string  })=>{
    
    return {
        type: Add_Load_Money,
        data,
    }
}
export const EditLoad_Money=(id: string ,data: { currency: string; amount:string; points:string })=>{
    return {
        type: Edit_Load_Money,
        id,
        data
    }
}
export const DeleteLoad_Money=(id: string)=>{
    return {
        type: Delete_Load_Money,
        id,
    }
}
export const DisplayLoad_Money=({search,page,limit}:any)=>{
    return {
        type: Display_Load_Money,
        search,
        page,
        limit
    }
}