import {Add_Package,Display_Package,Edit_Package,Delete_Package} from './actionType'
export const AddPackage=(data: { name: string; })=>{
    return {
        type: Add_Package,
        data,
    }
}
export const EditPackage=(id: string ,data: { name: string; })=>{
    return {
        type: Edit_Package,
        id,
        data
    }
}
export const DeletePackage=(id: string)=>{
    return {
        type: Delete_Package,
        id,
    }
}
export const DisplayPackage=()=>{
    return {
        type: Display_Package,
       
    }
}