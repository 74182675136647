import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from  "../dictionary"
import axios from "axios";
const {transaction } = Dictionary
const TransactionServices ={
    view: async (paramsObj:any)=>{
        return axios.get(BASE_URL + transaction.viewTransaction(),{params: paramsObj} );
    },
    downloadCsv: async (paramsObj:any)=>{
        return axios.get(BASE_URL + transaction.downloadCsv(),{params: paramsObj} );
    }
}
export default TransactionServices;
