import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames"
import Table from '../../components/Common/dataTable';
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const DetailSection = (props: any) => {

    const [isMenu, setisMenu] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [transactionData, setTransactionData] = useState<any>()
    const [TransactionSortData, setTransactionSortData] = useState<any>([])
    const CustomerId: string | any = useParams()
    const customer_id = CustomerId?.id
    const dispatch = useDispatch();
    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) => index + 1,
            maxWidth: "5%",
        },
        {
            name: "Transaction Type",
            selector: (i: any) => i?.transaction_type,
            conditionalCellStyles: [
                {
                    when: (row: any) => row.transaction_type === "AWARD",
                    classNames: ['green'],
                },
                {
                    when: (row: any) => row.transaction_type === "REDEEM",
                    classNames: ['red'],
                },
                {
                    when: (row: any) => row.transaction_type === "LOAD",
                    classNames: ['blue'],
                }
            ],
            maxWidth: "15%",
        },
        {
            name: "customer name",
            selector: (i: any) => i?.customer?.user?.first_name + '' + i?.customer?.user?.last_name,
            maxWidth: "18%",
        },
        {
            name: "branch",
            selector: (i: any) => i?.branch?.name,
            maxWidth: "18%",
        },
        {
            name: "Transaction Id",
            selector: (i: any) => i?.transaction_id,
            maxWidth: "18%",
        },

        {
            name: "Amount",
            selector: (i: any) => i?.amount,
            maxWidth: "8%",
        },
        {
            name: "Points",
            selector: (i: any) => i?.points,
            maxWidth: "8%",
        },
        {
            name: "Created Date",
            selector: (i: any) => (moment(i?.createdAt).format("DD/MM/YYYY hh:mm A")),
            maxWidth: "22%",
        },
    ]

    useEffect(() => {
        setTransactionData(props?.transactionDetails?.transactions)
    }, [props?.transactionDetails])

    // useEffect(() => {
    //     if (props?.transactionDetails?.transactions) {
    //         setTransactionSortData(props?.transactionDetails?.transactions)
    //     }
    // }, [transactionData])
    useEffect(() => {
        props.setCurrentPage(currentPage)
        props.setLimitPerPage(limitPerPage)
    }, [currentPage, limitPerPage])
    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const toggleMenu = () => {
        setisMenu(!isMenu)
    }
    // const handleSorting = (data: any, type: any) => {
    //     let temp: any = []
    //     data?.map((item: any) => {
    //         if (item.transaction_type === type) {
    //             temp.push(item)
    //         }
    //         else if (type === "ALL") {
    //             temp.push(item)
    //         }
    //     })
    //     setTransactionSortData(temp)
    // }

    const handleTransactionSorting = (e: string) => {
        props.setTransactionType(e)
    }
    return (
        <React.Fragment>
            <Col xl={9}>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <div className="mt-4">
                                    <Nav pills className="bg-light rounded" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleTab("1")
                                                    // handleSorting(transactionData, "ALL")
                                                    handleTransactionSorting('')
                                                }}
                                            >
                                                ALL
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleTab("2")
                                                    // handleSorting(transactionData, "AWARD")
                                                    handleTransactionSorting('AWARD')
                                                }}
                                            >
                                                Award
                                            </NavLink>

                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === "3",
                                                })}
                                                onClick={() => {
                                                    toggleTab("3")
                                                    // handleSorting(transactionData, "REDEEM")
                                                    handleTransactionSorting('REDEEM')
                                                }}
                                            >
                                                Redeem
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeTab === "4",
                                                })}
                                                onClick={() => {
                                                    toggleTab("4")
                                                    // handleSorting(transactionData, "LOAD")
                                                    handleTransactionSorting('LOAD')
                                                }}
                                            >
                                                Load
                                            </NavLink>
                                        </NavItem>

                                    </Nav>

                                    <TabContent activeTab={activeTab} className="mt-4">
                                        <TabPane tabId="1" id="buy-tab">
                                            <div className="container-fluid custom-width-table">
                                                <Table
                                                    columns={columns}
                                                    data={transactionData}
                                                    setCurrentPage={setCurrentPage}
                                                    setLimitPerPage={setLimitPerPage}
                                                    totalCount={props?.transactionDetails?.count}
                                                />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2" id="sell-tab">
                                            <div className="container-fluid custom-width-table">
                                                <Table
                                                    columns={columns}
                                                    data={transactionData}
                                                    setCurrentPage={setCurrentPage}
                                                    setLimitPerPage={setLimitPerPage}
                                                    totalCount={props?.transactionDetails?.count}
                                                />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="3" id="sell-tab">
                                            <div className="container-fluid custom-width-table">
                                                <Table
                                                    columns={columns}
                                                    data={transactionData}
                                                    setCurrentPage={setCurrentPage}
                                                    setLimitPerPage={setLimitPerPage}
                                                    totalCount={props?.transactionDetails?.count}
                                                />
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="4" id="sell-tab">
                                            <div className="container-fluid custom-width-table">
                                                <Table
                                                    columns={columns}
                                                    data={transactionData}
                                                    setCurrentPage={setCurrentPage}
                                                    setLimitPerPage={setLimitPerPage}
                                                    totalCount={props?.transactionDetails?.count}
                                                />
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </React.Fragment>
    )
}

export default DetailSection