import { ResponsePackage } from './actionType'

const initialState = {
    PackageData:[]
}
export const Package = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case ResponsePackage:
            return {
                PackageData:action.data
            }
        default:
            return state;
    }
}
export default Package