import { put, takeLatest } from 'redux-saga/effects'
import { Admin_Dashboard, Response_Admin_Dashboard } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* admindashboard(): Generator<any, AxiosResponse<any>, any> {

    try {
        const response: any = yield service.dashboard.view();
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: Response_Admin_Dashboard, data })
        } else {
            toast.success(response.data.message);
        }
        return response;
    } catch (error:any) {
        console.log(error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* productSaga() {
    yield takeLatest(Admin_Dashboard, admindashboard)
}

export default productSaga;