import React, { useEffect, useState } from "react";
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Table from '../../components/Common/dataTable';
// Formik Validation
import { DisplayCustomer, changeCustomerStatus } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupModel from "../../components/Common/Popup"
import moment from "moment";
import { Tooltip } from "react-tooltip";
import Constants from "../../constants/Constants";


const Index = () => {
    const [CustomerData, setCustomerData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(2)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [statusPopup, setStatusPopup] = useState(false)
    const [statusId, setStatusId] = useState('');
    const [statusType, setStatusType] = useState('');

    const { response, responseCount } = useSelector((state: any) => ({
        response: state?.ManageCustomer?.responseCutomerData?.data,
        responseCount: state?.ManageCustomer?.responseCutomerData?.count,
    }));
    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) => <span
            >
                {index + 1}
            </span>,
            maxWidth: "5%",
        },
        {
            name: "Customer Name",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.user?.first_name + " " + i?.user?.last_name}>
                    {i?.user?.first_name + " " + i?.user?.last_name}
                </span>
                <Tooltip id={`my-tooltip${i.id}`} />
            </>,

            maxWidth: "12%",
            compact: true,
            sortable: true,
        },
        {
            name: "Email",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.user?.email}>
                    {i?.user?.email}
                </span>
            </>,
            minWidth: "18%",
        },
        {
            name: "Gender",
            selector: (i: any) => <span className="text-uppercase">{i?.user?.gender}</span>,
            maxWidth: "8%",
        },
        {
            name: "Mobile",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={"(" + i?.user?.country_code + ")" + i?.user?.mobile}>
                    {"(" + i?.user?.country_code + ") " + i?.user?.mobile}
                </span>
                <Tooltip id={`my-tooltip${i.id}`} />
            </>,

            maxWidth: "15%",
        },
        {
            name: "DOB",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={(moment(i?.user?.dob).format("DD/MM/YYYY"))}>
                    {(moment(i?.user?.dob).format("DD/MM/YYYY"))}
                </span>
            </>,
            maxWidth: "10%",
        },
        {
            name: "Status",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    className={i?.user?.status === Constants.STATUS.ACTIVE ? "green" : "red"}
                    data-tooltip-content={i?.user?.status === Constants.STATUS.ACTIVE ? "Active" : "Deactive"}>
                    {i?.user?.status === Constants.STATUS.ACTIVE ? "Active" : "Deactive"}
                </span>
            </>,
            minWidth: "10%",
        },
        {
            name: "Registered",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    className={i?.user?.isRegistered ? "green" : "red"}
                    data-tooltip-content={i?.user?.isRegistered ? "Registered" : "Not Registered"}>
                    {i?.user?.isRegistered ? "Registered" : "Not Registered"}
                </span>
            </>,
            minWidth: "10%",
        },
        {
            name: "Action",
            cell: (i: any) =>
                <span className="cursor">
                    <UncontrolledDropdown>
                        <DropdownToggle className="" id="dropdownMenuClickable" data-bs-toggle="dropdown" direction="left" data-bs-auto-close="false" aria-expanded="false">
                            <i className="fas fa-ellipsis-h"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={() => { navigate(`customer-detail/${i?.id}`) }} >Detail</DropdownItem>
                            <DropdownItem onClick={() => _handleStatus(i?.user?.id, i?.user?.status)} >{i?.user?.status === Constants.STATUS.ACTIVE ? "Deactivate" : "Activate"}</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </span>,
            minWidth: "8%",
        },
    ]
    const _handleStatus = (id: string, status: string) => {
        setStatusId(id)
        setStatusPopup(true)
        setStatusType(status)
    }

    const handleStatusChange = () => {
        dispatch(changeCustomerStatus(statusId))
    }
    useEffect(() => {
        setCustomerData(response)
        setTotalCount(responseCount)
    }, [response])

    useEffect(() => {
        dispatch(DisplayCustomer({ search: '', page: currentPage, limit: limitPerPage }))
    }, [currentPage, limitPerPage])

    const handleSearch = (e: string) => {
        let searchValue: string = e
        setTimeout(() => {
            dispatch(DisplayCustomer({ search: searchValue, page: currentPage, limit: limitPerPage }))
        }, 500)
    }
    return (
        <>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Manage Customer" />
                            <div className="text-end">
                                <Button className="color"
                                    onClick={() => {
                                        navigate("addCustomer")
                                    }}>Add Customer</Button>
                            </div>
                        </div>
                    </div>
                    <div className="card col-11">
                        <div className="row ">
                            <div className="col-6">
                                <form className="app-search d-none d-lg-block w-50">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="container-fluid custom-width-table">
                            <Table
                                columns={CustomerData && columns}
                                data={CustomerData ? CustomerData : []}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setLimitPerPage={setLimitPerPage}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {statusPopup && (
                <PopupModel
                    ApiName={handleStatusChange}
                    popUpFunction={setStatusPopup}
                    title="Confirmation"
                    paragraph={`Are you sure you want to ${statusType === Constants.STATUS.ACTIVE ? "Deactivate" : "Activate"}  the Customer ?`}
                />
            )}
        </>
    );
};

export default Index;


