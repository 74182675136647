import React, { useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';

function Index(props: any) {

    const [show, setShow] = useState(true);
    const [comment, setComment] = useState('');

    const handleClose = () => {
        setShow(false)
        props.ApiName()
        props.popUpFunction(false)
    };

    const handleCloseForApi = () => {
        props.ApiName()
        setShow(false)
        props.popUpFunction(false)

    };

    return (
        <>
            <Modal centered={true} isOpen={show} toggle={() => handleClose()} >
                <div className="modal-dialog-centered d-block position-relative">
                    <ModalBody>
                        <div className="px-4 text-center">
                            <div className="white-circle pt-2 position-absolute" style={{ top: '-17%', left: '44%' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="60.199" height="60.199" viewBox="0 0 60.199 60.199">
                                    <g id="Group_7801" data-name="Group 7801" transform="translate(1.5 1.5)">
                                        <path id="Path_4763" data-name="Path 4763" d="M60.2,31.6A28.6,28.6,0,1,1,31.6,3,28.6,28.6,0,0,1,60.2,31.6Z" transform="translate(-3 -3)" fill="white" stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                        <path id="Path_4764" data-name="Path 4764" d="M18,12V23.44" transform="translate(10.6 5.16)" fill="#FFF" stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                        <path id="Path_4765" data-name="Path 4765" d="M18,24h0" transform="translate(10.6 16.04)" fill="red" stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                    </g>
                                </svg>
                            </div>
                            <h1 className="modal-title-name mt-4" style={{ color: "red" }}>{props.title}</h1>
                            {props.name &&
                                <p className="modal-title-caption mt-3 mb-0 mx-3" >{props.paragraph}{" "}<b>{props.name}</b> ?</p>
                            }
                            {!props.name &&
                                <p className="modal-title-caption mt-3 mb-0 mx-3" style={{ color: "red" }} >{props.paragraph}</p>
                            }
                            <Button className="color w-50 mt-5" onClick={() => handleClose()} >CLOSE</Button>
                        </div>
                    </ModalBody>

                </div>
            </Modal>
        </>
    )
}

export default Index
