import auth from './auth'
import category from './category'
import packages from './package'
import subAdmin from './subAdmin'
import merchant from './merchant'
import award_redeem from './award-redeem'
import transaction from "./transaction"
import award from './award'
import merchantQuery from './merchantQuery'
import userQuery from './userQuery'
import customer from './customer'
import load_money from './loadMoney'
import dashboard from './dashboard'
import image from './image'
const dictionary: any = {
    auth,
    category,
    packages,
    subAdmin,
    merchant,
    award_redeem,
    transaction,
    award,
    merchantQuery,
    userQuery,
    customer,
    load_money,
    dashboard,
    image,
}
export default dictionary
