import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody,
    Container, Input, Label, Form, FormFeedback,
    CardTitle, Button, Modal
} from "reactstrap";
import Table from '../../components/Common/dataTable';
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddPackage, DisplayPackage, DeletePackage, EditPackage } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";

import { IAddData, IEditData } from "./interface";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupModel from "../../components/Common/Popup"
import Constants from "../../constants/Constants";
const Index = () => {
    const [showModal, setShowModal] = useState(false);
    const [PackageName, setPackageName] = useState('');
    const [packageId, setpackageId] = useState('');
    const [PackageData, setPackageData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState()
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [search, setSearch] = useState("")
    const storageData: any = JSON.parse(localStorage.getItem("login_response") || '{}')
    const dispatch = useDispatch();
    const { PackageList } = useSelector((state: any) => ({
        PackageList: state?.Package?.PackageData
        ,
    }));
    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) => index + 1,
            minWidth: "45%",
        },
        {
            name: "Package Name",
            selector: (row: any, i: any) => row.name,
            minWidth: "45%",
            compact: true,
            sortable: true,
        },
        Constants.SCOPE.ADMIN === storageData.scope && (
            {
                name: "Action",
                selector: (i: any) =>
                    <span className="cursor">
                        <i className="mdi mdi-pencil fs-2 m-lg-1" onClick={() => {
                            _handlePackageUpdate(i)
                        }
                        } ></i>
                        <i className="bx bxs-trash fs-2" onClick={() => {
                            setDeleteConfirmation(true)
                            setDeleteId(i.id)
                        }}></i>
                    </span>,
                minWidth: "10%",
            }
        )
    ]
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: PackageName,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Package Name"),
            // PackageImage: Yup.string().required("Please Upload PackageImage"),
        }),

        onSubmit: (values: any, { resetForm }: any) => {
            let temp: IAddData = {
                name: values.name.trim(),
            }
            if (values.name.match(/^\s+$/)) {
                return values.name.trim()
            } else {
                if (packageId) {
                    dispatch(EditPackage(packageId, temp))
                    setpackageId('')
                    setPackageName('')
                    resetForm({ values: '' })
                } else {
                    dispatch(AddPackage(temp))
                    setPackageName('')
                    resetForm({ values: '' })
                }
                setShowModal(false)
            }
        }
    })
    useEffect(() => {
        setPackageData(PackageList?.data)
        setTotalCount(PackageList?.count)
        if (showModal === false) {
            setpackageId('')
            setPackageName('')
        }
    }, [PackageList?.data, showModal])

    useEffect(() => {
        // dispatch(DisplayPackage({ search: '' , page: currentPage, limit: limitPerPage}))
        dispatch(DisplayPackage())
    }, [currentPage, limitPerPage])

    const _handlePackageUpdate = (i: IEditData) => {
        setpackageId(i.id)
        setPackageName(i.name)
        setShowModal(true)
    }
    const handleDelete = () => {
        dispatch(DeletePackage(deleteId))
    }
    const handleSearch = (e: string) => {
        let searchValue: string = e
        setTimeout(() => {
            dispatch(DisplayPackage())
        }, 500)
        setSearch(e)
    }
    const handleReset = () => {
        validation.resetForm({
            values: {
                name: '',
            },
        })
    }
    return (
        <>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Manage Package" />
                            {Constants.SCOPE.ADMIN === storageData.scope ?
                                (<div className="text-end">
                                    <Button className="color"
                                        onClick={() => {
                                            handleReset()
                                            setShowModal(true)
                                        }}>Add Package</Button>
                                </div>) : (
                                    <div className="text-end" style={{ height: "34.5px" }}>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="card col-11">
                        <div className="row ">
                            <div className="col-6">
                                <form className="app-search d-none d-lg-block w-50">
                                    <div className="position-relative">
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" /> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <Table
                                columns={PackageData && columns}
                                data={PackageData ? PackageData : []}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setLimitPerPage={setLimitPerPage}
                                pagination={false}
                                paginationValue={8}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <Modal
                        isOpen={showModal}
                        role="dialog"
                        autoFocus={true}
                        centered
                        data-toggle="modal"
                        toggle={() => {
                            setShowModal(!showModal);
                        }}
                    >
                        {/* <div>
                        <ModalHeader
                            className="border-bottom-0"
                        toggle={() => {
                          setShowModal(!showModal);
                        }}
                        ></ModalHeader>
                    </div> */}
                        <div className="modal-body">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col >

                                        <CardBody>
                                            <CardTitle className="mb-4">Package</CardTitle>
                                            <div className="p-4">
                                                <Form
                                                    className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit(e);
                                                        return false;
                                                    }}
                                                >
                                                    <Row className="mb-4">
                                                        <Label
                                                            htmlFor="horizontal-firstname-Input"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Name
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                className="form-control"
                                                                placeholder="Enter Package"
                                                                type="text"
                                                                onChange={
                                                                    validation.handleChange
                                                                }
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.name || ""}
                                                                invalid={
                                                                    validation.touched.name && validation.errors.name ? true : false
                                                                }
                                                            />
                                                            {validation.touched.name && validation.errors.name ? (
                                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <Row className="text-center">
                                                        <Col >
                                                            <div>
                                                                <Button
                                                                    type="submit"
                                                                    color="primary"
                                                                    className="w-md color"
                                                                // onClick={() => { validation.values.name.length > 0 && handleAddPackage(validation.values) }}
                                                                >
                                                                    {packageId ? 'Update' : 'Add'}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>
                    <div>
                        {deleteConfirmation && (
                            <PopupModel
                                ApiName={handleDelete}
                                popUpFunction={setDeleteConfirmation}
                                title="Confirmation"
                                paragraph={`Are you sure you want to delete ?`}
                            />
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};

export default Index;
