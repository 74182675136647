import logo from "../images/profile-img.43b59e598ba15abe6eab.png"
import Adminavater from "./AdminImage.png"
import Cube3Logo from "./logoAdmin.png"
import LoginLogo from "./Login_img.png"
import WhiteLogo from "./cube_3 logo.svg"
import loginTwo from "./illustration.svg"
import lightlogo from "../../assets/images/logo-light.svg"
import logoImg from "../../assets/images/logo.svg"
import profile from "../../assets/images/profile-img.png"
import empty from './empty.png'
import error from './error-img.png'
import cube3Icon from "./Cube3-icon.png"
import ResetPass from "./reset_password.svg"
import qrscan from "./scan.png"

export default {
  logo,
  Adminavater,
  Cube3Logo,
  LoginLogo,
  WhiteLogo,
  loginTwo,
  lightlogo,
  logoImg,
  profile,
  empty,
  error,
  cube3Icon,
  ResetPass,
  qrscan,
}
