import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';


function MyComponent(props: any) {
  const [pgainationValue, setPaginationValue] = useState(true)
  const [paginationPerPageValue, setPagainationPerPageValue] = useState(10)

  //   let paginationData:any
  useEffect(() => {
    if (props.pagination !== undefined || null) {
      setPaginationValue(props.pagination)
    }
    if (props.paginationValue !== undefined || null) {
      setPagainationPerPageValue(props.paginationValue)
    } else {
      setPagainationPerPageValue(10)
    }

  }, [props.pagination, paginationPerPageValue])


  return (
    <div className={`${props?.data?.length > 0 ? 'table-container' : ''}`} >
      {props?.data?.length !== 0 ? <DataTable
        columns={props.columns}
        data={props.data}
        // fixedHeader={true}
        pagination={pgainationValue}
        noDataComponent={<h3>No data available</h3>}
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        highlightOnHover
        // paginationPerPage={10} 
        paginationServer
        paginationTotalRows={props.totalCount}
        onChangeRowsPerPage={(limit) => props.setLimitPerPage(limit)}
        onChangePage={(page) => { props.setCurrentPage(page) }}
      /> : <div className='text-center m-4'>
        <h3>No data available</h3>
      </div>}
    </div>
  );
};

export default MyComponent;