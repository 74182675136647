import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
const { image } = Dictionary
const ImageServices = {
    add: async (data: {}, headers: any) => {
        return axios.post(BASE_URL + image.Image(), data, { headers: headers });
    },
    get: async () => {
        return axios.get(BASE_URL + image.Image());
    },
    delete: async (id:string) => {
        return axios.delete(BASE_URL + image.Image_By_Id(id));
    },
}
export default ImageServices;
