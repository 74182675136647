import { all, fork } from "redux-saga/effects";

//public
// import AuthSaga from "./auth/login/saga";
// import LayoutSaga from "./layout/saga";
import login from "./auth/saga";
import category from "./category/saga";
import packages from "./package/saga"
import subAdmin from "./subAdmin/saga"
import merchant from "./merchant/saga"
import award_redeem from "./award-redeem/saga"
import transaction from "./transaction-history/saga"
import award from "./award/saga";
import merchantQuery from "./merchantQuery/saga";
import userQuery from "./userQuery/saga";
import customer from "./manage-customer/saga"
import loadMoney from "./load-money/saga"
import dashboard from "./dashboard/saga"
import image from "./image/saga"
export default function* rootSaga() {
  
  //   //public
  //   // fork(AuthSaga),
  //   // fork(LayoutSaga),
  
  yield fork(login),
  yield fork(category),
  yield fork(packages),
  yield fork(subAdmin),
  yield fork(merchant),
  yield fork(award_redeem),
  yield fork(transaction),
  yield fork(award),
  yield fork(merchantQuery),
  yield fork(userQuery),
  yield fork(customer),
  yield fork(loadMoney),
  yield fork(dashboard)
  yield fork(image)
  
}
