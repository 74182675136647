import {Add_Award_Redeem,Display_Award_Redeem,Edit_Award_Redeem,Delete_Award_Redeem} from './actionType'
export const AddAward_Redeem=(data: { currency: string; transaction_type:string; amount:string; points:string  })=>{
    
    return {
        type: Add_Award_Redeem,
        data,
    }
}
export const EditAward_Redeem=(id: string ,data: { currency: string; transaction_type:string; amount:string; points:string })=>{
    return {
        type: Edit_Award_Redeem,
        id,
        data
    }
}
export const DeleteAward_Redeem=(id: string)=>{
    return {
        type: Delete_Award_Redeem,
        id,
    }
}
export const DisplayAward_Redeem=({search,page,limit}:any)=>{
    return {
        type: Display_Award_Redeem,
        search,
        page,
        limit
    }
}