import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
// import { api } from "../testInterceptor"
const { award_redeem } = Dictionary
const Award_Redeem_Services = {
    add: async (data: {}, headers: any) => {

        return axios.post(BASE_URL + award_redeem.addAward_Redeem(), data, { headers: headers });
    },
    delete: async (id: string) => {
        return axios.delete(BASE_URL + award_redeem.deleteAward_Redeem(id));
    },
    edit: async (id: string, data: {}, headers: any) => {
        return axios.put(BASE_URL + award_redeem.editAward_Redeem(id), data, { headers: headers });
    },
    view: async (paramsObj: any) => {
        return axios.get(BASE_URL + award_redeem.viewAward_Redeem(), { params: paramsObj });
    }
}
export default Award_Redeem_Services;
