import { put, takeLatest, call } from 'redux-saga/effects'
import { Add_Package, Display_Package, Edit_Package, Delete_Package, ResponsePackage } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addPackage(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let payload = {
            name: action.data.name,
        }
        const response: any = yield service.packages.add(payload, { "Content-type": "application/json" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(displayPackage);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* editPackage(action: any): Generator<any, AxiosResponse<any>, any> {
    let payload = {
        name: action.data.name,
    }
    try {
        const response: any = yield service.packages.edit(action.id, payload, { "Content-type": "application/json" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            // yield put({ type: EditResponse, data });
            yield call(displayPackage);

        } else {
            toast.error(response.data.message);
        }
        return response;
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }
    // add this line to return the response
}
function* deletePackage(action: any): Generator<any, AxiosResponse<any>, any> {
    try {

        const response: any = yield service.packages.delete(action.id);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(displayPackage);
            // yield put({ type: EditResponse, data });
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* displayPackage(action?:any): Generator<any, AxiosResponse<any>, any> {
    
    try {
        const response: any = yield service.packages.view();
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.message);
            yield put({ type: ResponsePackage, data })
        } else {
            toast.error(response.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* productSaga() {
    yield takeLatest(Add_Package, addPackage)
    yield takeLatest(Edit_Package, editPackage)
    yield takeLatest(Delete_Package, deletePackage)
    yield takeLatest(Display_Package, displayPackage)
}

export default productSaga;