import { combineReducers } from "redux";

import { ProfileData } from "./auth/reducers";
import Category from "./category/reducers";
import Package from "./package/reducers";
import SubAdmin from "./subAdmin/reducers";
import Merchant from "./merchant/reducers";
import Award_Redeem from "./award-redeem/reducers"
import TransactionHistory from "./transaction-history/reducers"
import { AwardData, getPointData,getQrResponse,getTransitionData } from "./award/reducers"
import MerchantQuery from "./merchantQuery/reducers"
import UserQuery from "./userQuery/reducers"
import ManageCustomer from "./manage-customer/reducers"
import Load_Money from "./load-money/reducers"
import Dashboard from "./dashboard/reducers"
import Images from "./image/reducers"
const rootReducer = combineReducers({
  Category,
  ProfileData,
  Package,
  SubAdmin,
  Award_Redeem,
  TransactionHistory,
  AwardData,
  getPointData,
  MerchantQuery,
  UserQuery,
  Merchant,
  ManageCustomer,
  Load_Money,
  Dashboard,
  Images,
  getQrResponse,
  getTransitionData
});

export default rootReducer;
