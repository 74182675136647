import { Display_Category, Add_Category, ResponseCategory } from './actionType'

const initialState = {
    data: []
}
 const Category = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case ResponseCategory:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export default Category