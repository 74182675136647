import React, { useEffect, useState } from "react";

import {
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Container,
    Label,
    Input,
    Button,
    Modal,
    Form,
    FormFeedback,
    ModalHeader,
} from "reactstrap"
import { ChangePassword, ProfileDisplay } from "../../store/action"
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { ChangePasswrd, ProfileDetails } from "./interface";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik";

const Profile = () => {

    //meta title

    const [showModal, setShowModal] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [dob, setDob] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [gender, setGender] = useState('');
    const [status, setStatus] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const checkData: any = useSelector(state => state);
    useEffect(() => {
        dispatch(ProfileDisplay())
        handleProfile(checkData?.ProfileData?.data)
    }, [])
    const validation = useFormik<ChangePasswrd>({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            newPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Please Enter Your oldPassword"),
            newPassword: Yup.string().required("Please Enter Your newPassword"),
        }),
        onSubmit: (values) => {
            setShowModal(false);
            dispatch(ChangePassword(values, navigate))
            // dispatch(loginUser(values, props.router.navigate));
        }
    });

    useEffect(() => {
        // dispatch(ProfileDisplay())
        handleProfile(checkData?.ProfileData?.data)
    }, [checkData?.ProfileData?.data])

    const handleProfile = (e: ProfileDetails) => {
        setFirstName(e?.first_name)
        setLastName(e?.last_name)
        setEmail(e?.email)
        setMobile(e?.mobile)
        setDob(moment(e?.dob).format("YYYY-MM-DD"))
        setCountryCode(e?.country_code)
        setGender(e?.gender)
        setStatus(e?.status)
        setCreatedDate(moment(e?.createdAt).format("YYYY-MM-DD"))
    }

    return (
        <React.Fragment>

            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Profile" />
                            <div className="text-end">
                                <Button
                                    className="color"
                                    onClick={() => setShowModal(true)}>Change Password</Button>
                            </div>
                        </div>

                    </div>
                    <div className="card col-11">
                        <div className="justify-content-between align-items-center">

                            <Card>
                                <CardBody>

                                    <Row className="mb-3">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className=" col-form-label"
                                            >
                                                First Name
                                            </label>
                                            <div className="col-md-12">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    // defaultValue="Artisanal kale"
                                                    value={firstName}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className=" col-form-label"
                                            >
                                                Last Name
                                            </label>
                                            <div className="col-md-12">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    // defaultValue="Artisanal kale"
                                                    value={lastName}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className=" col-form-label"
                                            >
                                                Dob
                                            </label>
                                            <div className="col-md-12">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    // defaultValue="Artisanal kale"
                                                    value={dob}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-form-label"
                                            >
                                                Gender
                                            </label>
                                            <div className="col-md-12">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    // defaultValue="Artisanal kale"
                                                    value={gender}
                                                />
                                            </div>
                                        </div>
                                    </Row>

                                    <Row className="mb-3">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-form-label"
                                            >
                                                Create Date
                                            </label>
                                            <div className="col-md-12">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    //defaultValue="Artisanal kale"
                                                    value={createdDate}
                                                // onChange={(e)=>{}}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-form-label"
                                            >
                                                Status
                                            </label>
                                            <div className="col-md-12">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    // defaultValue="Artisanal kale"
                                                    value={status}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-form-label"
                                            >
                                                Email
                                            </label>
                                            <div className="col-md-12">
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    // defaultValue="Artisanal kale"
                                                    value={email}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row ">
                                                <div className="col-2" style={{ marginTop: "9px" }}>
                                                    <label
                                                        htmlFor="example-text-input"
                                                    // className="col-md-2 col-form-label"
                                                    >
                                                        Code
                                                    </label>
                                                    <div >
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            // defaultValue="Artisanal kale"
                                                            value={countryCode}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 WidthProfile">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className=" col-form-label"
                                                    >
                                                        Mobile
                                                    </label>
                                                    <div className="col-md-12">
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            // defaultValue="Artisanal kale"
                                                            style={{ width: "104%" }}
                                                            value={mobile}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    isOpen={showModal}
                    role="dialog"
                    autoFocus={true}
                    centered
                    data-toggle="modal"
                    toggle={() => {
                        setShowModal(!showModal);
                    }}
                    style={{ width: "350px" }}
                >
                    <ModalHeader className="justify-content-center">
                        <div>
                            Change Password
                        </div>
                    </ModalHeader>
                    <div className="modal-body mx-3">
                        <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <div className="mb-3">
                                <Label className="form-label">Old Password</Label>
                                <Input
                                    name="oldPassword"
                                    className="form-control"
                                    placeholder="Enter Old Password"
                                    type="password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.oldPassword || ""}
                                    invalid={
                                        validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                                    }
                                />
                                {validation.touched.oldPassword && validation.errors.oldPassword ? (
                                    <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label">New Password</Label>
                                <Input
                                    name="newPassword"
                                    value={validation.values.newPassword || ""}
                                    type="password"
                                    placeholder="Enter New Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.newPassword && validation.errors.newPassword ? true : false
                                    }
                                />
                                {validation.touched.newPassword && validation.errors.newPassword ? (
                                    <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                ) : null}
                            </div>
                            <div className="mt-3 text-center  ">
                                <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                // onClick={()=>handleClick(validation.values)}
                                >
                                    Confirm
                                </button>
                            </div>

                        </Form>
                    </div>
                </Modal >
            </div>

        </React.Fragment >
    )
}

export default Profile
