import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from  "../dictionary"
import axios from "axios";
const {merchant } = Dictionary
const merchantServices ={
    add: async (data : {},headers:any )=>{
     
        return axios.post(BASE_URL + merchant.addMerchant(), JSON.stringify(data),{ headers: headers });
    },
    resetPassword: async (data : {},headers:any )=>{
     
        return axios.post(BASE_URL + merchant.resentPasswordLink(), data,{ headers: headers });
    },
    delete: async (id : string)=>{
       
        return axios.delete(BASE_URL + merchant.deleteMerchant(id) );
    },
    edit: async (id : string,data : {},headers:any )=>{
     
        return axios.put(BASE_URL + merchant.editMerchant(id),data,{ headers: headers });
    },
    getbyid: async (id : string )=>{
     
        return axios.get(BASE_URL + merchant.getMerchant(id));
    }, 
    getBranches: async (id : string )=>{
        return axios.get(BASE_URL + merchant.getBranches(id));
    },
    view: async (paramsObj:any)=>{
        return axios.get(BASE_URL + merchant.viewMerchant(),{params : paramsObj} );
    },
    listCategory: async ()=>{
 
        return axios.get(BASE_URL + merchant.viewCategory() );
    },
    listPackages: async ()=>{
        
        return axios.get(BASE_URL + merchant.viewPackage() );
    },
    changeStatusbyid: async (id : string )=>{
     
        return axios.put(BASE_URL + merchant.changeStatus(id));
    },
    changeMerchantEmail: async (payload : {} )=>{
     
        return axios.put(BASE_URL + merchant.changeEmail(),payload);
    },
    getMerchantDetail: async (id : string,paramsObj:any )=>{
        return axios.get(BASE_URL + merchant.getMerchantDetails(id),{params : paramsObj});
    },
}
export default merchantServices;
