import React, { useEffect, useState, useRef } from "react";
import {
    Row, Col, CardBody, Container, Input, Label, Form, FormFeedback,
    CardTitle, Button, Modal
} from "reactstrap";
import Table from '../../components/Common/dataTable';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddCategory, DisplayCategory, DeleteCategory, EditCategory } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupModel from "../../components/Common/Popup"
import Images from "../../assets/images";
const Index = () => {
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [imageErrorMessage, setImageErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(2)
    const [categoryName, setCategoryName] = useState('');
    const [categoryId, setCategoryid] = useState('');
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [updateImageUrl, setUpdateImageUrl] = useState('');
    const [categoryData, setCategoryData] = useState<any>([])
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [search, setSearch] = useState("")
    const inputFileRef = useRef<any>();
    const dispatch = useDispatch();
    const { Categories } = useSelector((state: any) => ({
        Categories: state?.Category?.data,
    }));
    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) => index + 1,
            maxWidth: "10%",
        },
        {
            name: "Category Name",
            selector: (i: any) => i.name,
            minWidth: "22%",
            compact: true,
            sortable: true,
        },
        {
            name: "Image",
            selector: (i: any) => <div className="my-2" >

                {i.image === null || i.image === '' ?
                    <img
                        src={Images.empty}
                        className=".rounded-4"
                        style={{ width: "63px", height: "54px" }}
                    />
                    :
                    <img
                        src={`${i.image}`}
                        className=".rounded-4"
                        style={{ width: "63px", height: "54px" }}
                    />

                }


            </div>,
            minWidth: "22%",
        },
        {
            name: "Status",
            selector: (i: any) =>
                <span className={`${i.status === "active" ? "bg-success" : "bg-danger"} fs-6 badge bg-secondary`}>
                 {i.status}
                </span>,
            minWidth: "22%",
        },
        {
            name: "Action",
            cell: (i: any) =>
                <span className="cursor">
                    <i className="mdi mdi-pencil fs-2 m-lg-1" onClick={() => {
                        setImageUrl('')
                        _handleCategoryUpdate(i)
                    }
                    } ></i>
                    <i className="bx bxs-trash fs-2" onClick={() => {
                        setDeleteId(i.id)
                        setDeleteConfirmation(true)
                    }}></i>
                    {/* <ReactTooltip /> */}
                </span>,
            minWidth: "10%",
        },
    ]
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: categoryName,
            categoryImage: image ? image : updateImageUrl,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Category"),
            categoryImage: Yup.string().required("Please Upload Category Image"),
        }),

        onSubmit: (values: any, { resetForm }: any) => {
            let temp = {
                name: values.name,
                image: imageUrl ? imageUrl : updateImageUrl
            }
            if (temp.image) {
                if (categoryName.length > 0) {
                    dispatch(EditCategory(categoryId, temp))
                    setCategoryid('')
                    setCategoryName('')
                    setImage('')
                    resetForm({ values: '' })
                    setShowModal(false)
                } else {
                    dispatch(AddCategory(temp))
                    setCategoryName('')
                    setImage('')
                    resetForm({ values: '' })
                    setShowModal(false)
                }
            } else {
                setImageErrorMessage("Please Upload Image")
            }
        }
    });
    const _handleImageChgange = (e: any) => {
        let image_Url = URL.createObjectURL(e.target.files[0])
        setImagePreview(image_Url)
        setImageUrl(e.target.files[0])
    }
    useEffect(() => {
        setCategoryData(Categories?.data)
        setTotalCount(Categories?.count)
    }, [Categories?.data])

    useEffect(() => {
        dispatch(DisplayCategory({ search: '', page: currentPage, limit: limitPerPage }))

    }, [currentPage, limitPerPage])

    const _handleCategoryUpdate = (i: any) => {
        setCategoryid(i.id)
        setCategoryName(i.name)
        setImagePreview(i.image)
        // setImage(i.image)
        setUpdateImageUrl(i.image)
        setShowModal(true)
    }
    const _chooseFileToUpload = () => {
        if (inputFileRef && inputFileRef.current) {
            inputFileRef.current.click();
        }
    };
    const handleDelete = () => {
        dispatch(DeleteCategory(deleteId))
    }
    const handleSearch = (e: string) => {
        let searchValue: string = e.trim()
        setTimeout(() => {
            dispatch(DisplayCategory({ search: searchValue, page: currentPage, limit: limitPerPage }))
        }, 500)
        setSearch(e)
    }
    const handleReset = () => {
        setCategoryName('')
        setImagePreview('')
        setUpdateImageUrl('')
        validation.resetForm({
            values: {
                name: '',
                categoryImage: '',
            },
        })
    }
    return (
        <>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Manage Categories" />
                            <div className="text-end">
                                <Button
                                    className="color"
                                    onClick={() => {
                                        handleReset()
                                        setShowModal(true)
                                        setImagePreview('')
                                    }}>Add Category</Button>
                            </div>
                        </div>
                    </div>
                    <div className="card col-11">
                        <div className="row ">
                            <div className="col-6">
                                <form className="app-search d-none d-lg-block w-50">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="container-fluid">

                            <Table
                                columns={categoryData && columns}
                                data={categoryData ? categoryData : []}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setLimitPerPage={setLimitPerPage}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <Modal
                        isOpen={showModal}
                        role="dialog"
                        autoFocus={true}
                        centered
                        data-toggle="modal"
                        toggle={() => {
                            setShowModal(!showModal);
                        }}
                    >

                        <div className="modal-body">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col >
                                        {/* <Card> */}
                                        <CardBody>
                                            <CardTitle className="mb-4">Category</CardTitle>
                                            <div className="p-4">
                                                <Form
                                                    className="form-horizontal"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        // return false;
                                                    }}
                                                >
                                                    <Row className="mb-4">
                                                        <Label
                                                            htmlFor="horizontal-firstname-Input"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Name
                                                        </Label>
                                                        <Col sm={9}>
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                className="form-control"
                                                                placeholder="Enter Category"
                                                                type="text"
                                                                onChange={(e) => validation.handleChange(e)}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.name || ""}
                                                                invalid={
                                                                    validation.touched.name && validation.errors.name ? true : false
                                                                }
                                                            />
                                                            {validation.touched.name && validation.errors.name ? (
                                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                            ) : null}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Label
                                                            htmlFor="horizontal-email-Input"
                                                            className="col-sm-3 col-form-label"
                                                        >
                                                            Image
                                                        </Label>
                                                        <Col sm={9}>

                                                            <div
                                                                className={''}
                                                                style={{ marginTop: "14px" }}
                                                            >
                                                                <div className="avatar-upload">
                                                                    <div className="avatar-edit">
                                                                        <input
                                                                            type="file"
                                                                            id="imageUpload"
                                                                            accept="image/*"
                                                                            style={{ display: "none" }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="avatar-preview cursor-pointer categoryImageBox"
                                                                        onClick={() => _chooseFileToUpload()}
                                                                    >
                                                                        <div
                                                                            id="imagePreview"
                                                                            style={{
                                                                                backgroundImage: `url(${imagePreview})`,
                                                                            }}
                                                                            className="backgroundimage m-3"
                                                                        >

                                                                            {/* {imagePreview && ( */}
                                                                            <p className={`m-auto mb-0 p-3 text-center font-w500 fs-18 all-color ${imagePreview && "addcustomimage"}`}>
                                                                                Select Image
                                                                            </p>
                                                                            {/* )} */}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    name="categoryImage"
                                                                    type="file"
                                                                    ref={inputFileRef}
                                                                    accept="image/*"
                                                                    onChange={(e) => {
                                                                        validation.handleChange(e)
                                                                        _handleImageChgange(e)
                                                                    }}
                                                                    style={{ display: "none" }}
                                                                    onBlur={validation.handleBlur}
                                                                // value={validation.values.categoryImage || ""}
                                                                // invalid={
                                                                //     validation.touched.categoryImage && validation.errors.categoryImage && imagePreview.length === 0 ? true : false
                                                                // }
                                                                />
                                                            </div>
                                                            {validation.errors.categoryImage?.length !== 0 && validation.values.categoryImage.length === 0 ? (
                                                                <p className="errorColor">{validation.errors.categoryImage}</p>) : ''}
                                                        </Col>
                                                    </Row>
                                                    <Row className="text-center">
                                                        <Col >
                                                            <div>
                                                                <Button
                                                                    type="submit"
                                                                    color="primary"
                                                                    className="w-md"
                                                                >
                                                                    {categoryName.length > 0 ? 'Update' : 'Add'}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </CardBody>
                                        {/* </Card> */}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>
                </div>

                <div>
                    {deleteConfirmation && (
                        <PopupModel
                            ApiName={handleDelete}
                            popUpFunction={setDeleteConfirmation}
                            title="Confirmation"
                            paragraph={`Are you sure you want to delete ?`}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Index;
