import React, { useState } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap';

function PopupModel(props: any) {

    const [show, setShow] = useState(true);
    const [comment, setComment] = useState('');

    const handleClose = () => {
        setShow(false)
        props.popUpFunction(false)
    };

    const handleCloseForApi = () => {
        props.ApiName()
        setShow(false)
        props.popUpFunction(false)

    };

    return (
        <>
            <Modal centered={true} isOpen={show} toggle={() => handleClose()} >
                <div className="modal-dialog-centered d-block">
                    <ModalBody>
                        <div className="px-4 text-center">
                        <div className="white-circle pt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="60.199" height="60.199" viewBox="0 0 60.199 60.199">
                                <g id="Group_7801" data-name="Group 7801" transform="translate(1.5 1.5)">
                                    <path id="Path_4763" data-name="Path 4763" d="M60.2,31.6A28.6,28.6,0,1,1,31.6,3,28.6,28.6,0,0,1,60.2,31.6Z" transform="translate(-3 -3)" fill="none" stroke="#FBB539" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                    <path id="Path_4764" data-name="Path 4764" d="M18,12V23.44" transform="translate(10.6 5.16)" fill="none" stroke="#FBB539" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                    <path id="Path_4765" data-name="Path 4765" d="M18,24h0" transform="translate(10.6 16.04)" fill="none" stroke="#FBB539" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
                                </g>
                            </svg>
                        </div>
                            <h1 className="modal-title-name mt-2">{props.title}</h1>
                            {props.name &&
                                <p className="modal-title-caption mt-3 mb-0 mx-3" >{props.paragraph}{" "}<b>{props.name}</b> ?</p>
                            }
                            {!props.name &&
                                <p className="modal-title-caption mt-3 mb-0 mx-3" >{props.paragraph}</p>
                            }
                            {props.textarea &&
                                <>
                                    <div className="modal-title-caption mt-4 mb-0">
                                        <textarea
                                            className="p-1"
                                            style={{ border: '2px solid #163462', borderRadius: "15px", height: "120px" }}
                                            name="comment" id=""
                                            cols={25} rows={10}
                                            placeholder="Comment here..."
                                            value={comment}
                                            onChange={(e) => { props.getCommentPopup(e.target.value); setComment(e.target.value) }}
                                        >
                                        </textarea>
                                      
                                        {props.errormessage && (<div>
                                            <p style={{ color: "red" }}>{props.message}</p>
                                        </div>
                                        )}
                                    </div>
                                </>
                            }
                            <div className="text-center mt-3">
                                <div className='row align-items-center w-75 m-auto'>
                                    <div className='col-6'><Button className="color ps-4 pe-4" onClick={() => handleCloseForApi()}>Yes</Button></div>
                                    <div className='col-6'><Button className="color ps-4 pe-4" onClick={() => handleClose()} >No</Button></div>
                                </div>
                            </div>
                            
                        </div>
                    </ModalBody>

                </div>
            </Modal>
        </>
    )
}

export default PopupModel
