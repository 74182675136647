import { call, put, takeLatest } from 'redux-saga/effects'
import { Add_Images, Delete_Image, Get_Images, Get_Images_Response } from './actionType'
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* addImages(payload: any): any {
    let carouselArr = payload?.data?.carousel
    const formData = new FormData();
    Object.values(carouselArr).forEach((image: any) => formData.append('corousel', image));
    try {
        const response: any = yield service.Image.add(formData, { "Content-type": "multipart/form-data" });
        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(getImages);
        }
        return response;
    } catch (error: any) {
        console.log(error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* getImages(): any {
    try {
        const response: any = yield service.Image.get();
        const data = yield response.data
        if (response.status === 200) {
            yield put({ type: Get_Images_Response, data })
        }
        return response;
    } catch (error: any) {
        console.log(error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* deleteImage(action: any): any {
    try {
        const response: any = yield service.Image.delete(action?.id);
        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(getImages);
        }
        return response;
    } catch (error: any) {
        console.log(error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* imageSaga() {
    yield takeLatest(Add_Images, addImages)
    yield takeLatest(Get_Images, getImages)
    yield takeLatest(Delete_Image, deleteImage)
}

export default imageSaga;