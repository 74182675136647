import { Display_Query,ResponseQuery } from './actionType'

const initialState = {
    QueryData : []
}
const MerchantQuery = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case ResponseQuery:
            return {
                ...state,
                QueryData : action?.data 
            }
        default:
            return state;
    }
}
export default MerchantQuery