import { Response_Admin_Dashboard } from './actionType'

const initialState = {
    dashboard: []
}
const Admin_dashboard = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case Response_Admin_Dashboard:
            return {
                ...state,
                dashboard: action.data,
            };
        default:
            return state;
    }
}

export default Admin_dashboard