import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
// import { api } from '../testInterceptor'
const { packages } = Dictionary
const PackageServices = {
    add: async (data: {}, headers: any) => {

        return axios.post(BASE_URL + packages.addPackage(), data, { headers: headers });
    },
    delete: async (id: string) => {

        return axios.delete(BASE_URL + packages.deletePackage(id));
    },
    edit: async (id: string, data: {}, headers: any) => {

        return axios.put(BASE_URL + packages.editPackage(id), data, { headers: headers });
    },
    view: async () => {

        return axios.get(BASE_URL + packages.viewPackage());
    }
}
export default PackageServices;
