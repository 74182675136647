import { Add_Images, Get_Images, Delete_Image } from './actionType'

export const AddImages = (data: any) => {
    return {
        type: Add_Images,
        data,
    }
}
export const getImages = () => {
    return {
        type: Get_Images,
    }
}
export const deleteImage = (id:string) => {
    return {
        type: Delete_Image,
        id
    }
}