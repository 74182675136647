import { IMerchant,IMerchantAddData } from '../../pages/Merchant/interface'
import {Reset_Merchant_Password,Change_Status,Add_Merchant,Display_Merchant,Edit_Merchant,Delete_Merchant,getResponseById, Display_Package_List, Display_Category_List, getBranches, Change_Merchant_Email, GetMerchantDetailById} from './actionType'
export const AddMerchant=(data: IMerchantAddData,navigate:{})=>{
    return {
        type: Add_Merchant,
        data,
        navigate
    }
}
export const resetMerchantPassword=(email: string,)=>{
    return {
        type: Reset_Merchant_Password,
        email,
    }
}
export const EditMerchant=(id: string ,data: IMerchantAddData,navigate:{})=>{
    return {
        type: Edit_Merchant,
        id,
        data,
        navigate
    }
}
export const DeleteMerchant=(id: string)=>{
    return {
        type: Delete_Merchant,
        id,
    }
}
export const GetMerchantByID=(id: string)=>{
    return {
        type: getResponseById,
        id,
    }
}
export const changeStatus=(id: string)=>{
    return {
        type: Change_Status,
        id,
    }
}
export const getBranchList=(id: string)=>{
    return {
        type: getBranches,
        id,
    }
}
export const DisplayMerchant=({search,created_from,created_to,page,limit}:any )=>{
    return {
        type: Display_Merchant,
        search,
        created_from,
        created_to,
        limit,
        page
    }
}
export const GetCategoryList=()=>{
    return {
        type: Display_Category_List,
    }
}
export const GetPackageList=()=>{
    return {
        type: Display_Package_List,
    }
}
export const ChangeMerchantEmail=(payload:any,merchantId:string)=>{
    return {
        type: Change_Merchant_Email,
        payload,
        merchantId
    }
}
export const getMerchantById=({customer_id,page,limit,istransaction,transaction_type}:any)=>{
    return {
        type: GetMerchantDetailById,
        customer_id,
        page,
        limit,
        istransaction,
        transaction_type
    }
}