import { IAddData } from '../../pages/SubAdmin/interface'
import { Add_SubAdmin, Display_SubAdmin, Edit_SubAdmin, Delete_SubAdmin, getSubadminById, SubAdmin_Status, Reset_SubAdmin_Password } from './actionType'
export const AddSubAdmin = (data: IAddData, navigate: {}) => {
    return {
        type: Add_SubAdmin,
        data,
        navigate
    }
}
export const EditSubAdmin = (id: string, data: IAddData, navigate: {}) => {
    return {
        type: Edit_SubAdmin,
        id,
        data,
        navigate
    }
}
export const DeleteSubAdmin = (id: string) => {
    return {
        type: Delete_SubAdmin,
        id,
    }
}
export const GetSubAdminByID = (id: string) => {
    return {
        type: getSubadminById,
        id,
    }
}
export const DisplaySubAdmin = ({ search, page, limit }: any) => {
    return {
        type: Display_SubAdmin,
        search,
        page,
        limit
    }
}
export const changeSubAdminStatus = (id: string) => {
    return {
        type: SubAdmin_Status,
        id,
    }
}

export const resetSubAdminPassword = (email: string,) => {
    return {
        type: Reset_SubAdmin_Password,
        email,
    }
}