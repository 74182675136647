import PropTypes from "prop-types";
import React, { useState } from "react";
import Logo from "./../../assets/images/index"
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

const Sidebar = (props: any) => {
  const [toggle, setToggle] = useState(false)
  
  return (
    <React.Fragment>
       <div className={`vertical-menu ${toggle ? "" : 'overFlow'}`} >
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={Logo.WhiteLogo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={Logo.WhiteLogo} alt="" height="57" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
        {props.type !== "condensed" ? <SidebarContent setToggle={setToggle} toggle={toggle}/> : <SidebarContent setToggle={setToggle} toggle={toggle}/>}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

export default Sidebar
