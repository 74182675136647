import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import MetisMenu from "metismenujs";
import withRouter from "../Common/withRouter";
import { Link } from "react-router-dom";
import Constants from "../../constants/Constants";
import { withTranslation } from "react-i18next";

const SidebarContent = (props: { t: (arg0: string) => string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined , setToggle : any ,toggle:boolean}) => {
  const storageData: any = JSON.parse(localStorage.getItem("login_response") || '{}')
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: string | any[] | HTMLCollectionOf<HTMLAnchorElement>) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName: any = path.pathname;
    let matchingMenuItem = null;
    const ul: HTMLElement | null = document.getElementById("side-menu")
    const items = ul!.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName.split('/')[1] === items[i].pathname.split('/')[1]) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: { offsetTop: any; }) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    }
  }


  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li>
            <Link to="/dashboard" onClick={()=>tToggle()} >
              <i className="bx bxs-dashboard"></i>
              <span>{props.t("Dashboards")}</span>
            </Link>
          </li>
          <li>
                  <Link to="/#" className="has-arrow" onClick={()=>props.setToggle(!props.toggle)}>
              <i className="bx bx-cog"></i>
              <span>{props.t("Settings")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/category" onClick={()=>tToggle()}>{props.t("Manage Categories")}</Link>
              </li>
              <li>
                <Link to="/packages" onClick={()=>tToggle()}>{props.t("Manage Packages")}</Link>
              </li>
              <li className="overflow-hidden">
                <Link to="/award-redeem" onClick={()=>tToggle()}>{props.t("Manage Redeem / Award points ")}</Link>
              </li>
              <li>
                <Link to="/load-money" onClick={()=>tToggle()}>{props.t("Load Money conversion")}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/upload-images" onClick={()=>tToggle()}>
              <i className="bx bx-image-add"></i>
              <span>{props.t("Upload images (App)")}</span>
            </Link>
          </li>
          <li>
            <Link to="/merchant" onClick={()=>tToggle()}>
              <i className="bx bx-home-circle"></i>
              <span>{props.t("Manage Merchants")}</span>
            </Link>
          </li>
          {Constants.SCOPE.ADMIN === storageData.scope &&
            <li>
              <Link to="/subAdmin" onClick={()=>tToggle()}>
                <i className="mdi mdi-account"></i>
                <span>{props.t("Manage Sub Admins")}</span>
              </Link>
            </li>
          }
          <li>
            <Link to="/transaction" onClick={()=>tToggle()}>
              <i className="mdi mdi-history"></i>
              <span>{props.t("Transaction history")}</span>
            </Link>
          </li>
          <li>
            <Link to="/load-money-history" onClick={()=>tToggle()}>
              <i className="bx bx-dollar-circle"></i>
              <span>{props.t("Load Money history")}</span>
            </Link>
          </li>
          <li>
            <Link to="/manage-customer" onClick={()=>tToggle()}>
              <i className="bx bxs-user-detail"></i>
              <span>{props.t("Manage Customer")}</span>
            </Link>
          </li>
          <li>
            <Link to="/award" onClick={()=>tToggle()}>
              <i className="bx bx-award"></i>
              <span>{props.t("Award Points")}</span>
            </Link>
          </li>
          <li>
            <Link to="/merchant-query" onClick={()=>tToggle()}>
              <i className="mdi mdi-frequently-asked-questions"></i>
              <span>{props.t("Merchant queries")}</span>
            </Link>
          </li>
          <li>
            <Link to="/user-query" onClick={()=>tToggle()}>
              <i className="mdi mdi-comment-question"></i>
              <span>{props.t("Customer queries")}</span>
            </Link>
          </li>
          <li>
            <Link to="/profile" onClick={()=>tToggle()}>
              <i className="fas fa-user-tie"></i>
              <span>{props.t("Profile")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
