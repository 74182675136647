import { IAddData } from '../../pages/ManageCustomer/interface'
import { Add_Customer, Display_Customer, change_Customer_Status, getDetailById } from './actionType'
export const AddCustomer = (data: IAddData, navigate: {}) => {
    return {
        type: Add_Customer,
        data,
        navigate
    }
}
export const DisplayCustomer = ({ search, page, limit }: any) => {
    return {
        type: Display_Customer,
        search,
        page,
        limit
    }
}

export const getCustomerbyId = ({ customer_id, page, limit,transaction_type }: any) => {
    return {
        type: getDetailById,
        customer_id,
        page,
        limit,
        transaction_type
    }
}
export const changeCustomerStatus = (customer_id: any) => {
    return {
        type: change_Customer_Status,
        customer_id,
    }
}