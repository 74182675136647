export const countryCodeData = [
    {
        options:[
            {
                label: "(+1264) Anguilla",
                value: "+1264",
                "code": "AI"
            },
            {
                label: "(+1246) Barbados",
                value: "+1246",
                "code": "BB"
            },
            {
                label: "(+ 345) Cayman Islands",
                value: "+ 345",
                "code": "KY"
            },
            {
                label: "(+1767) Dominica",
                value: "+1767",
                "code": "DM"
            },
            {
                label: "(+1473) Grenada",
                value: "+1473",
                "code": "GD"
            },
            {
                label: "(+509) Haiti",
                value: "+509",
                "code": "HT"
            },
            {
                label: "(+91) India",
                value: "+91",
                "code": "IND"
            },
            
            {
                label: "(+1876) Jamaica",
                value: "+1876",
                "code": "JM"
            },
            {
                label: "(+1664) Montserrat",
                value: "+1664",
                "code": "MS"
            },
            {
                label: "(+1868) Trinidad and Tobago",
                value: "+1868",
                "code": "TT"
            },
        ]
      },
  ];