import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
const { subAdmin } = Dictionary
const SubAdminServices = {
    add: async (data: {}, headers: any) => {

        return axios.post(BASE_URL + subAdmin.addSubAdmin(), data, { headers: headers });
    },
    delete: async (id: string) => {

        return axios.delete(BASE_URL + subAdmin.deleteSubAdmin(id));
    },
    edit: async (id: string, data: {}, headers: any) => {

        return axios.put(BASE_URL + subAdmin.editSubAdmin(id), data, { headers: headers });
    },
    getbyid: async (id: string) => {

        return axios.get(BASE_URL + subAdmin.getSubAdmin(id));
    },
    view: async (paramsObj: any) => {

        return axios.get(BASE_URL + subAdmin.viewSubAdmin(), { params: paramsObj });
    },
    changeStatusbyid: async (id: string) => {

        return axios.put(BASE_URL + subAdmin.changeStatus(id));
    },
    resetPassword: async (data: {}, headers: any) => {

        return axios.post(BASE_URL + subAdmin.resetPasswordLink(), data, { headers: headers });
    },
}
export default SubAdminServices;
