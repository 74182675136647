import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from  "../dictionary"
import axios from "axios";
const {award } = Dictionary
const Award ={
    add: async (data : {},headers:any )=>{
      
        return axios.post(BASE_URL + award.addAward(), data,{ headers: headers });
    },
    getPoints: async (data : {},headers:any )=>{
  
        return axios.post(BASE_URL + award.getPoints(),data);
    },
    getPointsToCustomer: async (data : {},headers:any )=>{
 
        return axios.post(BASE_URL + award.getPoints(),data);
    },
    viewCustomer: async (paramsObj:any)=>{
      
        return axios.get(BASE_URL + award.viewCustomerList(),{params : paramsObj} );
    },
    viewCustomerByQr: async (data: {}) => {

        return axios.post(BASE_URL + award.viewCustomerByQr(), data);
    },
    getTransactionHistory: async (id: string,paramsObj:{}) => {

        return axios.get(BASE_URL + award.customer_history(id),{ params: paramsObj });
    },
}
export default Award;
