import { Display_Transaction, Download_Csv } from './actionType'

export const DisplayTransaction = ({ search, page, limit, transaction_type, created_from, created_to }: any) => {
    return {
        type: Display_Transaction,
        search,
        page,
        limit,
        transaction_type,
        created_from,
        created_to
    }
}
export const DownloadCsv = ({ created_from, created_to,transaction_type }: any) => {
    return {
        type: Download_Csv,
        created_from,
        created_to,
        transaction_type,
    }
}