import { put, takeLatest, call } from 'redux-saga/effects'
import { Add_SubAdmin, Display_SubAdmin, Edit_SubAdmin, Delete_SubAdmin, ResponseSubAdmin, Get_Edit_Response, getSubadminById, SubAdmin_Status, Reset_SubAdmin_Password } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addSubAdmin(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let payload = {
            email: action.data.email,
            first_name: action.data.firstname,
            last_name: action.data.lastname,
            dob: action.data.date,
            gender: action.data.gender,
            country_code: action.data.countrycode,
            mobile: action.data.mobile
        }
        const response: any = yield service.subAdmin.add(payload, { "Content-type": "application/json" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            action.navigate("/subAdmin")
            yield call(displaySubAdmin);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* editSubAdmin(action: any): Generator<any, AxiosResponse<any>, any> {
    let payload = {
        email: action.data.email,
        first_name: action.data.firstname,
        last_name: action.data.lastname,
        dob: action.data.date,
        gender: action.data.gender,
        country_code: action.data.countrycode,
        mobile: action.data.mobile
    }
    try {
        const response: any = yield service.subAdmin.edit(action.id, payload, { "Content-type": "application/json" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            action.navigate("/subAdmin")
            yield call(displaySubAdmin);

        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* deleteSubAdmin(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.subAdmin.delete(action.id);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(displaySubAdmin);
            // yield put({ type: EditResponse, data });
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* displaySubAdmin(action?:any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.subAdmin.view({search : action?.search,page:action?.page,limit:action?.limit});
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.message);
            yield put({ type: ResponseSubAdmin, data })
        } else {
            toast.error(response.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }


}
function* displaySubAdminById(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.subAdmin.getbyid(action.id);
        const data = yield response.data;

        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: Get_Edit_Response, data });
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* changeStatusById(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.subAdmin.changeStatusbyid(action.id);
        const data = yield response.data;

        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(displaySubAdmin);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message)
        yield call(displaySubAdmin);
        return error
    }

}
function* ResetPassword(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let payLoad = {
            email:action.email
        }
        const response: any = yield service.subAdmin.resetPassword(payLoad, { });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message)
            yield call(displaySubAdmin);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        console.log("error", error)
        toast.error(error?.response?.data?.message)
        return error
    }

}
function* productSaga() {
    yield takeLatest(Add_SubAdmin, addSubAdmin)
    yield takeLatest(Edit_SubAdmin, editSubAdmin)
    yield takeLatest(Delete_SubAdmin, deleteSubAdmin)
    yield takeLatest(Display_SubAdmin, displaySubAdmin)
    yield takeLatest(getSubadminById, displaySubAdminById)
    yield takeLatest(SubAdmin_Status, changeStatusById)
    yield takeLatest(Reset_SubAdmin_Password, ResetPassword)
}

export default productSaga;