import React, { useEffect, useState } from "react";
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Table from '../../components/Common/merchantDataTable';

import { DeleteSubAdmin, DisplaySubAdmin, changeSubAdminStatus, resetSubAdminPassword } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupModel from "../../components/Common/Popup";
import { Tooltip } from "react-tooltip";


const Index = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(2)
  const [statusId, setstatusId] = useState('');
  const [statusPopup, setstatusPopup] = useState(false)
  const [SubAdminData, setSubAdminData] = useState<any>([])
  const [search, setSearch] = useState("")
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { ResponseData } = useSelector((state: any) => ({
    ResponseData: state?.SubAdmin?.Response
    ,
  }));
  const columns: any = [
    {
      name: "S.No.",
      selector: (i: any, index: number) => index + 1,
      maxWidth: "5%",
    },
    {
      name: "Name",
      selector: (i: any) => i.first_name + ' ' + i.last_name,
      minWidth: "15%",
      compact: true,
      sortable: true,
    },
    {
      name: "Email",
      selector: (i: any) => <>
        <span data-tooltip-id={`my-tooltip${i.id}`}
          data-tooltip-content={i?.email}>
          {i?.email}
        </span>
        <Tooltip id={`my-tooltip${i.id}`} />
      </>,
      minWidth: "25%",
    },
    {
      name: "Gender",
      selector: (i: any) => i.gender,
      minWidth: "12%",
    },

    {
      name: "Mobile",
      selector: (i: any) => <>
        <span data-tooltip-id={`my-tooltip${i.id}`}
          data-tooltip-content={"(" + i?.country_code + ")" + i?.mobile}>
          {"(" + i?.country_code + ") " + i?.mobile}
        </span>
        <Tooltip id={`my-tooltip${i.id}`} />
      </>,

      minWidth: "18%",
    },
    {
      name: "Status",
      selector: (i: any) => handleIsStatus(i)?.label,
      conditionalCellStyles: [
        {
          when: (i: any) => handleIsStatus(i)?.label ===
            "Active",
          classNames: ['green'],
        },
        {
          when: (i: any) => handleIsStatus(i)?.label !== "Active",
          classNames: ['red'],
        }
      ],
      minWidth: "10%",
    },
    {
      name: "Action",
      cell: (i: any) =>
        <span className="cursor">
          <UncontrolledDropdown>
            <DropdownToggle className="" id="dropdownMenuClickable" data-bs-toggle="dropdown" direction="left" data-bs-auto-close="false" aria-expanded="false">
              <i className="fas fa-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => { _handlePackageUpdate(i.id) }} >Edit</DropdownItem>
              <DropdownItem
                onClick={() => {
                  setDeleteConfirmation(true)
                  setDeleteId(i.id)
                }}
              >
                Delete
              </DropdownItem>
              <DropdownItem
                onClick={() => { setstatusId(i.id), setstatusPopup(true) }}
              >{i?.status === "1" ? "Inactive" : "Active"}</DropdownItem>
              <DropdownItem onClick={() => {
                dispatch(resetSubAdminPassword(i?.email))
              }}>Resend password link</DropdownItem>

            </DropdownMenu>
          </UncontrolledDropdown>

        </span>,
      minWidth: "10%",
    },
  ]
  useEffect(() => {
    setSubAdminData(ResponseData?.data)
    setTotalCount(ResponseData?.count)
  }, [ResponseData?.data])

  useEffect(() => {
    dispatch(DisplaySubAdmin({ search: '', page: currentPage, limit: limitPerPage }))
  }, [currentPage, limitPerPage])

  const _handlePackageUpdate = (i: string) => {
    navigate(`edit-subAdmin/${i}`)
    // setShowModal(true)
  }
  const isStatusData = [
    {
      options: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ]
    },
  ];
  const handleDelete = () => {
    dispatch(DeleteSubAdmin(deleteId))
  }
  const handleIsStatus = (i: any) => {
    let statusindex = isStatusData[0].options.findIndex(x => x?.value === i?.status);
    return isStatusData[0].options[statusindex]
  }
  const handleSearch = (e: string) => {
    let searchValue: string = e
    setTimeout(() => {
      dispatch(DisplaySubAdmin({ search: searchValue, page: currentPage, limit: limitPerPage }))
    }, 500)
    setSearch(e)
  }
  const handleBranchChange = () => {
    dispatch(changeSubAdminStatus(statusId))
  }
  return (
    <>
      <div className="my-5 py-5">

        <div className="row justify-content-center">
          <div className="col-11 p-0 mb-3 ">
            <div className="d-flex justify-content-between align-items-center">
              <Breadcrumbs breadcrumbItem="Sub Admin" />
              <div className="text-end">
                <Button className="color"
                  onClick={() => {
                    navigate("add-subAdmin")
                  }}>Add Sub Admin</Button>
              </div>
            </div>
          </div>
          <div className="card col-11">

            <div className="row ">
              <div className="col-6">
                <form className="app-search d-none d-lg-block w-50">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => { handleSearch(e.target.value) }}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </div>
            </div>
            <div className="container-fluid">
              <Table
                columns={SubAdminData && columns}
                data={SubAdminData ? SubAdminData : []}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                setLimitPerPage={setLimitPerPage}
              />
            </div>
          </div>
          <div>
            {statusPopup && (
              <PopupModel
                ApiName={handleBranchChange}
                popUpFunction={setstatusPopup}
                title="Confirmation"
                paragraph={`Are you sure you want to change the Status ?`}
              />
            )}
          </div>
          <div>
            {deleteConfirmation && (
              <PopupModel
                ApiName={handleDelete}
                popUpFunction={setDeleteConfirmation}
                title="Confirmation"
                paragraph={`Are you sure you want to delete ?`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
