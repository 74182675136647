import React, { useEffect, useState } from "react";
import {
    Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback,
    CardTitle, Button, Modal, FormGroup
} from "reactstrap";
import Table from '../../components/Common/dataTable';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddAward_Redeem, DisplayAward_Redeem, DeleteAward_Redeem, EditAward_Redeem } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { IAddData } from "./interface";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupModel from "../../components/Common/Popup"
import WarningPopup from "../../components/Common/WarningPopup"
import { countryCodeData } from "../../components/Common/carabianCurrency"
import Constants from "../../constants/Constants";

const Index = () => {
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(2)
    const [award_Redeem_Amount, setAward_Redeem_Amount] = useState('');
    const [countryCode, setCountryCode] = useState<string | any>(null);
    const [award_Redeem_Point, setAward_Redeem_Point] = useState('');
    const [Award_Redeem_Id, setAward_Redeem_Id] = useState('');
    const [Award_RedeemData, setAward_RedeemData] = useState<any>([])
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [transactionType, setTransactionType] = useState<string | any>(null);
    const [countryError, setCountryError] = useState('')
    const [transactionError, setTransactionError] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [Error, setError] = useState<boolean>(false);
    const [warning, setWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState("")
    const [search, setSearch] = useState("")
    const storageData: any = JSON.parse(localStorage.getItem("login_response") || '{}')
    const dispatch = useDispatch();
    const { Award_Redeem_Data, Award_Redeem_Count, Award_Redeem_warning } = useSelector((state: any) => ({
        Award_Redeem_Data: state?.Award_Redeem?.data?.data,
        Award_Redeem_Count: state?.Award_Redeem?.data?.count,
        Award_Redeem_warning: state?.Award_Redeem?.data?.popup,
    }));
    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) => index + 1,
            minWidth: "5%",
        },
        {
            name: "Currency",
            selector: (i: any) => handleCountry(i)?.label, minWidth: "20%", compact: true,
            sortable: true
        },
        {
            name: "Transaction Type", selector: (i: any) => i.transaction_type,
            conditionalCellStyles: [
                {
                    when: (row: any) => row.transaction_type === "AWARD",
                    classNames: ['green'],
                },
                {
                    when: (row: any) => row.transaction_type === "REDEEM",
                    classNames: ['blue'],
                }
            ],
            minWidth: "18%", compact: true, sortable: true,
        },
        { name: "Amount", selector: (i: any) => i.amount || "-", minWidth: "18%" },
        { name: "Points", selector: (i: any) => i?.points, minWidth: "21%" },
        Constants.SCOPE.ADMIN === storageData.scope && (
            {
                name: "Action",
                selector: (i: any) => <span className="cursor" >
                    <i className="mdi mdi-pencil fs-2 m-lg-1" onClick={() => {
                        _handleAward_RedeemUpdate(i)
                        setAward_Redeem_Id(i?.id)
                    }
                    } ></i>
                    <i className="bx bxs-trash fs-2" onClick={() => {
                        setDeleteConfirmation(true)
                        setDeleteId(i?.id)
                        handleReset()
                    }}></i>

                </span>,
                minWidth: "10%",
            })
    ]

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            amount: award_Redeem_Amount,
            points: award_Redeem_Point,
        },
        validationSchema: Yup.object({
            amount: Yup.string().required("Please Enter the Amount"),
            points: Yup.string().required("Please Enter the  Points"),
        }),
        onSubmit: (values, { resetForm }: any) => {
            let countryError = 'Please Select Country Type'
            let transactionError = 'Please Select Transaction Type'
            let temp1 = false
            if (transactionType === null) {
                setTransactionError(transactionError)
                temp1 = true
            } else if (countryCode === null) {
                temp1 = true
                setCountryError(countryError)
            } else {
                setTransactionError('')
                setCountryError('')
            }
            let temp: IAddData = {
                currency: countryCode.value,
                transaction_type: transactionType.value,
                amount: values.amount,
                points: values.points,
            }
            if (Error === false) {
                if (isEdit) {
                    dispatch(EditAward_Redeem(Award_Redeem_Id, temp))
                    setShowModal(false)
                    setIsEdit(false)
                    resetForm({ values: '' })
                    handleReset()
                } else {
                    dispatch(AddAward_Redeem(temp))
                    setShowModal(false)
                    resetForm({ values: '' })
                    handleReset()
                }
            }
        }
    });
    const transaction_type = [
        {
            options: [
                { label: "AWARD", value: "AWARD" },
                { label: "REDEEM", value: "REDEEM" },
            ]
        },
    ];
    function handleTransactionType(e: string) {
        if (e) {
            setTransactionError('')
        }
        setTransactionType(e)
    }
    function handleCountryCode(e: string) {
        if (e) {
            setCountryError('')
        }
        setCountryCode(e)
    }
    useEffect(() => {
        if (Award_Redeem_warning === true) {
            setWarningMessage(Award_Redeem_Data)
            setTotalCount(Award_Redeem_Count)
            setWarning(Award_Redeem_warning)
        } else {
            setAward_RedeemData(Award_Redeem_Data)
            setTotalCount(Award_Redeem_Count)
        }
    }, [Award_Redeem_Data])

    useEffect(() => {
        dispatch(DisplayAward_Redeem({ search: '', page: currentPage, limit: limitPerPage }))
    }, [currentPage, limitPerPage])
    const handleSearch = (e: string) => {
        let searchValue: string = e
        setTimeout(() => {
            dispatch(DisplayAward_Redeem({ search: searchValue, page: currentPage, limit: limitPerPage }))
        }, 500)
        setSearch(e)
    }
    const _handleAward_RedeemUpdate = (i: IAddData) => {
        setIsEdit(true)
        let transactionindex = transaction_type[0].options.findIndex(x => x?.value === i?.transaction_type);
        let countryindex = countryCodeData[0].options.findIndex(x => x?.value === i?.currency);

        setAward_Redeem_Amount(i.amount)
        setCountryCode(countryCodeData[0].options[countryindex])
        setAward_Redeem_Point(i.points)
        setTransactionType(transaction_type[0].options[transactionindex])
        setShowModal(true)
    }
    const handleDelete = () => {
        dispatch(DeleteAward_Redeem(deleteId))
    }

    const handleReset = () => {
        setCountryCode('')
        setTransactionType('')
        setCountryError(" ")
        setTransactionError("")
        validation.resetForm({
            values: {
                amount: '',
                points: '',
            },
        })
    }


    //for change tooltip display propery
    const handleError = () => {
        setCountryError("")
        setTransactionError("")
        setError(false)
        if (countryCode === '') {
            setCountryError("Please select a country")
            setError(true)
        }
        if (transactionType === '') {
            setTransactionError("Please select a Transaction Type")
            setError(true)
        }
    }

    const handleCountry = (i: any) => {
        let countryindex = countryCodeData[0].options.findIndex(x => x?.value === i?.currency);

        return countryCodeData[0].options[countryindex]
    }
    const handleGetData = () => {
        dispatch(DisplayAward_Redeem({ search: '', page: currentPage, limit: limitPerPage }))
    }



    return (
        <React.Fragment>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Award/Redeem Points" />
                            {Constants.SCOPE.ADMIN === storageData.scope ?
                                (<div className="text-end">
                                    <Button className="color"
                                        onClick={() => {
                                            handleReset()
                                            setIsEdit(false)
                                            setShowModal(true)
                                        }}>Add </Button>
                                </div>) : (
                                    <div className="text-end" style={{ height: "34.5px" }}>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="card col-11">
                        <div className="row">
                            <div className="col-6">
                                <form className="app-search d-none d-lg-block w-50">
                                    <div className="position-relative">
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" /> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <Table
                                columns={columns}
                                data={Award_RedeemData ? Award_RedeemData : []}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setLimitPerPage={setLimitPerPage}
                                pagination={false}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <Modal
                        isOpen={showModal}
                        role="dialog"
                        autoFocus={true}
                        centered
                        data-toggle="modal"
                        toggle={() => {
                            setShowModal(!showModal);
                        }}
                    >
                        <div className="modal-body">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col >
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Award/Redeem</CardTitle>
                                                <div className="p-2">


                                                    <Form className="needs-validation"
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                    >
                                                        <Row className="mb-3">
                                                            <Label htmlFor="validationCustom01">
                                                                Currency
                                                            </Label>
                                                            <Col sm={12}>
                                                                <Select
                                                                    name="countrycode"
                                                                    value={countryCode}
                                                                    // onBlur={(e)=>validation.handleBlur(e)}
                                                                    // onChange={validation.handleChange}
                                                                    // onBlur={validation.handleBlur}
                                                                    // value={validation.values.countrycode || ""}
                                                                    // invalid={
                                                                    //   validation.touched.countrycode && validation.errors.countrycode ? true : false
                                                                    // }
                                                                    onChange={(e) => {
                                                                        handleCountryCode(e);
                                                                    }}
                                                                    options={countryCodeData}
                                                                    className="select2-selection"

                                                                />
                                                                {countryCode?.value?.length === undefined ? (
                                                                    <span className="errorColor">{countryError}</span>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Label htmlFor="validationCustom01">
                                                                Transaction Type
                                                            </Label>
                                                            <Col sm={12}>
                                                                <Select
                                                                    name="transactionType "
                                                                    value={transactionType}

                                                                    // onChange={validation.handleChange}
                                                                    // onBlur={(e)=>validation.handleBlur(e)}
                                                                    // value={validation.values.gender || ""}
                                                                    // invalid={
                                                                    //   validation.touched.countrycode && validation.errors.countrycode ? true : false
                                                                    // }
                                                                    onChange={(e) => {
                                                                        handleTransactionType(e)
                                                                    }}
                                                                    options={transaction_type}
                                                                    className="select2-selection"

                                                                />
                                                                {transactionType?.value?.length === undefined ? (
                                                                    <span className="errorColor">{transactionError}</span>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup className="mb-2">
                                                                    <Label htmlFor="validationCustom01">Amount</Label>
                                                                    <Input
                                                                        name="amount"
                                                                        placeholder="Amount"
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="validationCustom01"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.amount || ""}
                                                                        invalid={
                                                                            validation.touched.amount && validation.errors.amount ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.amount && validation.errors.amount ? (
                                                                        <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="validationCustom02">Points</Label>
                                                                    <Input
                                                                        name="points"
                                                                        placeholder="Points"
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="validationCustom02"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.points || ""}
                                                                        invalid={
                                                                            validation.touched.points && validation.errors.points ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.points && validation.errors.points ? (
                                                                        <FormFeedback type="invalid">{validation.errors.points}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <div className="text-center">
                                                            <Button color="primary" type="submit" onClick={() => { handleError() }}>
                                                                {isEdit ? "Update" : 'Add'}
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Modal>
                    <div>
                        {deleteConfirmation && (
                            <PopupModel
                                ApiName={handleDelete}
                                popUpFunction={setDeleteConfirmation}
                                title="Confirmation"
                                paragraph={`Are you sure you want to delete ?`}
                            />
                        )}
                        {warning && (
                            <WarningPopup
                                ApiName={handleGetData}
                                popUpFunction={setWarning}
                                title="Error"
                                paragraph={warningMessage}
                            />
                        )}
                    </div>
                </div>

                {/* </Container> */}
            </div>
        </React.Fragment >
    );
};

export default Index;
