import React from 'react'

const Index = () => {
    return (
        <div className='privacy-div card p-5 mt-2'>
            <h1>Privacy Policy for Cube 3 Rewards Website</h1>
            <p>
                At Cube 3 Rewards, we take your privacy seriously. This Privacy Policy
                explains how we collect, use, and disclose your personal information when
                you visit our website or use our services.
            </p>
            <h2>1. Information We Collect</h2>
            <p>
                We may collect personal information from you when you visit our website,
                sign up for our services, or participate in our loyalty programs. The
                information we collect may include your name, email address, phone number,
                and payment information.
            </p>
            <h2>2. How We Use Your Information</h2>
            <p>
                We use your personal information to provide you with our services, process
                your transactions, and communicate with you. We may also use your
                information to send you promotional offers and marketing materials.
            </p>
            <h2>3. Disclosure of Your Information</h2>
            <p>
                We do not sell, rent, or disclose your personal information to third
                parties, except as necessary to provide you with our services or as required
                by law.
            </p>
            <h2>4. Cookies and Other Tracking Technologies</h2>
            <p>
                We use cookies and other tracking technologies to improve your user
                experience and personalize your interactions with our website.
            </p>
            <h2>5. Security</h2>
            <p>
                We take reasonable measures to protect your personal information from
                unauthorized access, use, and disclosure.
            </p>
            <h2>6. Your Rights</h2>
            <p>
                You have the right to access, correct, and delete your personal information.
                You may also opt-out of receiving promotional offers and marketing
                materials.
            </p>
            <h2>7. Changes to This Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. We will notify you of
                any material changes by posting the updated Privacy Policy on our website.
            </p>
            <p>
                If you have any questions or concerns about our Privacy Policy, please
                contact us at [insert contact information].
            </p>
            <h1>Terms of Service (TOS) for Cube3 Rewards</h1>
            <p>
                These terms of service (the “Agreement”) govern your use of the Cube3
                Rewards website (the “Site”) and the services offered on the Site (the
                “Services”). By accessing or using the Site or Services, you agree to be
                bound by this Agreement.
            </p>
            <h2>1. Use of Site and Services</h2>
            <p>
                Cube3 Rewards provides a loyalty program that allows customers to earn and
                redeem rewards from participating merchants. You may use the Site and
                Services solely for your personal and non-commercial use. You agree not to
                copy, reproduce, modify, create derivative works from, distribute, sell, or
                exploit any portion of the Site or Services without the prior written
                consent of Cube3 Rewards.
            </p>
            <h2>2. User Accounts</h2>
            <p>
                To access certain features of the Site and Services, you may be required to
                create a user account. You agree to provide accurate, current, and complete
                information when creating your account, and to promptly update your account
                information as necessary. You are responsible for maintaining the
                confidentiality of your account login credentials and for all activities
                that occur under your account. Cube3 Rewards reserves the right to suspend
                or terminate your account at any time and for any reason, without notice or
                liability.
            </p>
            <h2>3. User Content</h2>
            <p>
                You retain all rights in any content that you submit to the Site or Services
                (“User Content”). By submitting User Content, you grant Cube3 Rewards a
                non-exclusive, worldwide, royalty-free, fully paid-up, perpetual,
                irrevocable, and sublicensable license to use, copy, modify, create
                derivative works based on, distribute, publicly display, publicly perform,
                and otherwise exploit in any manner such User Content in all formats and
                distribution channels now known or hereafter devised (including in
                connection with the Site and Services and Cube3 Rewards’s business and on
                third-party sites and services), without further notice to or consent from
                you, and without the requirement of payment to you or any other person or
                entity.
            </p>
            <h2>4. Prohibited Conduct</h2>
            <p>You agree not to use the Site or Services to:</p>
            <ul>
                <li>violate any applicable laws or regulations;</li>
                <li>
                    infringe the intellectual property or other rights of Cube3 Rewards or any
                    third party;
                </li>
                <li>distribute viruses or other harmful computer code;</li>
                <li>
                    interfere with or disrupt the Site or Services, or servers or networks
                    connected to the Site or Services; or
                </li>
                <li>
                    engage in any other conduct that is harmful to Cube3 Rewards, the Site,
                    Services, or any third party.
                </li>
            </ul>
            <p />
            <h2>5. Disclaimer of Warranties</h2>
            <p>
                THE SITE AND SERVICES ARE PROVIDED “AS IS” AND WITHOUT WARRANTY OF ANY KIND.
                CUBE3 REWARDS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
                WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NON-INFRINGEMENT. CUBE3 REWARDS DOES NOT WARRANT
                THAT THE SITE OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS
                WILL BE CORRECTED, OR THAT THE SITE OR SERVICES ARE FREE OF VIRUSES OR OTHER
                HARMFUL COMPONENTS.
            </p>
            <h2>6. Limitation of Liability</h2>
            <p>
                IN NO EVENT SHALL CUBE3 REWARDS BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION
                WITH YOUR USE OF THE SITE OR SERVICES, WHETHER BASED ON WARRANTY, CONTRACT,
                TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT CUBE3 REWARDS HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. CUBE3 REWARDS’S LIABILITY SHALL
                BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
            </p>
            <h2>Indemnification</h2>
            <p>
                You agree to indemnify and hold Cube3 Rewards and its affiliates, officers,
                agents, employees, and partners harmless from and against any claim, demand,
                or liability, including reasonable attorneys’ fees, arising out of or in
                connection with your use of the Site or Services.
            </p>
        </div>

    )
}

export default Index