import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Form, FormFeedback, Input, Label, Modal, ModalHeader, NavItem, NavLink, Row, TabContent, TabPane, } from "reactstrap"
import GoogleComponent from 'react-google-autocomplete';
import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select";
import { countryCodeData } from "../../components/Common/carabianCountry"
import { IMerchant, IMerchantAddData, IMerchantData, IMerchantListData } from "./interface";
import { GetCategoryList, GetPackageList, AddMerchant, GetMerchantByID, EditMerchant, ChangeMerchantEmail } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";

const MerchantAdd = () => {

  const [activeTab, setActiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [gender, setGender] = useState<string | any>(null);
  const [countryCode, setCountryCode] = useState<string | any>(null);
  const [branchCountryCode, setBranchCountryCode] = useState<string | any>(null);
  const [isTeller, setIsTeller] = useState<string | any>(null);
  const [isStatus, setIsStatus] = useState<string | any>(null);
  const [getPackage, setPackage] = useState<string | any>(null);
  const [getCategory, setCategory] = useState<string | any>(null);
  const [categoryList, setCategoryList] = useState<string | any>([]);
  const [packageList, setPackageList] = useState<string | any>([]);
  const [brandName, setBrandName] = useState<string>('');
  const [branchName, setBranchName] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [dob, setDob] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [branchAddress, setBranchAddress] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [brandLogo, setBrandLogo] = useState<string | any>()
  const [brandLogoName, setBrandLogoName] = useState<string | any>()
  const [genderError, setGenderError] = useState<string>('');
  const [countryCodeError, setCountryCodeError] = useState<string>('');
  const [branchCountryCodeError, setBranchCountryCodeError] = useState<string>('');
  const [isTellerError, setIsTellerError] = useState<string>('');
  const [isStatusError, setIsStatusError] = useState<string>('');
  const [getCategoryError, setCategoryError] = useState<string | any>('');
  const [getPackageError, setPackageError] = useState<string | any>('');
  const [brandNameError, setBrandNameError] = useState<string>('');
  const [branchNameError, setBranchNameError] = useState<string>('')
  const [firstNameError, setFirstNameError] = useState<string>('')
  const [lastNameError, setLastNameError] = useState<string>('')
  const [latitude, setLatitude] = useState<string>('')
  const [longitude, setLongitude] = useState<string>('')
  const [dobError, setDobError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [branchAddressError, setBranchAddressError] = useState<string>('')
  const [mobileError, setMobileError] = useState<string>('')
  const [branchId, setBranchId] = useState<string>('')
  const [brandLogoError, setBrandLogoError] = useState<string | any>()
  const merchantId: string | any = useParams()
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate()
  const blockInvalidChar = (e: any) => ['e', 'E', '+', '-', '*', '/'].includes(e.key) && e.preventDefault();
  const hiddenFileInput: any = React.useRef(null);
  const initialValues: IMerchantAddData = {
    category_id: getCategory?.value,
    package_id: getPackage?.value,
    brand_name: brandName,
    status: isStatus?.value,
    isTeller: isTeller?.value,
    branch_name: branchName,
    branch_address: branchAddress,
    country_code: countryCode?.value,
    state: '',
    city: '',
    email: email,
    first_name: firstName,
    last_name: lastName,
    dob: dob,
    gender: gender?.value,
    branch_country_code: branchCountryCode?.value,
    mobile: mobile,
    brand_logo: brandLogo,
    lat: latitude,
    long: longitude,
    branch_id: branchId
  }
  const dispatch = useDispatch()
  const { EditData, categoryDataList, packageDataList } = useSelector((state: any) => ({
    EditData: state?.Merchant?.merchantEditResponse,
    categoryDataList: state?.Merchant?.CategoryData?.data,
    packageDataList: state?.Merchant?.PackageData?.data,
  }));
  function toggleTab(tab: any) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handlePlaceSelected = (place: any) => {
    const { lat, lng } = place?.geometry?.location;
    setBranchAddressError("")
    setBranchAddress(place?.formatted_address)
    setLatitude(lat())
    setLongitude(lng())
  }

  const handleEmailUpdate = () => {
    // setEmailMerchantId(i.id)
    setShowModal(true)
  }
  const genderData = [
    {
      options: [
        { label: "Male", value: "MALE" },
        { label: "Female", value: "FEMALE" },
      ]
    },
  ];
  const isTellerData = [
    {
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ]
    },
  ];
  const isStatusData = [
    {
      options: [
        { label: "Active", value: "1" },
        { label: "Deactive", value: "2" },
      ]
    },
  ];
  function handleCategory(e: IMerchant) {
    setCategory(e)
    setCategoryError("")
  }
  function handlePackage(e: IMerchant) {
    setPackage(e)
    setPackageError("")
  }
  function handleStatus(e: IMerchant) {
    setIsStatus(e)
    setIsStatusError("")
  }
  function handleGender(e: IMerchant) {
    setGender(e)
    setGenderError("")
  }
  function handleIsTeller(e: IMerchant) {
    setIsTeller(e)
    setIsTellerError("")
  }
  function handleCountryCode(e: {}) {
    setCountryCode(e)
    setCountryCodeError('')
  }
  const handleMobile = (e: any) => {
    const limit = 10;
    if (e?.length < 10) {
      setMobileError("please fill 10 digits")
      // setMobile(e)
      setMobile(e.slice(0, limit));
    } else {
      setMobile(e.slice(0, limit));
      // setMobile(e)
    }
  }
  const handleBranchCode = (e: {}) => {
    setBranchCountryCode(e)
    setBranchCountryCodeError("")
  }
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  }
  const handleChange = (e: any) => {
    setBrandLogoName(e.target.files[0].name)
    handleImageUpload(e.target.files[0])
    setBrandLogoError("")
  }
  const handleDateChange = (event: any) => {
    const value = event.target.value;
    setDob(value);
    if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
      setDobError('Please enter a valid date in the format YYYY-MM-DD');
    } else {
      setDobError('');
    }
  };
  useEffect(() => {
    dispatch(GetPackageList())
    dispatch(GetCategoryList())
  }, [])
  useEffect(() => {
    let temp4: IMerchantData | any = []
    let temp3: IMerchantData | any = []
    let temp: IMerchantData | any = []
    let tempTwo: IMerchantData | any = []
    categoryDataList?.map((item: IMerchantListData) => {
      temp.push({
        label: item.name,
        value: item.id
      })
    })
    packageDataList?.map((item: IMerchantListData) => {
      tempTwo.push({
        label: item.name,
        value: item.id
      })
    })
    temp3.push({
      options: temp
    })
    temp4.push({
      options: tempTwo
    })
    setCategoryList(temp3)

    setPackageList(temp4)
    if (isEdit && Object.keys(EditData).length > 0) {
      handleIsEdit(EditData)
    }
  }, [categoryDataList, packageDataList, EditData])

  const handleSubmit = async () => {
    if (!handleNextBtn() && !handleValidation()) {
      if (!isEdit) {
        dispatch(AddMerchant(initialValues, navigate))
      } else {
        dispatch(EditMerchant(merchantId.id, initialValues, navigate))

      }
    }
  }
  function validateEmail(email: any) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  const handleNextBtn = () => {
    let temp = false
    setFirstNameError("")
    setLastNameError("")
    setCountryCodeError("")
    setEmailError("")
    setGenderError("")
    setDobError("")
    setMobileError("")
    if (initialValues?.first_name?.length === 0) {
      setFirstNameError("please enter First Name")
      temp = true
    } if (initialValues?.last_name?.length === 0) {
      setLastNameError("please enter last name")
      temp = true
    } if (countryCode?.value?.length === undefined) {
      setCountryCodeError("please select country code")
      temp = true
    } if (initialValues?.dob?.length === 0) {
      setDobError("please enter DOB")
      temp = true
    } if (initialValues?.email?.length === 0) {
      setEmailError("please enter Email")
      temp = true
    } else if (!validateEmail(initialValues.email)) {
      setEmailError("Please enter a valid email.");
      temp = true;
    }
    if (gender?.value?.length === undefined) {
      setGenderError("please enter Gender")
      temp = true
    } if (initialValues?.mobile?.length === 0) {
      setMobileError("please enter mobile number")
      temp = true
    }
    return temp
  }
  const handleValidation = () => {
    let temp = false
    setBranchAddressError("")
    setBranchCountryCodeError("")
    setBranchNameError("")
    setBrandLogoError("")
    setBrandNameError("")
    setCategoryError("")
    setIsTellerError("")
    setIsStatusError("")
    setPackageError("")
    if (initialValues?.branch_address?.length === 0) {
      setBranchAddressError("please enter branch address")
      temp = true
    } if (branchCountryCode?.value?.length === undefined) {
      setBranchCountryCodeError("please enter branch country code")
      temp = true
    } if (initialValues?.branch_name?.length === 0) {
      setBranchNameError("please enter branch name")
      temp = true
    } if (!brandLogo) {
      setBrandLogoError("please upload brand logo")
      temp = true
    } if (initialValues?.brand_name?.length === 0) {
      setBrandNameError("please enter brand name")
      temp = true
    } if (getCategory?.value?.length === undefined) {
      setCategoryError("please select category")
      temp = true
    } if (isTeller?.value === undefined) {
      setIsTellerError("please select isTeller")
      temp = true
    } if (isEdit && (isStatus?.value?.length === undefined)) {
      setIsStatusError("please select merchant status")
      temp = true
    }
    if (getPackage?.value?.length === undefined) {
      setPackageError("please enter package")
      temp = true
    }
    return temp
  }

  const handleImageUpload = (event: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(event);
    reader.onload = () => {
      setBrandLogo(reader.result);
    };
  };

  useEffect(() => {
    if (merchantId.id) {
      setIsEdit(true)
      dispatch(GetMerchantByID(merchantId.id))
    }
  }, [])


  const handleIsEdit = (e: any) => {
    let genderIndex = genderData[0]?.options?.findIndex(x => x?.value === e?.owner?.gender);
    let countryIndex = countryCodeData[0]?.options?.findIndex(x => x?.value === e?.owner?.country_code);
    let branchCountryIndex = countryCodeData[0].options.findIndex(x => x.value === e?.branches[0]?.branch_country_code);
    let tellerIndex = isTellerData[0]?.options?.findIndex((x) => x?.value === e?.isTeller);
    let statusIndex = isStatusData[0]?.options?.findIndex((x) => x?.value === e?.status);
    let packageIndex = packageList[0]?.options?.findIndex((x: { value: string; }) => x?.value === e?.package_id);
    let categoryIndex = categoryList[0]?.options?.findIndex((x: { value: any; }) => x?.value === e?.category_id);
    let tempLogo = e?.brand_logo?.split("/")
    setBranchAddress(e.branches[0].address)
    setBranchCountryCode(countryCodeData[0].options[branchCountryIndex])
    setBranchName(e.branches[0].name)
    setBranchId(e.branches[0].id)
    setBrandLogo(e.brand_logo)
    setLatitude(e.branches[0].lat)
    setLongitude(e.branches[0].long)
    setBrandLogoName(tempLogo[tempLogo.length - 1])
    setBrandName(e.brand_name)
    setCategory(categoryList[0]?.options[categoryIndex])
    //   setCountryCode("")
    setDob(e.staffs[0].dob)
    setEmail(e?.owner?.email)
    setIsRegistered(e?.owner?.isRegistered);
    setFirstName(e?.owner?.first_name)
    setGender(genderData[0]?.options[genderIndex])
    setIsTeller(isTellerData[0]?.options[tellerIndex])
    setLastName(e?.owner?.last_name)
    setIsStatus(isStatusData[0]?.options[statusIndex])
    setMobile(e?.owner?.mobile)
    setPackage(packageList[0]?.options[packageIndex])
    setGender(genderData[0].options[genderIndex])
    setCountryCode(countryCodeData[0].options[countryIndex])


  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',

    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Email"),

    }),
    onSubmit: (values) => {
      let payload = {
        email: values?.email,
        userId: EditData?.user_id
      }
      setShowModal(false);
      dispatch(ChangeMerchantEmail(payload, merchantId?.id))
    }
  });

  return (
    <React.Fragment>
      <div className="row page-content justify-content-center mx-2 mt-2">
        <div className="col-lg-12 ">
          <div className="mb-3">
            <Breadcrumbs breadcrumbItem={`${merchantId?.id?.length > 0 ? "Update Merchant" : "Add Merchant"}`} isBack={true} />
          </div>

          <Card>
            <CardBody>
              {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={() => {
                          setActiveTab(1)
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">1.</span> Merchant Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          setActiveTab(2)
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">2.</span> Brand Details
                      </NavLink>
                    </NavItem>

                  </ul>
                </div>
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <Form>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                First name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Your First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onKeyPress={(e) => setFirstNameError("")}
                              />
                              <p className="errorColor">{firstNameError}</p>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="">
                              <Label for="basicpill-lastname-input2">
                                Last name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input2"
                                placeholder="Enter Your Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onKeyPress={(e) => setLastNameError("")}
                              />
                            </div>
                            <p className="errorColor">{lastNameError}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-email-input4">
                                Country
                              </Label>
                              <Select
                                name="countrycode"
                                value={countryCode}
                                onChange={(e) => {
                                  handleCountryCode(e)
                                }}
                                options={countryCodeData}
                                className="select2-selection"

                              />
                              <p className="errorColor">{countryCodeError}</p>
                            </div>
                          </Col>

                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-email-input4">
                                Email
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="basicpill-email-input4"
                                  placeholder="Enter Your Email ID"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  onKeyPress={(e) => setEmailError("")}
                                  disabled={isRegistered}
                                // style={{ borderRightStyle: "hidden" }}
                                />
                                <button
                                  //  onClick={() => setPasswordShow(!passwordShow)} 
                                  className="btn btn-light bg-transparent" style={{ borderColor: "#e0e0e0", borderLeftStyle: "hidden" }} type="button" id="password-addon">
                                  <i className="mdi mdi-pencil" onClick={() => {
                                    handleEmailUpdate()
                                  }
                                  } >
                                  </i>
                                </button>
                              </div>
                              <div>
                                <p className="errorColor">{emailError}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-phoneno-input3">
                                Gender
                              </Label>
                              <Select
                                name="gender "
                                value={gender}
                                onChange={(e) => {
                                  handleGender(e)
                                }}
                                options={genderData}
                                className="select2-selection"

                              />
                              <p className="errorColor">{genderError}</p>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-phoneno-input3">
                                DOB
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="basicpill-phoneno-input3"
                                placeholder="Enter Your Phone No."
                                value={dob}
                                onChange={(e) => handleDateChange(e)}
                                onKeyPress={(e) => setDobError("")}
                              />
                              <p className="errorColor">{dobError}</p>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-email-input4">
                                Mobile
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-mobile-input4"
                                maxLength={10}
                                placeholder="Enter Your Mobile Number"
                                value={mobile}
                                onChange={(e) => handleMobile(e.target.value)}
                                onKeyPress={(e) => { setMobileError("") }}
                                onKeyDown={blockInvalidChar}
                              />
                              <p className="errorColor">{mobileError}</p>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-pancard-input5">
                                  Category
                                </Label>
                                <Select
                                  name="categoryList"
                                  value={getCategory}
                                  onChange={(e) => {
                                    handleCategory(e)
                                  }}
                                  options={categoryList}
                                  className="select2-selection"

                                />
                                <p className="errorColor">{getCategoryError}</p>
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-vatno-input6">
                                  Package
                                </Label>
                                <Select
                                  name="packagelist"
                                  value={getPackage}
                                  onChange={(e) => {
                                    handlePackage(e)
                                  }}
                                  options={packageList}
                                  className="select2-selection"

                                />
                                <p className="errorColor">{getPackageError}</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-cstno-input7">
                                  Brand Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cstno-input7"
                                  placeholder="Enter Your Brand Name"
                                  value={brandName}
                                  onChange={(e) => setBrandName(e.target.value)}
                                  onKeyPress={(e) => { setBrandNameError("") }}
                                />
                                <p className="errorColor">{brandNameError}</p>
                              </div>
                            </Col>

                            <Col className="" lg="6">
                              <Label for="basicpill-cstno-input7">
                                Upload Logo
                              </Label>
                              <div className=" px-2">
                                <Input className="form-control" type="file" id="formFile"
                                  onChange={handleChange}
                                  accept="image/png, image/gif, image/jpeg"
                                  placeholder="Please Upload Logo" />
                              </div>
                              <p className="mb-0 errorColor">{brandLogoError}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-cardno-input12">
                                  Branch Code
                                </Label>
                                <Select
                                  name="branch Code"
                                  value={branchCountryCode}
                                  onChange={(e) => {
                                    handleBranchCode(e)
                                  }}
                                  options={countryCodeData}
                                  className="select2-selection"

                                />
                                <p className="errorColor">{branchCountryCodeError}</p>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-declaration-input10">
                                  IsTeller
                                </Label>
                                <Select
                                  name="teller"
                                  value={isTeller}
                                  onChange={(e) => {
                                    handleIsTeller(e)
                                  }}
                                  options={isTellerData}
                                  className="select2-selection"

                                />
                                <p className="errorColor">{isTellerError}</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-namecard-input11">
                                  Branch Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-namecard-input11"
                                  placeholder="Enter Your Branch Name "
                                  onChange={(e) => setBranchName(e.target.value)}
                                  value={branchName}
                                  onKeyPress={(e) => setBranchNameError("")}
                                />
                                <p className="errorColor">{branchNameError}</p>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-namecard-input11">
                                  Address
                                </Label>
                                <GoogleComponent
                                  className="form-control"
                                  placeholder="Enter Your Branch Address"
                                  apiKey="AIzaSyBN9LK8B_n_X1LTvi7jhqU9fzyXCQUOuqk"
                                  options={{
                                    types: ["geocode", "establishment"]
                                  }}
                                  onPlaceSelected={(place: any) =>
                                    handlePlaceSelected(place)
                                  }
                                  defaultValue={branchAddress}
                                />
                                <p className="errorColor">{branchAddressError}</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            {isEdit && (
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-companyuin-input9">
                                    Status
                                  </Label>
                                  <Select
                                    name="status"
                                    value={isStatus}
                                    onChange={(e) => {
                                      handleStatus(e)
                                    }}
                                    options={isStatusData}
                                    className="select2-selection"

                                  />
                                  <p className="errorColor">{isStatusError}</p>
                                </div>
                              </Col>
                            )}

                          </Row>
                        </Form>
                      </div>
                    </TabPane>

                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li
                    // className={activeTab === 2 ? "next disabled" : "next"}
                    >
                      {activeTab == 1 && <Link
                        to="#"
                        onClick={() => {
                          handleNextBtn()
                          if (!handleNextBtn()) {
                            toggleTab(activeTab + 1)
                          }
                        }}
                      >
                        Next
                      </Link>}
                      {activeTab == 2 && <Link
                        to="#"
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        Submit
                      </Link>}
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>

        </div>
        <div>
          <Modal
            isOpen={showModal}
            role="dialog"
            autoFocus={true}
            centered
            data-toggle="modal"
            toggle={() => {
              setShowModal(!showModal);
            }}
            style={{ width: "350px" }}
          >
            <ModalHeader className="justify-content-center">
              <div>
                Change email
              </div>
            </ModalHeader>
            <div className="modal-body mx-3">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Enter New Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter new email"
                    type="email"
                    onChange={(e) => { validation.handleChange(e) }}
                    onBlur={validation.handleBlur}
                    values={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null}
                </div>
                <div className="mt-3 text-center  ">
                  <button
                    className="btn btn-primary btn-block"
                    type="submit"
                  // onClick={()=>handleClick(validation.values)}
                  >
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </Modal >
        </div>
      </div>
    </React.Fragment>
  )
}

export default MerchantAdd
