import { Display_Load_Money, Add_Load_Money,Response_Load_Money } from './actionType'

const initialState = {
    Load_MoneyData: []
}
const Load_Money = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case Response_Load_Money:
            return {
                ...state,
                Load_MoneyData:action.data
            }
        default:
            return state;
    }
}

export default Load_Money