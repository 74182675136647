const Merchant  = {
    addMerchant:  () => 'admin/merchants',
    editMerchant:  (id:string) => `admin/merchants/${id}`,
    deleteMerchant:  (id:string) => `admin/merchants/${id}`,
    viewMerchant:  () => 'admin/merchants',
    getMerchant:  (id:string) => `admin/merchants/${id}`,
    viewCategory:  () => 'admin/categories',
    viewPackage:  () => 'admin/packages',
    changeStatus:  (id:string) => `admin/merchants/status/${id}`,
    getBranches:  (id:string) => `admin/branches/${id}`,
    resentPasswordLink:  (id:string) => `reset-password-link/resend`,
    changeEmail:  (id:string) => `merchant/email`,
    getMerchantDetails:  (id:string) => `admin/merchants/${id}`,

}
export default Merchant