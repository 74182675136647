import { Get_Images_Response } from './actionType'

const initialState = {
    images: []
}
const Images = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case Get_Images_Response:
            return {
                ...state,
                images: action.data,
            };
        default:
            return state;
    }
}

export default Images