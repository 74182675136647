import { ResponseTransaction } from './actionType'

const initialState = {
    TransactionResponse: []
}

 const TransactionHistory = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case ResponseTransaction:
            return {
                ...state,
                TransactionResponse: action.data
            }
        default:
            return state;
    }
}
export default TransactionHistory