import React, { useEffect, useState } from "react";
import Table from '../../components/Common/dataTable';
import { UserDisplayQuery } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
const Index = () => {
    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(2)
    const [QueryData, setQueryData] = useState<any>([])
    const dispatch = useDispatch();
    const { QueryResponse } = useSelector((state: any) => ({
        QueryResponse: state?.UserQuery?.UserQueryResponse,
    }));
    useEffect(() => {
        setQueryData(QueryResponse?.data)
        setTotalCount(QueryResponse?.count)
    }, [QueryResponse?.data])

    useEffect(() => {
        dispatch(UserDisplayQuery({ search: '', page: currentPage, limit: limitPerPage }))
    }, [currentPage, limitPerPage])
    const handleSearch = (e: string) => {
        let searchValue: string = e
        setTimeout(() => {
            dispatch(UserDisplayQuery({ search: searchValue, page: currentPage, limit: limitPerPage }))
        }, 500)
        setSearch(e)
    }
    return (
        <>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 mb-3 ">
                        <div className="d-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Customer Queries" />
                            <div className="text-end" style={{ height: "34.5px" }}>
                            </div>
                        </div>
                    </div>
                    <div className="card col-11">
                        <div className="row ">
                            <div className="col-6">
                                <form className="app-search d-none d-lg-block w-50">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" />
                                    </div>
                                </form>
                            </div>
                            <div className="col-4">
                            </div>
                        </div>
                        <div className="container-fluid">
                            {QueryData?.map((data: any, index: number) =>
                                <div className=" notification-box justify-content-between " key={index + 1}>
                                    <div className='align-items-center d-flex row'>
                                        <div className="col-4">
                                            <p className="font-weight-bold mb-1 text-muted">Name</p>
                                            <p className="font-weight-medium ">{data?.customer?.first_name + " " + data?.customer?.last_name}</p>
                                        </div>
                                        <div className="col-3">
                                            <p className="font-weight-bold mb-1 text-muted">Brand Name</p>
                                            <p className="font-weight-medium ">{data?.customer?.merchant?.brand_name}</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="font-weight-bold mb-1 text-muted">Email</p>
                                            <p className="font-weight-medium ">{data?.customer?.email}</p>
                                        </div>
                                        {data.isNew && <div className='notification-active'></div>}
                                    </div>
                                    <div className="d-flex row">
                                        <div className="col-4">
                                            <p className="font-weight-bold mb-1 text-muted">Subject&nbsp;</p>
                                            <p className="mb-0 ml-2">{data?.subject}</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="font-weight-bold mb-1 text-muted">Date&nbsp;</p>
                                            <p className="mb-0 ml-2">{moment(data?.createdAt).format("DD/MM/YYYY")}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <p className="font-weight-bold mb-1 text-muted">Message&nbsp;</p>
                                        <p className=" mb-0 ml-2">{data?.description}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        {QueryData?.length === 0 &&
                            <div>
                                <h3 className="text-center">No Data Found</h3>
                            </div>}
                    </div>

                </div>
            </div>
        </>
    );
};

export default Index;
