import { Get_Logout_Response, Get_Login_Response, Get_Profile_Response, Get_logoType } from './actionType'
export const DashboardData = (data = [], action: { type: any; data: any }) => {

    switch (action.type) {
        case Get_Login_Response:
            return action.data
        default:
            return data;
    }
}
export const ProfileData = (data = [], action: { type: any; data: any }) => {
    switch (action.type) {
        case Get_Profile_Response:
            return action.data
        case Get_Login_Response:
            return action.data
        default:
            return data;
    }
}
