import { ResponseSubAdmin, Get_Edit_Response } from './actionType'

const initialState = {
    Response: [],
    EditResponse: ''
}

 const SubAdmin = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case ResponseSubAdmin:
            return {
                ...state,
                Response: action.data
            }
        case Get_Edit_Response:
            return {
                ...state,
                EditResponse: action.data.data
            }
        default:
            return state;
    }
}
export default SubAdmin