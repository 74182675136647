import PropTypes from 'prop-types';
import React from "react";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
import { Navigate } from "react-router-dom";
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout"
import Interceptor from './api/interceptor';
import "./assets/scss/theme.scss";
import "./assets/scss/style.scss";
import "react-datepicker/dist/react-datepicker.css";

import Login from "./pages/Authentication/Login";
import Register from "./pages/Authentication/Register";
import Category from "./pages/Category";
import MaterialTable from "./pages/Category/Table";
import Merchant from "./pages/Merchant"
import AddMerchant from "./pages/Merchant/AddMerchant"
import Packages from "./pages/Package";
import Subadmin from "./pages/SubAdmin"
import AddSubAdmin from "./pages/SubAdmin/addSubAdmin"
import Dashboard from "./pages/Dashboard/index";
import ChangePassword from './pages/Authentication/ChangePassword';
import Profile from "./pages/Authentication/Profile"
import Award_Redeem from './pages/award-redeem/index';
import Transaction from './pages/Transaction-History/index';
import Award from './pages/award/index';
import MerchantQuery from './pages/MerchantQuery/index';
import UserQuery from './pages/UserQuery/index';
import Branches from './pages/Merchant/Branches'
import ManageCustomer from './pages/ManageCustomer/index'
import AddCustomer from './pages/ManageCustomer/AddCustomer'
import LoadMoney from "./pages/LoadMoney/index"
import MerchantDetail from "./pages/Merchant/MerchantDetail"
import ManageCustomerDetail from "./pages/ManageCustomer/CustomerDetail"
import ResetPassword from "./pages/Authentication/ResetPassword"
import LoadMoneyHistory from "./pages/LoadMoneyHistory/index"
import UploadImages from "./pages/uploadImages"
import Privacy_Policy from "./pages/Privacy_Policy"
import Error404 from "./pages/404"
const getLayout = (layoutType: any) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const Layout = getLayout("vertical")

  return (

    <React.Fragment>
      <Interceptor />
      <Routes >
        <Route path="/">
          <Route path="" element={<Authmiddleware><Navigate to="dashboard" /></Authmiddleware>}></Route>
          <Route path="dashboard" element={<Authmiddleware><Layout><Dashboard /></Layout></Authmiddleware>}></Route>
          <Route path="category" element={<Authmiddleware><Layout><Category /></Layout></Authmiddleware>}></Route>
          <Route path="register" element={<Authmiddleware><Layout><Register /></Layout></Authmiddleware>}></Route>
          <Route path="load-money-history" element={<Authmiddleware><Layout><LoadMoneyHistory /></Layout></Authmiddleware>}></Route>
          <Route path="merchant" >
            <Route path="" element={<Authmiddleware><Layout><Merchant /></Layout></Authmiddleware>}></Route>
            <Route path="addmerchant" element={<Authmiddleware><Layout><AddMerchant /></Layout></Authmiddleware>}></Route>
            {/* <Route path="addmerchant/:id" element={<Authmiddleware><Layout><AddMerchant /></Layout></Authmiddleware>}></Route> */}
            <Route path="edit-merchant/:id" element={<Authmiddleware><Layout><AddMerchant /></Layout></Authmiddleware>}></Route>
            <Route path="branches/:id" element={<Authmiddleware><Layout><Branches /></Layout></Authmiddleware>}></Route>
            <Route path="merchant-details/:id" element={<Authmiddleware><Layout><MerchantDetail /></Layout></Authmiddleware>}></Route>
          </Route>
          <Route path="packages" element={<Authmiddleware><Layout><Packages /></Layout></Authmiddleware>}></Route>
          <Route path="upload-images" element={<Authmiddleware><Layout><UploadImages /></Layout></Authmiddleware>}></Route>
          <Route path="award-redeem" element={<Authmiddleware><Layout><Award_Redeem /></Layout></Authmiddleware>}></Route>
          <Route path="load-money" element={<Authmiddleware><Layout><LoadMoney /></Layout></Authmiddleware>}></Route>
          <Route path="award" element={<Authmiddleware><Layout><Award /></Layout></Authmiddleware>}></Route>
          <Route path="transaction" element={<Authmiddleware><Layout><Transaction /></Layout></Authmiddleware>}></Route>
          <Route path="changepassword" element={<Authmiddleware><Layout><ChangePassword /></Layout></Authmiddleware>}></Route>
          <Route path="merchant-query" element={<Authmiddleware><Layout><MerchantQuery /></Layout></Authmiddleware>}></Route>
          <Route path="user-query" element={<Authmiddleware><Layout><UserQuery /></Layout></Authmiddleware>}></Route>
          <Route path="subAdmin">
            <Route path="" element={<Authmiddleware><Layout><Subadmin /></Layout></Authmiddleware>}></Route>
            <Route path="add-subAdmin" element={<Authmiddleware><Layout><AddSubAdmin /></Layout></Authmiddleware>}></Route>
            <Route path="edit-subAdmin/:id" element={<Authmiddleware><Layout><AddSubAdmin /></Layout></Authmiddleware>}></Route>
          </Route>
          <Route path="manage-customer">
            <Route path="" element={<Authmiddleware><Layout><ManageCustomer /></Layout></Authmiddleware>}></Route>
            <Route path="addCustomer" element={<Authmiddleware><Layout><AddCustomer /></Layout></Authmiddleware>}></Route>
            <Route path="customer-detail/:id" element={<Authmiddleware><Layout><ManageCustomerDetail /></Layout></Authmiddleware>}></Route>
          </Route>
          <Route path="profile" element={<Authmiddleware><Layout><Profile /></Layout></Authmiddleware>}></Route>
          <Route path="table" element={<NonAuthLayout><MaterialTable /></NonAuthLayout>}></Route>
          <Route path="/login" element={<NonAuthLayout><Login /></NonAuthLayout>}></Route>
          <Route path="/privacy-policy" element={<NonAuthLayout><Privacy_Policy /></NonAuthLayout>}></Route>
          <Route path="reset-password" element={<NonAuthLayout><ResetPassword /></NonAuthLayout>}></Route>
          <Route path='error' element={<Error404 />} />
          <Route path='*' element={<Navigate to='/error' />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;