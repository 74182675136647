import React from 'react';
import DataTable from 'react-data-table-component';


function MyComponent(props: any) {

    return (
        <div className='table-container'>
            {props?.data?.length !== 0 ? <DataTable
                columns={props?.columns}
                data={props?.data}
                pagination
                noDataComponent="No Data Available"
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                highlightOnHover
                paginationServer
                paginationTotalRows={props.totalCount}
                onChangeRowsPerPage={(limit) => props.setLimitPerPage(limit)}
                onChangePage={(page) => props.setCurrentPage(page)}

            /> : <div className='text-center m-4'>
                <h3>No data available</h3>
            </div>}
        </div>
    );
};

export default MyComponent;