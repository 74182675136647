import { put, takeLatest, call } from 'redux-saga/effects'
import { Add_Category, Display_Category, Edit_Category, Delete_Category, ResponseCategory } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addCategory(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const formdata: any = new FormData();
        formdata.append("name", action.data.name)
        formdata.append("image", action.data.image)
        const response: any = yield service.category.add(formdata, { "Content-type": "multipart/form-data" });
        if (response.status === 200) {
            toast.success(response.data.message);
            yield call(displayCategory)
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* editCategory(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const formdata: any = new FormData();
        formdata.append("name", action.data.name)
        formdata.append("image", action.data.image)
        const response: any = yield service.category.edit(action.id, formdata, { "Content-type": "multipart/form-data" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message)
            // yield put({ type: EditResponse, data });
            yield call(displayCategory)

        } else {
            toast.error(response.message)
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* deleteCategory(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.category.delete(action.id);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message)
            yield call(displayCategory)
            // yield put({ type: EditResponse, data });
        } else {
            toast.error(response.message)
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }


}
function* displayCategory(action?:any): Generator<any, AxiosResponse<any>, any> {
   try {
    const response: any = yield service.category.view({search : action?.search,page:action?.page,limit:action?.limit});
    const data = yield response.data
    if (response.status === 200) {
        toast.success(response.message)
        yield put({ type: ResponseCategory, data })
    } else {
        toast.error(response.message)
    }
    return response; // add this line to return the response
   }catch (error: any) {
    console.log("error", error)
    toast.error(error?.response?.data?.message);
    return error
}
    
}
function* productSaga() {
    yield takeLatest(Add_Category, addCategory)
    yield takeLatest(Edit_Category, editCategory)
    yield takeLatest(Delete_Category, deleteCategory)
    yield takeLatest(Display_Category, displayCategory)
}

export default productSaga;