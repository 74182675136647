import { Response_Award, Award_Points_Response, Customer_Mobile_Qr,Award_History_Response } from './actionType'

export const AwardData = (data = [], action: { type: any; data: any }) => {
    switch (action.type) {
        case Response_Award:
            return action.data
        default:
            return data;
    }
}

export const getPointData = (data = [], action: { type: any; data: any }) => {
    switch (action.type) {
        case Award_Points_Response:
            return action.data
        default:
            return data;
    }
}

export const getTransitionData = (data = [], action: { type: any; dataTwo: any }) => {
    switch (action.type) {
        case Award_History_Response:
            return action.dataTwo.data
        default:
            return data;
    }
}

export const getQrResponse = (data = [], action: { type: any; responseData: any }) => {
    switch (action.type) {
        case Customer_Mobile_Qr:
            return action.responseData
        default:
            return data;
    }
}