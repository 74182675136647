import authService from './auth'
import category from './category'
import packages from './package'
import subAdmin from './subAdmin'
import merchant from './merchant'
import award_redeem from './award-redeem'
import transaction from './transaction'
import award from './award'
import merchantQuery from "./merchantQuery"
import userQuery from "./userQuery"
import customer from "./customer"
import loadMoney from "./loadMoney"
import dashboard from "./dashboard"
import Image from "./image"
const service = {
    auth: authService,
    query: merchantQuery,
    category,
    packages,
    subAdmin,
    merchant,
    award_redeem,
    award,
    transaction,
    userQuery,
    customer,
    loadMoney,
    dashboard,
    Image,
}
export default service