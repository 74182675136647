import PropTypes from "prop-types";
import React from "react";
import { Form, Row, Col, CardBody, Card, Alert, Container, Input, FormFeedback, Label } from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "./../../components/Common/withRouter";

import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ChangePasswrd, LoginInterface } from "./interface"

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ChangePassword, DataDashboard } from "../../store/action";


const Login = () => {
  const navigate = useNavigate();


  const dispatch = useDispatch();

  const validation = useFormik<ChangePasswrd>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please Enter Your oldPassword"),
      newPassword: Yup.string().required("Please Enter Your newPassword"),
    }),
    onSubmit: (values) => {
     
      dispatch(ChangePassword(values, navigate))
      // dispatch(loginUser(values, props.router.navigate));
    }
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        
      </div>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
          {/* <div className="col-11 ">
          <Breadcrumbs breadcrumbItem=" Change Password" />
        </div> */}
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0" style={{ height: "360px" }}>
                  <div className="p-2 mt-5">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Old Password</Label>
                        <Input
                          name="oldPassword"
                          className="form-control"
                          placeholder="Enter Old Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.oldPassword || ""}
                          invalid={
                            validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                          }
                        />
                        {validation.touched.oldPassword && validation.errors.oldPassword ? (
                          <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="newPassword"
                          value={validation.values.newPassword || ""}
                          type="password"
                          placeholder="Enter New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.newPassword && validation.errors.newPassword ? true : false
                          }
                        />
                        {validation.touched.newPassword && validation.errors.newPassword ? (
                          <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mt-5 text-center  ">
                        <button
                          className="color"
                          type="submit"
                        // onClick={()=>handleClick(validation.values)}
                        >
                          Change Password
                        </button>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
