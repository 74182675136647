import React from "react"
import { useNavigate } from "react-router-dom"
import { Row, Col } from "reactstrap"

const Breadcrumb = (props: any) => {
  const navigate = useNavigate()
  return (
    <Row>
      <Col className="col-12">
        <div className="d-sm-flex align-items-center d-flex">
          {props.isBack && <i className="bx bx-left-arrow-circle me-2 cursor-pointer"
            style={{ fontSize: 'X-large' }}
            onClick={() => navigate(-1)} />}
          <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb
