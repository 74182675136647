import { put, takeLatest, call } from 'redux-saga/effects'
import { Add_Customer, Display_Customer, ResponseCustomer, change_Customer_Status, getDetailById, getReponseById } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addCustomer(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        let payload = {
            email: action.data.email,
            first_name: action.data.firstname,
            last_name: action.data.lastname,
            dob: action.data.date,
            gender: action.data.gender,
            country_code: action.data.countrycode,
            mobile: action.data.mobile
        }
        const response: any = yield service.customer.add(payload, { "Content-type": "application/json" });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            action.navigate("/manage-customer")
            // yield call(displayCustomer);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }

}

function* displayCustomer(action?: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.customer.view({ search: action?.search, page: action?.page, limit: action?.limit });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.message);
            yield put({ type: ResponseCustomer, data })
        } else {
            toast.error(response.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }
}

function* getCustomerDetail(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.customer.getCustomerDetail(action.customer_id, { page: action?.page, limit: action?.limit ,transaction_type:action.transaction_type});
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message)
            yield put({ type: getReponseById, data });
        } else {
            toast.error(response.message)
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* changeCustomerStatus(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.customer.customerStatus(action?.customer_id);
        if (response.status === 200) {
            toast.success(response.data.message)
            yield call(displayCustomer);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        yield call(displayCustomer);
        return error
    }
}
function* productSaga() {
    yield takeLatest(Add_Customer, addCustomer)
    yield takeLatest(Display_Customer, displayCustomer)
    yield takeLatest(getDetailById, getCustomerDetail)
    yield takeLatest(change_Customer_Status, changeCustomerStatus)
}

export default productSaga;