import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'reactstrap';

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Overview from './Overview';
import DetailsSection from './DetailsSection';
import { getMerchantById } from "../../store/action"
const JobDetails = () => {
    const [customerDetail, setCustomerDetails] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [transactionType, setTransactionType] = useState('')
    const dispatch = useDispatch();
    const CustomerId: string | any = useParams()
    const customer_id = CustomerId?.id
    const { responseById } = useSelector((state: any) => ({
        responseById: state?.Merchant?.merchantResponseCustomerById?.data
        ,
    }));
    useEffect(() => {
        setCustomerDetails(responseById)
    }, [responseById])
    useEffect(() => {
        dispatch(getMerchantById({ customer_id, page: currentPage, limit: limitPerPage, istransaction: "true" , transaction_type:transactionType }))
    }, [currentPage, limitPerPage,transactionType])
    return (
        <React.Fragment>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11 p-0 ">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <Breadcrumbs breadcrumbItem="Merchant Details" isBack={true}/>
                            <div className="text-end" style={{ height: "34.5px" }}>
                            </div>
                        </div>

                        <Row>
                            <Overview
                                userDetail={customerDetail}
                                setCurrentPage={setCurrentPage}
                                setLimitPerPage={setLimitPerPage}
                            />
                            <DetailsSection
                                transactionDetails={customerDetail}
                                setCurrentPage={setCurrentPage}
                                setLimitPerPage={setLimitPerPage}
                                setTransactionType={setTransactionType}
                            />
                        </Row>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default JobDetails;