import React, { useState } from "react";
import { Row, Col, CardBody, Card, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ResetPassword } from "../../store/auth/action";
import { useDispatch } from "react-redux";
import logo from "../../assets/images";
import Constants from "../../constants/Constants";

const Index = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [passwordShow, setPasswordShow] = useState(false);
    const { search } = useLocation()

    const reset_token = new URLSearchParams(search).get("reset_token") || "";
    const setKeyword = new URLSearchParams(search).get("set") || "";
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password"),
            confirmPassword: Yup.string()
                .required('Please retype your password.')
                .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
        }),
        onSubmit: (values: any) => {
            dispatch(ResetPassword(values, reset_token, navigate));
        }
    });

    return (
        <React.Fragment>
            <Container fluid className="p-0 loginContainer" >
                <div className="loginContainer_Two">
                    <Row className="w-100">
                        <div className="col-6 d-flex justify-content-end">
                            <img src={logo.ResetPass} className="img-fluid" />
                        </div>
                        <div className="col-6 d-flex justify-content-center ">

                            <div className="card w-50 p-4 loginCard" >
                                <div className="text-center">
                                    <img
                                        src={logo.Cube3Logo}
                                        alt=""
                                        height="87"
                                    />
                                </div>
                                <h3 className="text-center my-5 fw-bold">{((setKeyword == '') || (setKeyword === Constants.PASSWORD_STATUS.SET)) ? 'Set' : 'Reset'}&nbsp;Password</h3>
                                <div className="mt-4">

                                    <Form className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <div className="mb-3">
                                            <Label className="form-label">New Password</Label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <Input
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    type={passwordShow ? "text" : "password"}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }
                                                />
                                                <button onMouseDown={() => setPasswordShow(!passwordShow)}
                                                    className="btn btn-light bg-transparent"
                                                    style={{ borderColor: "#e0e0e0", borderLeftStyle: "hidden" }} type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button>
                                            </div>
                                            {validation.touched.password && validation.errors.password ? (
                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Confirm Password</Label>
                                            <div className="input-group auth-pass-inputgroup">
                                                <Input
                                                    name="confirmPassword"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    type={passwordShow ? "text" : "password"}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confirmPassword || ""}
                                                    invalid={
                                                        validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                    }
                                                />
                                                <button onClick={() => setPasswordShow(!passwordShow)}
                                                    className="btn btn-light bg-transparent"
                                                    style={{ borderColor: "#e0e0e0", borderLeftStyle: "hidden" }} type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button>
                                            </div>
                                            {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="text-end">
                                            <button
                                                className="btn btn-primary w-md "
                                                type="submit"
                                            >
                                                {((setKeyword == '') || (setKeyword === Constants.PASSWORD_STATUS.SET)) ? 'Set' : 'Reset'}&nbsp;Password
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default Index;
