import PropTypes from "prop-types";
import React, { useEffect } from "react";
import withRouter from "../Common/withRouter";
// import {
//   changeLayout,
//   changeLayoutMode,
//   changeSidebarTheme,
//   changeSidebarThemeImage,
//   changeSidebarType,
//   changeTopbarTheme,
//   changeLayoutWidth,
//   showRightSidebarAction
// } from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

//redux
import { useSelector, useDispatch } from "react-redux";

const Layout = (props :any) => {
  // const dispatch = useDispatch();

  // const {
  //   isPreloader,
  //   leftSideBarThemeImage,
  //   layoutWidth,
  //   leftSideBarType,
  //   topbarTheme,
  //   showRightSidebar,
  //   leftSideBarTheme,
  //   layoutModeType
  // } = useSelector(state => ({
  //   isPreloader: state.Layout.isPreloader,
  //   layoutModeType: state.Layout.layoutModeType,
  //   leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
  //   leftSideBarType: state.Layout.leftSideBarType,
  //   layoutWidth: state.Layout.layoutWidth,
  //   topbarTheme: state.Layout.topbarTheme,
  //   showRightSidebar: state.Layout.showRightSidebar,
  //   leftSideBarTheme: state.Layout.leftSideBarTheme,
  // }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

//toggle responsiveness
  // const toggleMenuCallback = () => {
  //   if (leftSideBarType === "default") {
  //     dispatch(changeSidebarType("condensed", isMobile));
  //   } else if (leftSideBarType === "condensed") {
  //     dispatch(changeSidebarType("default", isMobile));
  //   }
  // };

  //hides right sidebar on body click
  // const hideRightbar = (event) => {
  //   var rightbar = document.getElementById("right-bar");
  //   //if clicked in inside right bar, then do nothing
  //   if (rightbar && rightbar.contains(event.target)) {
  //     return;
  //   } else {
  //     //if clicked in outside of rightbar then fire action for hide rightbar
  //     // dispatch(showRightSidebarAction(false));
  //   }
  // };

  /*
  layout  settings
  */

  // useEffect(() => {
  //   //init body click event fot toggle rightbar
  //   document.body.addEventListener("click", hideRightbar, true);


  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   // dispatch(changeLayout("vertical"));
  // }, [dispatch]);

  // useEffect(() => {
  //   if (leftSideBarTheme) {
  //     dispatch(changeSidebarTheme(leftSideBarTheme));
  //   }
  // }, [leftSideBarTheme, dispatch]);

  // useEffect(() => {
  //   if (layoutModeType) {
  //     dispatch(changeLayoutMode(layoutModeType));
  //   }
  // }, [layoutModeType, dispatch]);

  // useEffect(() => {
  //   if (leftSideBarThemeImage) {
  //     dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
  //   }
  // }, [leftSideBarThemeImage, dispatch]);

  // useEffect(() => {
  //   if (layoutWidth) {
  //     dispatch(changeLayoutWidth(layoutWidth));
  //   }
  // }, [layoutWidth, dispatch]);

  // useEffect(() => {
  //   if (leftSideBarType) {
  //     dispatch(changeSidebarType(leftSideBarType));
  //   }
  // }, [leftSideBarType, dispatch]);

  // useEffect(() => {
  //   if (topbarTheme) {
  //     dispatch(changeTopbarTheme(topbarTheme));
  //   }
  // }, [topbarTheme, dispatch]);

  return (
    <React.Fragment>
      {/* <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div> */}

      <div id="layout-wrapper">
        {/* <Header toggleMenuCallback={toggleMenuCallback} /> */}
        <Header />
        <Sidebar
          // theme={leftSideBarTheme}
          // type={leftSideBarType}
          // isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
};

// Layout.propTypes = {
//   changeLayoutWidth: PropTypes.func,
//   changeSidebarTheme: PropTypes.func,
//   changeSidebarThemeImage: PropTypes.func,
//   changeSidebarType: PropTypes.func,
//   changeTopbarTheme: PropTypes.func,
//   children: PropTypes.any,
//   isPreloader: PropTypes.any,
//   layoutWidth: PropTypes.any,
//   leftSideBarTheme: PropTypes.any,
//   leftSideBarThemeImage: PropTypes.any,
//   leftSideBarType: PropTypes.any,
//   location: PropTypes.object,
//   showRightSidebar: PropTypes.any,
//   topbarTheme: PropTypes.any,
// };

export default withRouter(Layout);
