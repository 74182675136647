import { put, takeLatest } from 'redux-saga/effects'
import { User_Display_Query, User_Response_Query } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* displayQuery(action?:any): Generator<any, AxiosResponse<any>, any> {

    try {
        const response: any = yield service.userQuery.view({search : action?.search,page:action?.page,limit:action?.limit});
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: User_Response_Query, data })
        } else {
            toast.success(response.data.message);
        }
        return response;
    } catch (error:any) {
        console.log(error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* productSaga() {
    yield takeLatest(User_Display_Query, displayQuery)
}

export default productSaga;