import React, { useEffect, useState } from "react";

import Table from '../../components/Common/dataTable';
import { DisplayTransaction, DownloadCsv } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button, Col, Row } from "reactstrap";
import { Tooltip } from "react-tooltip";
const Index = () => {
    const [search, setSearch] = useState("")
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [TransactionSortData, setTransactionSortData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(2)
    const dispatch = useDispatch();
    const { TransactionResponse } = useSelector((state: any) => ({
        TransactionResponse: state?.TransactionHistory.TransactionResponse
        ,
    }));

    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) => <span
            >
                {index + 1}
            </span>,
            maxWidth: "5%",
        },
        {
            name: "Brand Name",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.merchant === null ? "Paypal" : i?.merchant?.brand_name}>
                    {i?.merchant === null ? "Paypal" : i?.merchant?.brand_name}
                </span>
                <Tooltip id={`my-tooltip${i.id}`} />
            </>,
            minWidth: "13%",
            compact: true,
            sortable: true
        },
        {
            name: "Customer Name",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={"" + i?.customer?.user?.first_name + " " + i?.customer?.user?.last_name}>
                    {"" + i?.customer?.user?.first_name + " " + i?.customer?.user?.last_name}
                </span>
                <Tooltip id={`my-tooltip${i.id}`} />
            </>,

            minWidth: "15%",
            compact: true,
            sortable: true
        },
        {
            name: "Branch ",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.branch?.name || '-'}>
                    {i?.branch?.name || '-'}
                </span>
                <Tooltip id={`my-tooltip${i.id}`} />
            </>,
            minWidth: "10%",
        },
        {
            name: "Mobile",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={"(" + i?.customer?.user?.country_code + ")" + i?.customer?.user?.mobile}>
                    {"(" + i?.customer.user?.country_code + ") " + i?.customer?.user?.mobile}
                </span>
                <Tooltip id={`my-tooltip${i.id}`} />
            </>,

            minWidth: "15%",
        },
        {
            name: "Payment Mode",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.merchant === null ? "Online" : "Offline"}>
                    {i?.merchant === null ? "Online" : "Offline"}
                </span>
            </>,
            minWidth: "10%",
        },
        {
            name: "Transaction Id",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.transaction_id}>
                    {i?.transaction_id}
                </span>
            </>,
            minWidth: "10%",
        },
        {
            name: "Type",
            selector: (i: any) => i.transaction_type,
            conditionalCellStyles: [
                {
                    when: (row: any) => row.transaction_type === "AWARD" || row.transaction_type === "RECEIVE",
                    classNames: ['green'],
                },
                {
                    when: (row: any) => row.transaction_type === "REDEEM" || row.transaction_type === "SEND",
                    classNames: ['red'],
                },
                {
                    when: (row: any) => row.transaction_type === "LOAD",
                    classNames: ['blue'],
                }
            ],

        },
        {
            name: "Date & Time",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={(moment(i?.createdAt).format("DD/MM/YYYY hh:mm A"))}>
                    {(moment(i?.createdAt).format("DD/MM/YYYY hh:mm A"))}
                </span>
            </>,
            minWidth: "15%",
        },
        {
            name: "Points",
            selector: (i: any) => (i?.points).toFixed(2),
        },

    ]
    useEffect(() => {
        setTransactionSortData(TransactionResponse?.transaction_history)
        setTotalCount(TransactionResponse?.count)
    }, [TransactionResponse?.transaction_history])

    useEffect(() => {
        let dateEnd = ''
        let dateStart = ''
        if (startDate !== undefined && endDate !== undefined) {
            dateStart = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
            dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');
        }
        dispatch(DisplayTransaction({ search: '', page: currentPage, limit: limitPerPage, transaction_type: 'LOAD', created_from: dateStart, created_to: dateEnd }))
    }, [currentPage, limitPerPage])
    const handleSearch = (e: string) => {
        let searchValue: string = e
        let dateEnd = ''
        let dateStart = ''
        if (startDate !== undefined && endDate !== undefined) {
            dateStart = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
            dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');
        }
        setTimeout(() => {
            dispatch(DisplayTransaction({ search: searchValue, page: currentPage, limit: limitPerPage, transaction_type: 'LOAD', created_from: dateStart, created_to: dateEnd }))
        }, 500)
        setSearch(e)
    }
    const handleDatePicker = (date: any) => {
        const dateStart = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
        const dateEnd = moment(date).format('YYYY-MM-DDT23:59:59');
        dispatch(DisplayTransaction({ search: '', transaction_type: 'LOAD', created_from: dateStart, created_to: dateEnd, page: currentPage, limit: limitPerPage }))
    }
    const startDateChange = (date: any) => {
        let date1 = new Date(date).getTime();
        let date2 = new Date(endDate).getTime();
        setStartDate(date)
        if (date1 > date2) {
            setEndDate('')
        } else {
            const dateStart = moment(date).format('YYYY-MM-DDTHH:mm:ss');
            const dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');
            if (endDate !== undefined) {
                dispatch(DisplayTransaction({ search: '', transaction_type: 'LOAD', created_from: dateStart, created_to: dateEnd, page: currentPage, limit: limitPerPage }))
            }
        }

    }

    const endDateChange = (date: any) => {
        setEndDate(date)
        if (startDate !== undefined) {
            handleDatePicker(date)
        }
    }
    const _clearDate = () => {
        let dateStart = ''
        let dateEnd = ''
        setStartDate(dateStart)
        setEndDate(dateEnd)
        dispatch(DisplayTransaction({ search: '', transaction_type: '', created_from: '', created_to: '', page: currentPage, limit: limitPerPage }))
    }

    const handleDownload = () => {
        let dateEnd = ''
        let dateStart = ''
        if (startDate !== undefined && endDate !== undefined) {
            dateStart = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
            dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');
        }
        dispatch(DownloadCsv({ created_from: dateStart, created_to: dateEnd, transaction_type: "LOAD" }))
    }

    return (
        <>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11  p-0 mb-3 ">
                        <div className="d-block d-md-flex justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Load Transaction History" />
                            <div className="mt-2">
                                <Row>
                                    <div className="col-md-4 col-lg-4 col-4 text-end">
                                        <DatePicker
                                            filterDate={d => {
                                                return new Date() >= d;
                                            }}
                                            // value={(moment(startDate).format("DD/MM/YYYY"))}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="Select Start Date"
                                            selectsStart
                                            selected={startDate}
                                            endDate={endDate}
                                            onChange={startDateChange}
                                        />
                                    </div>
                                    <div className="col-md-4 col-lg-4 col-4 text-end pe-0">
                                        <DatePicker
                                            filterDate={d => {
                                                return new Date() >= d;
                                            }}
                                            // value={(moment(endDate).format("DD/MM/YYYY"))}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Select End Date"
                                            className="form-control"
                                            selectsEnd
                                            selected={endDate}
                                            minDate={startDate}
                                            onChange={endDateChange}
                                        />
                                    </div>
                                    <div className="col-md-3 col-lg-2 col-3 text-end">
                                        <Button
                                            color='primary'
                                            onClick={() => _clearDate()}
                                        >Clear</Button>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-3 text-end d-none d-md-block">
                                        <Button className="download-btn btn-outline-primary"
                                            onClick={() => {
                                                handleDownload()
                                                // window.open(`${BASE_URL}admin/export?created_from=${startDate}&created_to=${moment(endDate).format('YYYY-MM-DDT23:59:59')}&transaction_type=${transactionType?.value !== 'ALL' ? transactionType?.value : ''}`, '_blank')
                                            }}>
                                            Download Csv
                                        </Button>
                                    </div>
                                </Row>
                                <div className="text-end d-block d-md-none mt-3">
                                    <Button className="download-btn"
                                        onClick={() => handleDownload()}>
                                        Download Csv
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card col-11 marginTop">
                        <div className="row ">
                            <div className="col-6">
                                <form className="app-search d-none d-lg-block w-50">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" />
                                    </div>
                                </form>
                            </div>
                            <div className="col-4">
                            </div>

                        </div>
                        <div className="container-fluid custom-width-table">
                            <Table
                                columns={columns}
                                data={TransactionSortData ? TransactionSortData : []}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setLimitPerPage={setLimitPerPage}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Index;
