import React, { useEffect, useState } from "react";
import {
  Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import Table from '../../components/Common/merchantDataTable';
import { DisplayMerchant, changeStatus, resetMerchantPassword } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Images from "../../assets/images/index"
import PopupModel from "../../components/Common/Popup"
import { Tooltip } from "react-tooltip";
const Index = () => {
  const [startDate, setstartDate] = useState<any>('')
  const [endDate, setendDate] = useState<any>('')
  const [search, setSearch] = useState('');
  const [statusId, setstatusId] = useState('');
  const [statusType, setStatusType] = useState('');
  const [statusPopup, setstatusPopup] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(2)
  const [MerchantData, setMerchantData] = useState<any>([])
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { merchant } = useSelector((state: any) => ({
    merchant: state?.Merchant?.merchantResponse,
  }));
  const columns: any = [
    {
      name: "S.No.",
      selector: (i: any, index: number) => index + 1,
      minWidth: "5%",
    },
    {
      name: "Brand Logo",
      selector: (i: any) => <span className="my-1"
      >
        {i.image === null || i.image === '' ?
          <img
            src={Images.empty}
            className="rounded avatar-sm my-1"
          // style={{ width: "63px", height: "54px" }}
          />
          :
          <img
            src={`${i?.brand_logo}`}
            className="rounded avatar-sm my-1"
          // style={{ width: "63px", height: "54px" }}
          />

        }
      </span>,
      minWidth: "13%",
    },
    {
      name: "Merchant Id",
      selector: (i: any) =>
        i?.merchant_code,
      minWidth: "12%",
    },
    {
      name: "Brand Name",
      selector: (i: any) => <>
        <span data-tooltip-id={`my-tooltip${i.id}`}
          data-tooltip-content={i?.brand_name}>
          {i?.brand_name}
        </span>
        <Tooltip id={`my-tooltip${i.id}`} />
      </>,
      minWidth: "15%",
      compact: true,
      sortable: true
    },
    {
      name: "Owner Name",
      selector: (i: any) => <>
        <span data-tooltip-id={`my-tooltip${i.id}`}
          data-tooltip-content={i?.owner?.first_name + " " + i?.owner?.last_name}>
          {i?.owner?.first_name + " " + i?.owner?.last_name}
        </span>
        <Tooltip id={`my-tooltip${i.id}`} />
      </>,

      minWidth: "15%",
    },
    {
      name: "Contact Number",
      selector: (i: any) => <>
        <span data-tooltip-id={`my-tooltip${i.id}`}
          data-tooltip-content={"(" + i?.owner?.country_code + ")" + i?.owner?.mobile}>
          {"(" + i?.owner?.country_code + ") " + i?.owner?.mobile}
        </span>
        <Tooltip id={`my-tooltip${i.id}`} />
      </>,

      minWidth: "20%",
    },
    {
      name: "Status",
      selector: (i: any) => handleIsStatus(i)?.label,
      conditionalCellStyles: [
        {
          when: (i: any) => handleIsStatus(i)?.label ===
            "Active",
          classNames: ['green'],
        },
        {
          when: (i: any) => handleIsStatus(i)?.label !== "Active",
          classNames: ['red'],
        }
      ],
      minWidth: "10%",
    },
    {
      name: "Action",
      cell: (i: any) =>
        <span className="cursor">
          <UncontrolledDropdown>
            <DropdownToggle className="" id="dropdownMenuClickable" data-bs-toggle="dropdown" direction="left" data-bs-auto-close="false" aria-expanded="false">
              <i className="fas fa-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => {
                navigate(`merchant-details/${i?.id}`)
              }} >Detail</DropdownItem>
              <DropdownItem onClick={() => { _handleMerchantUpdate(i.id) }} >Edit</DropdownItem>
              <DropdownItem onClick={() => { setStatusType(handleIsStatus(i)?.label), setstatusId(i.id), setstatusPopup(true) }} >{i?.status === "1" ? "Deactivate" : "Activate"}</DropdownItem>
              <DropdownItem onClick={() => { navigate(`branches/${i.id}`) }}>Branches</DropdownItem>
              <DropdownItem onClick={() => {
                dispatch(resetMerchantPassword(i?.owner?.email))
              }}>Resend password link</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

        </span>,
      minWidth: "6%",
    },
  ]
  const isStatusData = [
    {
      options: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ]
    },
  ];
  useEffect(() => {
    setMerchantData(merchant?.data)
    setTotalCount(merchant?.count)
  }, [merchant?.data])

  useEffect(() => {
    dispatch(DisplayMerchant({ search: '', created_from: '', created_to: '', page: currentPage, limit: limitPerPage }))
  }, [currentPage, limitPerPage])

  const _handleMerchantUpdate = (i: string) => {
    navigate(`edit-merchant/${i}`)
  }

  const handleSearch = (e: string) => {
    let searchValue: string = e
    setTimeout(() => {
      dispatch(DisplayMerchant({ search: searchValue, created_from: '', created_to: '', page: currentPage, limit: limitPerPage }))
    }, 500)
    setSearch(e)
  }


  const handleIsStatus = (i: any) => {
    let statusindex = isStatusData[0].options.findIndex(x => x?.value === i?.status);
    return isStatusData[0].options[statusindex]
  }
  const handleDatePicker = (date: string) => {
    dispatch(DisplayMerchant({ search: '', created_from: startDate, created_to: date, page: currentPage, limit: limitPerPage }))
  }
  const startDateChange = (date: any) => {
    setstartDate(date)
  }

  const endDateChange = (date: any) => {
    setendDate(date)
    handleDatePicker(date)
  }

  const handleBranchChange = () => {
    dispatch(changeStatus(statusId))
  }
  return (
    <>
      <div className="my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-11 p-0 mb-3 ">
            <div className="d-flex justify-content-between align-items-center">
              <Breadcrumbs breadcrumbItem="Manage Merchant" />
              {/* <div>
                <Row>
                  <Col md={6} className="pr-0">
                    <DatePicker
                      filterDate={d => {
                        return new Date() > d;
                      }}
                      className="form-control"
                      placeholderText="Select Start Date"
                      selectsStart
                      selected={startDate}
                      endDate={endDate}
                      onChange={startDateChange}
                    />
                  </Col>
                  <Col md={6} className="pl-0">
                    <DatePicker
                      filterDate={d => {
                        return new Date() > d;
                      }}
                      placeholderText="Select End Date"
                      className="form-control"
                      selectsEnd
                      selected={endDate}
                      minDate={startDate}
                      onChange={endDateChange}
                    />
                  </Col>
                </Row>
              </div> */}
              <div className="text-end">
                <Button
                  className="color"
                  onClick={() => {
                    navigate("addmerchant")
                  }}>Add Merchant</Button>
              </div>
            </div>
          </div>
          <div className="card col-11">
            <div className="row ">
              <div className="col-6">
                <form className="app-search d-none d-lg-block w-50">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e.target.value)
                      }}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </div>
            </div>

            <div>
              {statusPopup && (
                <PopupModel
                  ApiName={handleBranchChange}
                  popUpFunction={setstatusPopup}
                  title="Confirmation"
                  paragraph={`Are you sure you want to ${statusType === "Active" ? "Deactivate" : "Activate"}  the Merchant ?`}
                />
              )}
            </div>
            <div className="container-fluid">
              <Table
                columns={columns}
                data={MerchantData ? MerchantData : []}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                setLimitPerPage={setLimitPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
