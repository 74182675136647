import { put, takeLatest } from 'redux-saga/effects'
import { Display_Transaction, Download_Csv, ResponseTransaction } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* displayTransaction(action?: any): Generator<any, AxiosResponse<any>, any> {

    try {
        const response: any = yield service.transaction.view({ search: action?.search, page: action?.page, limit: action?.limit, transaction_type: action?.transaction_type, created_from: action.created_from, created_to: action.created_to });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: ResponseTransaction, data })
        } else {
            toast.success(response.data.message);
        }
        return response;
    } catch (error: any) {
        console.log(error)
        toast.success(error?.response?.data?.message);
        return error
    }
}

function* download_Csv(action?: any): Generator<any, AxiosResponse<any>, any> {

    try {
        const response: any = yield service.transaction.downloadCsv({ created_from: action.created_from, created_to: action.created_to, transaction_type: action.transaction_type });
        if (response.status === 200) {
            window.open(response.data.path)
        }
        return response;
    } catch (error: any) {
        console.log(error)
        toast.error(error?.response?.data?.message);
        return error
    }
}
function* productSaga() {
    yield takeLatest(Display_Transaction, displayTransaction)
    yield takeLatest(Download_Csv, download_Csv)
}

export default productSaga;