import { put, takeLatest, call } from 'redux-saga/effects'
import { Add_Load_Money, Display_Load_Money, Edit_Load_Money, Delete_Load_Money, Response_Load_Money, Edit_Load_Money_Response } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addLoad_Money(action: any): Generator<any, AxiosResponse<any>, any> {
    try{
        
        const response: any = yield service.loadMoney.add(action.data,{});
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);  
            yield call(displayLoad_Money);
        }else{
            toast.error(response.data.message);  
        }
        return response; // add this line to return the response
    }catch(error:any){
        console.log("error",error)
        toast.error(error?.response?.data?.message);  
        yield call(displayLoad_Money);
        return error
    }
    
}
function* editLoad_Money(action: any): Generator<any, AxiosResponse<any>, any> {
    
    try {
        const response: any = yield service.loadMoney.edit(action.id ,action.data, { });
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);  
            yield put({ type: Edit_Load_Money_Response, data });
            yield call(displayLoad_Money);
            
        }else{
            toast.error(response.data.message);  
        }
        return response; 
    } catch (error:any) {
        console.log(error);
        toast.error(error?.response?.data?.message);  
        return action
    }
   // add this line to return the response
}
function* deleteLoad_Money(action: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.loadMoney.delete(action.id);
        const data = yield response.data;
        if (response.status === 200) {
            toast.success(response.data.message);  
            yield call(displayLoad_Money);
            // yield put({ type: EditResponse, data });
        }else{
            toast.error(response.data.message); 
        }
        return response;
    }  catch (error:any) {
        console.log(error);
        toast.error(error?.response?.data?.message);  
        yield call(displayLoad_Money);

        return action
    }
    // add this line to return the response
}
function* displayLoad_Money(action?:any): Generator<any, AxiosResponse<any>, any> {
   try {
    const response: any = yield service.loadMoney.view({search : action?.search,page:action?.page,limit:action?.limit});
    const data = yield response.data;
    if (response.status === 200) {
        toast.success(response.message); 
        yield put({ type: Response_Load_Money, data })
    }else{
        toast.error(response.message); 
    }
    return response; // add this line to return the response
   }  catch (error:any) {
    console.log(error);
    toast.error(error?.response?.data?.message);  
    return error
}
    
}
function* productSaga() {
    yield takeLatest(Add_Load_Money, addLoad_Money)
    yield takeLatest(Edit_Load_Money, editLoad_Money)
    yield takeLatest(Delete_Load_Money, deleteLoad_Money)
    yield takeLatest(Display_Load_Money, displayLoad_Money)
}

export default productSaga;