import React, { useEffect, useState } from "react";

import Table from '../../components/Common/dataTable';
import { DisplayTransaction, DownloadCsv } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button, Row } from "reactstrap";
import { Tooltip } from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
const Index = () => {
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [formattedStartDate, setFormattedStartDate] = useState<any>()
    const [formattedEndDate, setFormattedEndDate] = useState<any>()
    const [TransactionSortData, setTransactionSortData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(2)
    const [transactionType, setTransactionType] = useState<string | any>(null);
    const dispatch = useDispatch();

    const { TransactionResponse } = useSelector((state: any) => ({
        TransactionResponse: state?.TransactionHistory.TransactionResponse,
    }));
    const columns: any = [
        {
            name: "S.No.",
            selector: (i: any, index: number) => <span
            >
                {index + 1}
            </span>,
            maxWidth: "5%",
        },
        {
            name: "Brand Name",
            selector: (i: any) =>
                <>
                    <span data-tooltip-id={`my-tooltip${i.id}`}
                        data-tooltip-content={i?.merchant === null ? "Paypal" : i?.merchant?.brand_name}>
                        {i?.merchant === null ? "Paypal" : i?.merchant?.brand_name}
                    </span>
                    <Tooltip id={`my-tooltip${i.id}`} />
                </>,
            minWidth: "12%",
            compact: true,
            sortable: true,
        },
        {
            name: "Customer Name",
            selector: (i: any) =>
                <>
                    <span data-tooltip-id={`my-tooltip${i.id}`}
                        data-tooltip-content={i?.customer?.user?.first_name + ' ' + i?.customer?.user?.last_name}
                    >
                        {i?.customer?.user?.first_name + ' ' + i?.customer?.user?.last_name}
                    </span>
                </>
            ,
            minWidth: "10%",
            compact: true,
            sortable: true,
        },
        {
            name: "Branch ",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.branch?.name}>
                    {i?.branch?.name || '-'}
                </span>
            </>,
            minWidth: "10%",
        },
        {
            name: "Mobile",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={'(' + i?.customer?.user?.country_code + ') ' + i?.customer?.user?.mobile}>
                    {'(' + i?.customer?.user?.country_code + ') ' + i?.customer?.user?.mobile}
                </span>
            </>,

            minWidth: "13%",
        },
        {
            name: "Transaction Id",
            selector: (i: any) => <>
                <span data-tooltip-id={`my-tooltip${i.id}`}
                    data-tooltip-content={i?.transaction_id}>
                    {i?.transaction_id}
                </span>
            </>,
            minWidth: "10%",
        },
        {
            name: "Type",
            selector: (i: any) => i.transaction_type,
            conditionalCellStyles: [
                {
                    when: (row: any) => row.transaction_type === "AWARD" || row.transaction_type === "RECEIVE",
                    classNames: ['green'],
                },
                {
                    when: (row: any) => row.transaction_type === "REDEEM" || row.transaction_type === "SEND",
                    classNames: ['red'],
                },
                {
                    when: (row: any) => row.transaction_type === "LOAD",
                    classNames: ['blue'],
                }
            ],

        },
        {
            name: "Date & Time",
            selector: (i: any) =>
                (moment(i?.createdAt).format('DD/MM/YYYY hh:mm A')),

            minWidth: "13%",
        },
        {
            name: "Amount",
            selector: (i: any) => (i?.amount !== null ? i?.amount : 0).toFixed(2)
        },
        {
            name: "Points",
            selector: (i: any) => (i?.points).toFixed(2),
            // minWidth: "8%",
        },

    ]
    const transaction_type = [
        {
            options: [
                { label: "ALL", value: "ALL" },
                { label: "AWARD", value: "AWARD" },
                { label: "REDEEM", value: "REDEEM" },
                { label: "LOAD", value: "LOAD" },
                { label: "SEND", value: "SEND" },
                { label: "RECEIVE", value: "RECEIVE" },
            ]
        },
    ];
    useEffect(() => {
        setTransactionSortData(TransactionResponse?.transaction_history)
        setTotalCount(TransactionResponse?.count)
    }, [TransactionResponse?.transaction_history])

    useEffect(() => {
        // setTransactionType(transaction_type[0].options[0])
        let filterType = ''
        if (transactionType?.value !== "ALL") {
            filterType = transactionType?.value
        }
        dispatch(DisplayTransaction({ search: '', page: currentPage, limit: limitPerPage, transaction_type: filterType, created_from: formattedStartDate ? formattedStartDate : startDate, created_to: formattedEndDate ? formattedEndDate : endDate }))
    }, [currentPage, limitPerPage])

    const handleSearch = (e: string) => {
        let searchValue: string = e
        let filterType = ''
        if (transactionType?.value !== "ALL") {
            filterType = transactionType?.value
        }
        setTimeout(() => {
            dispatch(DisplayTransaction({ search: searchValue, page: currentPage, limit: limitPerPage, transaction_type: filterType, created_from: formattedStartDate, created_to: formattedEndDate }))
        }, 500)
    }

    const handleTransactionType = (e: any) => {
        let filterType = e.value
        setTransactionType(e)
        if (e.value === "ALL") {
            filterType = ''
        }

        dispatch(DisplayTransaction({ search: '', page: currentPage, limit: limitPerPage, transaction_type: filterType, created_from: formattedStartDate, created_to: formattedEndDate }))
    }

    const _clearDate = () => {
        let dateStart = ''
        let dateEnd = ''
        setStartDate(dateStart)
        setEndDate(dateEnd)
        setTransactionType(transaction_type[0].options[0])
        dispatch(DisplayTransaction({ search: '', transaction_type: '', created_from: '', created_to: '', page: currentPage, limit: limitPerPage }))

    }
    const returnStartDate = (startDate: any) => {
        setStartDate(startDate)

        let dateStart
        let dateEnd

        if (startDate !== undefined) {
            dateStart = moment(startDate).format('YYYY-MM-DDT00:00:00');
            dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');
        }
        let filterType = ''
        if (transactionType?.value !== "ALL") {
            filterType = transactionType?.value
        }
        setFormattedStartDate(dateStart)
        dispatch(DisplayTransaction({ search: '', transaction_type: filterType, created_from: dateStart ? dateStart : "", created_to: endDate ? endDate : dateEnd, page: currentPage, limit: limitPerPage }))
    }
    const returnEndDate = (endDate: any) => {
        setEndDate(endDate)

        let dateEnd
        if (endDate !== undefined) {
            dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');
        }
        if (startDate !== undefined) {
            let filterType = ''
            if (transactionType?.value !== "ALL") {
                filterType = transactionType?.value
            }
            setFormattedEndDate(dateEnd)
            dispatch(DisplayTransaction({ search: '', transaction_type: filterType, created_from: formattedStartDate ? formattedStartDate : '', created_to: dateEnd ? dateEnd : "", page: currentPage, limit: limitPerPage }))
        }
    }
    const handleDownload = () => {
        let dateEnd = ''
        let dateStart = ''
        if (startDate !== undefined && endDate !== undefined) {
            dateStart = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
            dateEnd = moment(endDate).format('YYYY-MM-DDT23:59:59');
        }
        let filterType = ''
        if (transactionType?.value !== "ALL") {
            filterType = transactionType?.value
        }
        dispatch(DownloadCsv({ created_from: dateStart, created_to: dateEnd, transaction_type: filterType }))
    }


    return (
        <>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11  p-0 mb-3 ">
                        <div className="d-block d-md-flex  justify-content-between align-items-center">
                            <Breadcrumbs breadcrumbItem="Transaction History" />

                            <div className="mt-2">
                                <Row>
                                    <div className="col-md-4 col-lg-4 col-4 text-end">
                                        <DatePicker
                                            filterDate={d => {
                                                return new Date() >= d;
                                            }}
                                            // value={(moment(startDate).format("DD/MM/YYYY"))}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            placeholderText="Select Start Date"
                                            selectsStart
                                            selected={startDate}
                                            endDate={endDate}
                                            onChange={(e: any) => returnStartDate(e)}
                                        />
                                    </div>
                                    <div className="pe-0 col-md-4 col-lg-4 col-4 text-end">
                                        <DatePicker
                                            filterDate={d => {
                                                return new Date() >= d;
                                            }}
                                            placeholderText="Select End Date"
                                            // value={(moment(endDate).format("DD/MM/YYYY"))}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                            selectsEnd
                                            selected={endDate}
                                            minDate={startDate}
                                            onChange={(e: any) => returnEndDate(e)}
                                        />
                                    </div>
                                    <div className="col-md-3 col-lg-2 col-3 text-end">
                                        <Button
                                            color='primary'
                                            onClick={() => _clearDate()}
                                        >Clear</Button>
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-3 text-end d-none d-md-block">
                                        <Button className="download-btn btn-outline-primary"
                                            onClick={() => {
                                                handleDownload()
                                                // window.open(`${BASE_URL}admin/export?created_from=${startDate}&created_to=${moment(endDate).format('YYYY-MM-DDT23:59:59')}&transaction_type=${transactionType?.value !== 'ALL' ? transactionType?.value : ''}`, '_blank')
                                            }}>
                                            Download Csv
                                        </Button>
                                    </div>
                                </Row>
                                <div className="text-end d-block d-md-none mt-3">
                                    <Button className="download-btn"
                                        onClick={() => handleDownload()}>
                                        Download Csv
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card col-11 marginTop ">
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <form className="app-search d-none d-lg-block w-75">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => { handleSearch(e.target.value) }}
                                        />
                                        <span className="bx bx-search-alt" />
                                    </div>
                                </form>
                            </div>

                            <div className=" w-25 mt-3">
                                <Select
                                    name="transactionType"
                                    value={transactionType}
                                    onChange={(e) => {
                                        handleTransactionType(e)
                                    }}
                                    options={transaction_type}
                                    className="select2-selection"
                                />
                            </div>
                        </div>
                        <div className="container-fluid custom-width-table">
                            <Table
                                columns={TransactionSortData && columns}
                                data={TransactionSortData ? TransactionSortData : []}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setLimitPerPage={setLimitPerPage}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Index;