import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import images from '../../assets/images';
import moment from 'moment';

const Overview = (props: any) => {
    const [userData, setUserData] = useState<any>()
    useEffect(() => {
        setUserData(props.userDetail)
    }, [props.userDetail])
    return (
        <React.Fragment>
            <Col xl={3}>
                <Card>
                    <CardBody>
                        <div className="text-center">
                            {userData?.profile === undefined || userData.profile === null ?
                                (<img src={images.empty} alt="" height="50" className="mx-auto d-block" />) : (
                                    <img src={`${userData?.profile}`} alt="" height="50" className="mx-auto d-block" />
                                )
                            }
                            <h5 className="mt-3 mb-1">{userData?.user?.first_name} {userData?.user?.last_name}</h5>
                        </div>

                        <ul className="list-unstyled mt-4">
                            <li>
                                <div className="d-flex">
                                    <i className="bx bx-phone text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Phone</h6>
                                        <p className="text-muted fs-14 mb-0">{userData?.user?.country_code} {userData?.user?.mobile}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-mail-send text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Email</h6>
                                        <p className="text-muted fs-14 mb-0 text-break">{userData?.user?.email}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-globe text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Gender</h6>
                                        <p className="text-muted fs-14 text-break mb-0">{userData?.user?.gender}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-globe text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Date of birth</h6>
                                        <p className="text-muted fs-14 text-break mb-0">{moment(userData?.user?.dob).format("DD/MM/YYYY")}</p>
                                    </div>
                                </div>
                            </li>
                            <li className="mt-3">
                                <div className="d-flex">
                                    <i className="bx bx-money text-primary fs-4"></i>
                                    <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Points</h6>
                                        <p className="text-muted fs-14 mb-0 ">{userData?.points}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default Overview;