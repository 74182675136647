import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import store from "./store";
import 'react-tooltip/dist/react-tooltip.css'

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
        <ToastContainer />
          <App />
        </BrowserRouter>
     </React.Fragment>
     </Provider>
);

serviceWorker.unregister()
