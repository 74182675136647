import { put, takeLatest, call } from 'redux-saga/effects'
import { Add_Award, Display_Award, Response_Award, get_Points, Award_Points_Response, customer_QrMobile, Customer_Mobile_Qr, Award_History_Response, Transactions_Data } from './actionType'
import { AxiosResponse } from 'axios';
import service from '../../api/services/index'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* addAward(action: any): any {
    try {
        const response: any = yield service.award.add(action.data, {});
        const data = yield {
            data: yield response.data,
            popup: false
        }
        const search:any =  {search:action?.data?.mobile}
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: Award_Points_Response, data })
            yield call(displayAward ,search);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {
        console.log("error", error)
        toast.error(error?.response?.data?.message);
        yield call(displayAward);
        return error
    }

}
function* getPoints(action: any): Generator<any, AxiosResponse<any>, any> {
    let Payload = {
        amount: action.data.amount,
        customerId: action.data.customerId,
    }
    try {
        const response: any = yield service.award.getPoints(Payload, {});
        const data = yield {
            data: yield response.data,
            popup: true
        }
        if (response.status === 200) {
            toast.success(response.data.message);
            yield put({ type: Award_Points_Response, data });
        } else {
            toast.error(response.data.message);
        }
        return response;
    } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message);
        return action
    }
    // add this line to return the response
}

function* displayAward(action?: any): any {
    try {
        let dataTwo
        const response: any = yield service.award.viewCustomer({ search: action?.search });
        const data = yield response.data;
        if (response.status === 200) {
            const customer_id = yield response?.data?.data[0]?.id
            toast.success(response.message);
            if (data?.data?.length == 0) {
                toast.error('Customer not found');
            }
            if (action?.search) {
                yield put({ type: Response_Award, data })
            }
            const historyResponse: any = yield service.award.getTransactionHistory(customer_id,{limit: 10 , page:1});
            dataTwo = yield historyResponse?.data
            yield put({ type: Award_History_Response, dataTwo });
        } else {
            toast.error(response.message);
        }
        const finalRes = {
            response,
            dataTwo
        }
        return finalRes;
    } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message);
        return error
    }

}
function* getCustomerMobile(action: any): any {
    let Payload = {
        qrId: action.QrID.replace(/^"(.*)"$/, "$1"),
    }

    try {
        const response: any = yield service.award.viewCustomerByQr(Payload);
        let responseData = response.data.data
        if (response.status === 200) {
            yield put({ type: Customer_Mobile_Qr, responseData });
        }
        return response;
    } catch (error: any) {
        console.log(error);
        toast.error(error.response.data.message);
        return action
    }
}


function* TransactionHistory(action?: any): any {
    try {
        const response: any = yield service.award.getTransactionHistory(action?.search,{limit: action?.limit , page:action?.page});
        let dataTwo = yield response?.data
        if (response.status === 200) {
            yield put({ type: Award_History_Response, dataTwo });
        } else {
            toast.error(response.message);
        }
        return response;
    } catch (error: any) {
        console.log(error);
        toast.error(error.response.data.message);
        return error
    }
}
function* productSaga() {
    yield takeLatest(Add_Award, addAward)
    yield takeLatest(get_Points, getPoints)
    yield takeLatest(Display_Award, displayAward)
    yield takeLatest(customer_QrMobile, getCustomerMobile)
    yield takeLatest(Transactions_Data,TransactionHistory)
}

export default productSaga;