import { Display_Merchant, Add_Merchant, ResponseMerchant, GetMerchantDetailResponse, Get_Edit_Response, Display_PackageData, Display_CategoryData, BranchesList } from './actionType'


const initialState: any = {
    merchantResponse: [],
    merchantEditResponse: '',
    CategoryData: [],
    PackageData: [],
    BranchList: [],
    merchantResponseCustomerById:[]
}

const Merchant = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case ResponseMerchant:
            return {
                ...state,
                merchantResponse: action?.data
            }
        case Get_Edit_Response:
            return {
                ...state,
                merchantEditResponse: action?.data?.data
            }
        case Display_CategoryData:
            return {
                ...state,
                CategoryData: action?.data
            }
        case Display_PackageData:
            return {
                ...state,
                PackageData: action?.data
            }
        case BranchesList:
            return {
                ...state,
                BranchList: action?.data
            }
        case GetMerchantDetailResponse:
            return {
                ...state,
                merchantResponseCustomerById: action.data
            }
        default:
            return state;
    }
}

export default Merchant
