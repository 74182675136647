import React, { useState } from "react";
import { Container, Form, Row, Input, Label, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoginInterface } from "./interface"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataDashboard } from "../../store/action";
import logo from "../../assets/images/index";

const Login2 = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validation = useFormik<LoginInterface>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Email"),
      password: Yup.string().required("Please Enter Password"),
    }),
    onSubmit: (values) => {
      dispatch(DataDashboard(values, navigate))
    }
  });
  return (
    <React.Fragment>
      <Container fluid className="p-0 loginContainer" >
        <div className="loginContainer_Two">
          <Row className="w-100 d-flex align-items-center ">
            <div className="col-12 col-md-6  d-none d-md-block">
              <div className="text-end">
                <img src={logo.loginTwo} className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center ">

              <div className="card p-4 loginCard" >
                <div className="text-center">
                  <img
                    src={logo.Cube3Logo}
                    alt=""
                    height="87"
                  // className="logo-light-element"
                  />

                </div>
                <h3 className="text-center my-5 fw-bold">Admin Login</h3>
                <div className="mt-4">
                  <Form className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter username"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username && validation.errors.username ? true : false
                        }
                      />
                      {validation.touched.username && validation.errors.username ? (
                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={passwordShow ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                          style={{ borderRightStyle: "hidden" }}
                        />
                        <button
                          //  onClick={() => setPasswordShow(!passwordShow)} 
                          className="btn btn-light bg-transparent" style={{ borderColor: "#e0e0e0", borderLeftStyle: "hidden" }} type="button" id="password-addon">
                          <i onClick={() => setPasswordShow(!passwordShow)} className={`mdi ${passwordShow ? "mdi-eye-off-outline" : "mdi-eye-outline"}`}></i></button>
                      </div>
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block "
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Login2;
