export default {
    STATUS: {
        ACTIVE: '1',
        DEACTIVE: '0',
    },
    TRANSACTION_TYPE: {
        AWARD: 'AWARD',
        REDEEM: 'REDEEM',
        LOAD: 'LOAD',
        SEND: 'SEND',
        RECEIVE: 'RECEIVE',
    },
    SCOPE: {
        ADMIN: '1',
        SUBADMIN: '2',
    },
    PASSWORD_STATUS: {
        SET: '0',
        RESET: '1'
    },
    Transaction_Type: [
        {
            options: [
                { label: "ALL", value: "ALL" },
                { label: "AWARD", value: "AWARD" },
                { label: "REDEEM", value: "REDEEM" },
                { label: "LOAD", value: "LOAD" },
            ]
        },
    ]
}