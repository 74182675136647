import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
// import { api } from '../testInterceptor'
const { category } = Dictionary
const categoryServices = {
    add: async (data: {}, headers: any) => {

        return axios.post(BASE_URL + category.addCategory(), data, { headers: headers });
    },
    delete: async (id: string) => {

        return axios.delete(BASE_URL + category.deleteCategory(id));
    },
    edit: async (id: string, data: {}, headers: any) => {

        return axios.put(BASE_URL + category.editCategory(id), data, { headers: headers });
    },
    view: async (paramsObj: any) => {
        return axios.get(BASE_URL + category.viewCategory(), { params: paramsObj });
    }
}
export default categoryServices;
