export const countryCodeData = [
    {
        options:[
            {
                label: "(+1264) Anguilla -AID",
                value: "+1264",
                "code": "AI"
            },
          
            {
                label: "(+1246) Barbados -BBD",
                value: "+1246",
                "code": "BB"
            },
            {
                label: "(+345) Cayman Islands -KYD",
                value: "+345",
                "code": "KY"
            },
            {
                label: "(+1767) Dominica -DOP",
                value: "+1767",
                "code": "DM"
            },
            {
                label: "(+1473) Grenada -XCD",
                value: "+1473",
                "code": "GD"
            },
            {
                label: "(+509) Haiti -HTG",
                value: "+509",
                "code": "HT"
            },
            {
                label: "(+91) India -INR",
                value: "+91",
                "code": "IND"
            },
          
            {
                label: "(+1876) Jamaica -JMD",
                value: "+1876",
                "code": "JM"
            },
            {
                label: "(+1664) Montserrat -MSD",
                value: "+1664",
                "code": "MS"
            },
            {
                label: "(+1868) Trinidad and Tobago -TT",
                value: "+1868",
                "code": "TT"
            },
        ]
      },
  ];