import { BASE_URL } from "../../constants/DefaultValues";
import Dictionary from "../dictionary"
import axios from "axios";
const { customer } = Dictionary
const CustomerServices = {
    add: async (data: {}, headers: any) => {

        return axios.post(BASE_URL + customer.addCustomer(), data, { headers: headers });
    },
    view: async (paramsObj: any) => {

        return axios.get(BASE_URL + customer.viewCustomer(), { params: paramsObj });
    },
    getCustomerDetail: async (id: string, paramsObj: any) => {
        return axios.get(BASE_URL + customer.getCustomer(id), { params: paramsObj });
    },
    customerStatus: async (id: string) => {
        console.log("id: ", id);
        return axios.put(BASE_URL + customer.customerStatus(id),);
    },
}
export default CustomerServices;
