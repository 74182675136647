import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Input, CardTitle, Button } from "reactstrap";
import { AddAward, customerQrMobile, DisplayAward, getPoints, transactionHistory } from "../../store/action"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PopupModel from "../../components/Common/Popup/index";
import DataTable from "../../components/Common/dataTable";
import moment from "moment";
import { QrReader } from 'react-qr-reader';
import QRimage from "../../assets/images/index"
import Constants from "../../constants/Constants";
const Index = () => {
    const [Award_Data, setAward_Data] = useState<any>([])
    const [pointResponse, setPointResponse] = useState<any>('')
    const [awardConfirmation, setAwardConfirmation] = useState(false)
    const [search, setSearch] = useState("")
    const [awardAmount, setAmount] = useState('');
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [pointsError, setPointsError] = useState(false)
    const [transitionResponse, setTransitionResponse] = useState<any>('')
    const [showQr, setShowQr] = useState(false);
    const [pageCount, setPageCount] = useState<any>(1);
    const [limit, setLimit] = useState(10);
    const [scannerId, setScannerId] = useState('');
    const columns = [
        { name: 'S. No.', selector: (row: any) => row?.serialNumber, maxWidth: "5%", },
        { name: 'Amount', selector: (row: any) => row?.amount?.toFixed(2) || '-', },
        { name: 'Points', selector: (row: any) => row?.points?.toFixed(2), },
        { name: 'Transaction Type', selector: (row: any) => row?.transaction_type, },
        { name: 'Transaction Date', selector: (row: any) => moment(row?.createdAt).format("DD/MM/YYYY hh:mm A"), sortable: true },
    ]
    const dispatch = useDispatch();
    const { awardPoint, getPoint, getQrResponse, getTransitionData } = useSelector((state: any) => ({
        awardPoint: state?.AwardData?.data,
        getPoint: state?.getPointData?.data,
        getQrResponse: state?.getQrResponse,
        getTransitionData: state?.getTransitionData,
    }));
    const navigate = useNavigate()
    useEffect(() => {
        if (isSearchClicked) {
            setAward_Data(awardPoint)
            setTransitionResponse(getTransitionData)
            setPointResponse(getPoint)
        }
    }, [awardPoint, getPoint, getQrResponse, getTransitionData])

    useEffect(() => {
        if (scannerId !== "")
            dispatch(customerQrMobile(scannerId))
    }, [scannerId])

    useEffect(() => {
        if (isSearchClicked) {
            setSearch(getQrResponse?.mobile)
            handleSearch(getQrResponse?.mobile)
        }
    }, [getQrResponse])
    const handleSearch = (e: any) => {
        dispatch(DisplayAward({ search: e?.trim() }))
    }

    const handleAward = () => {
        let payload = {
            country_code: Award_Data[0]?.user?.country_code,
            mobile: Award_Data[0]?.user?.mobile,
            amount: pointResponse?.amount
        }
        dispatch(AddAward(payload))
        setAmount('')
        // setIsSearchClicked(false)
    }

    const handleAwardPoints = (e: string) => {
        setAmount(e)
        if (e?.length !== 0) {
            let temp: any = {
                amount: e,
                customerId: Award_Data[0]?.id,
            }
            dispatch(getPoints(temp))
        }
    }
    const handleSubmit = () => {

        if (awardAmount.length == 0)
            setPointsError(true)
        else {
            setPointsError(false)
            setAwardConfirmation(true)
        }
    }
    useEffect(() => {
        if (isSearchClicked) {
            dispatch(transactionHistory(awardPoint[0]?.id, pageCount, limit))
        }
    }, [awardPoint, pageCount, limit]);
    return (
        <React.Fragment>
            <div className="my-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-11  mb-3 p-0 ">
                        <div className="d-flex justify-content-between align-items-center px-2 px-md-0">
                            <Breadcrumbs breadcrumbItem="Award Points" />
                            <div className="text-end">
                                <Button className="color"
                                    onClick={() => {
                                        navigate("/manage-customer/addCustomer")
                                    }}>Add Customer</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center px-2 px-md-0 ">
                    <div className="col-11 p-0">
                        <Row>
                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Search Customer</CardTitle>
                                        <Row className="mt-4">
                                            <div className="col-md-9">
                                                <Input
                                                    name="state" placeholder="Enter by Id/Mobile number" type="number"
                                                    className="form-control "
                                                    id="validationCustom03"
                                                    onChange={(e) => {
                                                        setSearch(e.target.value);
                                                        setIsSearchClicked(true)
                                                    }}
                                                    value={search}
                                                />
                                            </div>
                                            <div className="col-md-3 mt-3 mt-md-0  text-end">
                                                <Button className="btn btn-primary search-btn" color='primary'
                                                    disabled={search ? false : true}
                                                    onClick={() => handleSearch(search)}>
                                                    Search <i className="mdi mdi-arrow-right ms-1"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <div className="row align-items-center mb-3">
                                    <div className="col-md-5">
                                        <div className="border">
                                        </div>
                                    </div>
                                    <div className="col-md-2 text-center">
                                        <h3 className="mb-0">or</h3>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="border">
                                        </div>
                                    </div>
                                </div>

                                <Card className="mb-0">
                                    <CardBody className="h-100 mb-0">

                                        <Row>
                                            {showQr === false &&
                                                <CardTitle className="text-center mb-3">Click here to Scan</CardTitle>}
                                            <div className="text-center ">
                                                {showQr === false &&
                                                    <img src={QRimage.qrscan} onClick={() => setShowQr(true)} className='w-25 cursor' />
                                                }
                                                {showQr === true &&
                                                    <div >
                                                        <div className=" position-absolute button-overlap">
                                                            <button type="button" className="btn-close" style={{ fontSize: '7px' }} aria-label="Close" onClick={() => setShowQr(false)}></button>
                                                        </div>
                                                        <QrReader
                                                            onResult={(result: any) => {
                                                                if (!!result) {
                                                                    setIsSearchClicked(true)
                                                                    setScannerId(result?.text);
                                                                    setShowQr(false)
                                                                }
                                                            }} 
                                                            constraints={{ facingMode: 'environment' }}
                                                            className="h-50 video-screen"
                                                        />
                                                    </div>}
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="card m-0" sm="6 ">
                                <CardBody>
                                    <div className="row">
                                        <div className="col-6">
                                            <CardTitle className="mb-4 fw-bold fs-16">Customer Information</CardTitle>
                                        </div>
                                        <div className="col-6">
                                            <CardTitle className="text-muted mb-4 fw-bold fs-16" >Amount :&nbsp;
                                                <span className="text-dark text-capitalize">&#36;{Award_Data && Award_Data[0]?.balanceAmount}</span>
                                            </CardTitle>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col sm='6'>
                                            <h5 className="text-muted fw-bold fs-16">Name :&nbsp;
                                                {Award_Data?.length > 0 && (
                                                    <span className="text-dark text-capitalize">
                                                        {Award_Data[0]?.user?.first_name + " " + Award_Data[0]?.user?.last_name}
                                                    </span>)}
                                            </h5>
                                        </Col>
                                        <Col sm='6'>
                                            <h5 className="text-muted fw-bold fs-16">
                                                Current Points :&nbsp;
                                                {Award_Data?.length > 0 && (
                                                    <span className="text-dark ">{Award_Data[0]?.points} </span>)}
                                            </h5>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="d-flex align-items-center" sm='6'>
                                            <p className="text-muted fw-bold fs-16 mb-0">Enter Sale Amount :&nbsp;</p>
                                        </Col>
                                        <Col sm='6'>
                                            <Input
                                                name="state" placeholder="Enter amount of sale" type="text"
                                                className="form-control text-dark input-height"
                                                id="validationCustom03"
                                                onChange={(e) => {
                                                    handleAwardPoints(e.target.value);
                                                }}
                                                value={awardAmount || ''}
                                            />
                                            {pointsError &&
                                                <p className="red">Please enter amount</p>}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className='text-center'>
                                            <h5 className="text-muted fs-16 mt-3">
                                                Points To Be Awarded
                                            </h5>
                                            {awardAmount !== "" && <p className="text-dark mt-3 fw-bold fs-22 text-decoration-underline">
                                                {(pointResponse?.points)?.toFixed(2)}
                                            </p>}
                                            <div className="mt-3 mb-2">
                                                <Button className="btn btn-primary fw-bold fs-16" color='success'
                                                    onClick={() => handleSubmit()}>
                                                    Award
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Transaction History</CardTitle>
                                        <Row>
                                            <DataTable
                                                pagination={true}
                                                columns={columns}
                                                data={transitionResponse?.transactions && transitionResponse?.transactions.map((item: any, index: number) => {
                                                    return ({
                                                        ...item,
                                                        serialNumber: index + 1,
                                                        transaction_type: <span className={`${item.transaction_type === Constants.TRANSACTION_TYPE.AWARD || item.transaction_type === Constants.TRANSACTION_TYPE.RECEIVE ? 'green' : item.transaction_type === Constants.TRANSACTION_TYPE.REDEEM || item.transaction_type === Constants.TRANSACTION_TYPE.SEND ? 'red' : item.transaction_type === Constants.TRANSACTION_TYPE.LOAD ? 'blue' : ''}`}>{item.transaction_type}</span>
                                                    })
                                                })}
                                                setCurrentPage={setPageCount}
                                                setLimitPerPage={setLimit}
                                                totalCount={transitionResponse && transitionResponse?.count}
                                            />
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {awardConfirmation && (
                            <PopupModel
                                ApiName={handleAward}
                                popUpFunction={setAwardConfirmation}
                                title="Confirmation"
                                paragraph={`Are you sure you want to Award ?`}
                            />
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Index;
