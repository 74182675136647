import { User_Display_Query, User_Response_Query } from './actionType'

const initialState = {
    UserQueryResponse: []
}

const UserQuery = (state = initialState, action: { type: any; data: any }) => {
    switch (action.type) {
        case User_Response_Query:
            return {
                ...state,
                UserQueryResponse: action.data
            }
        default:
            return state;
    }
}

export default UserQuery