import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardTitle, Col, Input, Row } from 'reactstrap'
import { AdminDashboard } from '../../store/action'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const [dashboard_Data, setDashboard_Data] = useState<any>([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { dashboard } = useSelector((state: any) => ({
    dashboard: state?.Dashboard.dashboard
    ,
  }));
  useEffect(() => {
    dispatch(AdminDashboard())
  }, [])

  useEffect(() => {
    setDashboard_Data(dashboard?.data)
  }, [dashboard?.data])
  return (
    <div className="my-5 py-5">
      <div className="row justify-content-center ">
        <div className="col-11 mb-3 ">
          <div className="d-flex justify-content-between align-items-center row">
            <Col md="4 "  >
              <Card className="mini-stats-wid" style={{ borderRadius: '21px' }}>
                <CardBody className='dashboard-card-height' onClick={() => navigate('/transaction')}>
                  <div className="d-flex my-4">
                    <div className="flex-grow-1 text-center">
                      <p className="fw-medium fs-3 black" >
                        Total Awards
                      </p>
                      <h4 className="mb-0">{dashboard_Data?.totalAwards}</h4>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" >
              <Card className="mini-stats-wid" style={{ borderRadius: '21px' }} >
                <CardBody className='dashboard-card-height' onClick={() => navigate('/manage-customer')}>
                  <div className="d-flex my-4">
                    <div className="flex-grow-1 text-center">
                      <p className=" fw-medium fs-3 black">
                        Total Customers
                      </p>
                      <h4 className="mb-0">{dashboard_Data?.totalCustomers}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" >
              <Card className="mini-stats-wid" style={{ borderRadius: '21px' }}>
                <CardBody className='dashboard-card-height' onClick={() => navigate('/merchant')} >
                  <div className="d-flex my-4">
                    <div className="flex-grow-1 text-center">
                      <p className=" fw-medium fs-3 black">
                        Total Merchants
                      </p>
                      <h4 className="mb-0">{dashboard_Data?.totalMerchants}</h4>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
          <div className="d-flex justify-content-between align-items-center row">
            <Col md="4" >
              <Card className="mini-stats-wid" style={{ borderRadius: '21px' }}>
                <CardBody className='dashboard-card-height' onClick={() => navigate('/merchant-query')}>
                  <div className="d-flex my-4">
                    <div className="flex-grow-1 text-center">
                      <p className=" fw-medium fs-3 black">
                        Total Merchant Queries
                      </p>
                      <h4 className="mb-0">{dashboard_Data?.totalMerchantQueries}</h4>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" >
              <Card className="mini-stats-wid" style={{ borderRadius: '21px' }}>
                <CardBody className='dashboard-card-height' onClick={() => navigate('/user-query')}>
                  <div className="d-flex my-4">
                    <div className="flex-grow-1 text-center">
                      <p className=" fw-medium fs-3 black">
                        Total Customer Queries
                      </p>
                      <h4 className="mb-0">{dashboard_Data?.totalCustomerQueries}</h4>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" >
              <Card className="mini-stats-wid" style={{ borderRadius: '21px' }} >
                <CardBody className='dashboard-card-height' onClick={() => navigate('/transaction')}>
                  <div className="d-flex my-4">
                    <div className="flex-grow-1 text-center">
                      <p className=" fw-medium fs-3 black">
                        Total Redeems
                      </p>
                      <h4 className="mb-0">{dashboard_Data?.totalRedeems}</h4>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
          <div className="d-flex justify-content-between align-items-center row">
            <Col md="4" >
              <Card className="mini-stats-wid" style={{ borderRadius: '21px' }}>
                <CardBody className='dashboard-card-height' onClick={() => navigate('/load-money-history')}>
                  <div className="d-flex my-4">
                    <div className="flex-grow-1 text-center">
                      <p className=" fw-medium fs-3 black"   >
                        Total Load Money
                      </p>
                      <h4 className="mb-0">{dashboard_Data?.totalLoadMoney}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index